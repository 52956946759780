import * as React from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import './NextPreviousComponent.css';

const paths = [
    {"name": "/", "number": 0, "caption": "Главная страница"},
    {"name": "/nachalo/", "number": 1, "caption": "Введение"},
    {
        "name": "/zaostrov/",
        "number": 2,
        "caption": "Дело священника Георгия Кочеткова и общины храма Успения Пресвятой Богородицы в Печатника"
    },
    {
        "name": "/zaos1/",
        "number": 3,
        "caption": "Часть 1. «Дай вам Бог крепнуть, углубляться, расти и светить всем вокруг вас»"
    },
    {"name": "/zaos2/", "number": 4, "caption": "Часть 2. «Вы — жертва,  может быть, и невинная...»"},
    {"name": "/zaos3/", "number": 5, "caption": "Часть 3. 29 июня: говорят очевидцы"},
    {"name": "/zaos4/", "number": 6, "caption": "Часть 4. 29 июня: говорят документы"},
    {"name": "/zaos5/", "number": 7, "caption": "Часть 5. «Радонеж» начинает и выигрывает?"},
    {"name": "/zaos6/", "number": 8, "caption": "Часть 6: Нет ничего тайного, что не было бы узнано"},
    {"name": "/zaos7/", "number": 9, "caption": "Часть 7. Комиссия. Июль— октябрь 1997 года"},
    {"name": "/zaos8/", "number": 10, "caption": "Глава 8. Что же дальше?"},
    {"name": "/non-licet/", "number": 11, "caption": "«Дело» архимандрита Зинона"},
    {"name": "/fatheradelgeim/", "number": 12, "caption": "«Херсонское дело»"},
    {"name": "/chronologyadelgeim/", "number": 13, "caption": "«Томское дело»"},
    {"name": "/strugglechurch/", "number": 14, "caption": "«Екатеринбургское дело»"},
    {"name": "/tver/", "number": 15, "caption": "«Дело» игумена Мартирия (Багина) и храма Всех святых на Кулишках"},
    {"name": "/krasnousolsk/", "number": 16, "caption": "Кто следующий?"},
    {"name": "/nizhnevartovsk/", "number": 17, "caption": "Заключение"},
    {"name": "/pril/", "number": 18, "caption": "Приложение"}
]


class NextPrevious extends React.Component {
    constructor(props) {
        super(props)
        let index = props.profiles.filter(path => path.name === window.location.pathname)[0].number
        let textNext;
        if (index === paths.length - 1) {
            textNext = this.props.profiles[0].caption
        }
        if (index < paths.length - 1) {
            textNext = this.props.profiles[index + 1].caption
        }
        if (index === 0) {
            textNext = this.props.profiles[1].caption;
        }

        let textPrevious;
        if (index === 0) {
            textPrevious = this.props.profiles[paths.length - 1].caption;
        } else {
            textPrevious = this.props.profiles[index - 1].caption;
        }
        textPrevious = 'Предыдущий: ' + textPrevious
        textNext = 'Следующий: ' + textNext
        this.state = {
            index: index,
            textPrevious: textPrevious,
            textNext: textNext
        }
    }

    togglePrev() {
        let index = this.props.profiles.filter(path => path.name === window.location.pathname)[0].number
        let textNext;
        if (index === paths.length - 1) {
            textNext = this.props.profiles[0].caption
        }
        if (index < paths.length - 1) {
            textNext = this.props.profiles[index + 1].caption
        }
        if (index === 0) {
            index = paths.length;
            textNext = this.props.profiles[1].caption;
        }
        index--
        let textPrevious = this.props.profiles[index].caption
        window.location = this.props.profiles[index].name
        textPrevious = 'Предыдущий: ' + textPrevious
        textNext = 'Следующий: ' + textNext
        this.setState({index: index, textPrevious: textPrevious, textNext: textNext})
    }

    toggleNext() {
        let index = this.props.profiles.filter(path => path.name === window.location.pathname)[0].number
        let textPrevious;
        if (index === 0) {
            textPrevious = this.props.profiles[paths.length - 1].caption
        }
        if (index > 0) {
            textPrevious = this.props.profiles[index].caption;
        }
        index++;
        if (index === paths.length) {
            textPrevious = this.props.profiles[paths.length - 1].caption
            index = 0
        }
        window.location = this.props.profiles[index].name
        let textNext = this.props.profiles[index].caption
        textPrevious = 'Предыдущий: ' + textPrevious
        textNext = 'Следующий: ' + textNext
        this.setState({index: index, textPrevious: textPrevious, textNext: textNext})
    }

    render() {
        const {index} = this.state
        const profile = this.props.profiles ? this.props.profiles[index] : null
        if (profile) {
            return (
                <div className="profileMobile">
                    <table border="0" width="100%" className="profileMobile">
                        <tr className="profileMobile">
                            <td className="profileMobile" align="left">
                                    <Link to={profile.name} onClick={() => this.togglePrev()}>
                                        <Button key="prev" variant="outlined">&laquo; Предыдущая</Button></Link>
                            </td>
                             <td className="profileMobile" align="left">
                                    <Link to={profile.name} onClick={() => this.toggleNext()}>
                                        <Button key="next" variant="outlined">Следующая &raquo;</Button></Link>
                            </td>
                        </tr>
                    </table>
                </div>
            )
        } else {
            return <span>error</span>
        }
    }

}

export default function NextPreviousComponentMobile() {
    return (
        <div>
            <NextPrevious profiles={paths}/>
        </div>);
}
