import React from "react";
import './text.css';
const Zaos5 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Часть 5. «Радонеж» начинает и выигрывает?</h2>

            <p class="has-text-align-right"><em> « — Ты лжешь, — прогнусил Гнилоуст.</em></p>
            

            
            <p class="has-text-align-right"><em>— Недаром это слово не сходит у тебя с языка, —сказал Гэндальф».</em></p>
            

            
            <p class="has-text-align-right"><em>(Дж. Р.Р. Толкиен. Властелин колец)</em></p>
            

            
            <p><strong><em>В
                освещении радиостанцией “Радонеж” конфликта в приходе Успения Пресвятой Богородицы
                в Печатниках можно выявить следующие закономерности (о 1-м этапе см. раздел “Вы
                — жертва...”).</em></strong></p>
            

            
            <p><strong><em>2-й
                этап: от 29 июня до вручения о. Георгию Кочеткову Указа о временном запрещении
                в священнослужении (5 июля 1997 г.). 29 июня о. Михаил, в конце чтения канона
                назвав сокращение утрени (чтение канона “на два”) кощунством, начинает читать
                параллельно с чтецами сначала канон, потом другие богослужебные и
                небогослужебные тексты. После того, как у него отбирают книги, просит двух
                своих прихожанок вызвать милицию, монахов Сретенского монастыря и архиеп.
                Арсения, пытается выйти из храма в облачении, когда же прихожане заслоняют
                собой выход и просят разоблачиться, начинает хаотически перемещаться по храму,
                заходит в алтарь и начинает кричать оттуда: “Помогите, бьют!” Милиция
                констатирует, что «батюшка не в себе», вызванная прихожанами «скорая помощь»
                констатирует необходимость срочной принудительной госпитализации. Районная
                психиатрическая больница ( № 14) принимает о. Михаила на лечение, но вечером
                того же дня комиссионно выпускает его домой, признав, что он “не выявляет
                признаков психического расстройства”.</em></strong></p>
            

            
            <p><strong><em>29
                июня, в тот же день, появляются первые сообщения на «Радонеже». Общее время —
                15 минут. В них входят два звонка в прямом эфире (в одном из которых «с полной
                ответственностью» сообщается, что о. Михаил был в алтаре храма избит, после
                чего отправлен в психбольницу), а также не анонсированное сообщение
                “единственного свидетеля” (не присутствовавшего в алтаре, но присутствовавшего
                в храме и даже устроившего там драку — Владимира Сергеева, алтарника о.
                Александра Шаргунова) в записи.</em></strong></p>
            

            
            <p><strong><em>30
                июня проблеме посвящено 35 минут. Утверждается, что о. Михаил был связан,
                избит, его таскали за волосы, что он, будучи совершенно здоров, был отправлен в
                психбольницу, где ему вкололи неизвестный психотропный препарат, который едва
                не повлек за собой его гибель. Говорится о заговоре (“здесь много темных и
                страшных страниц”).</em></strong></p>
            

            
            <p><strong><em>1
                июля (день возвращения патриарха) весь час прямого эфира обсуждают о. Георгия
                Кочеткова и общину Успения в Печатниках. Повторяется весь предыдущий набор
                обвинений, а также то, что приход этот — тоталитарная секта. В прямом эфире
                выступает о. Михаил (из 7-й городской больницы). О. Михаил не говорит об
                избиении. Все остальные говорят об избиении как о несомненном факте.</em></strong></p>
            

            
            <p><strong><em>1—2
                июля патриарх подписывает Указ и распоряжение о временном запрещении о. Георгия
                Кочеткова в священнослужении, роспуске приходского совета и пр.</em></strong></p>
            

            
            <p><strong><em>2
                июля проблема затрагивается вскользь по инициативе дозвонившихся в прямой эфир
                “Радонежа” прихожан церкви Успения в Печатниках, свидетельствующих о том, что
                все, что говорит радиостанция — клевета. </em></strong></p>
            

            
            <p><strong><em>3
                июля к пяти минутам прямого эфира добавляется полчаса интервью, одного — с иг.
                Тихоном (Шевкуновым), второго — с прот. Владимиром Диваковым, где к тому же
                набору обвинений добавляется обвинение в беспрерывной лжи и непослушании. “Этот
                Карфаген лжи должен быть разрушен”, — утверждает о. Тихон.</em></strong></p>
            

            
            <p><strong><em>4
                июля почти все два часа передачи посвящены общине Успения в Печатниках. Дьяк. Андрей
                Кураев утверждает, что там ересь, только в чем — пока непонятно, и призывает
                все московские приходы прервать евхаристическое общение с храмом Успения —
                может быть, это заставит Патриарха принять меры.</em></strong></p>
            

            
            <p><strong><em>5
                июля Указ и Распоряжение вручены о. Георгию и прихожанам. В этот день радио “Радонеж”
                посвятило о. Георгию Кочеткову и Успенской общине около 45 минут. Далее
                плотность вещания спадает до пяти—шести минут в день. Конфликт входит в третью
                стадию — расследования инцидента.</em></strong></p>
            

            
            <p><strong><em>Ниже
                публикуются расшифровки отдельных выступлений радиостанции “Радонеж” и телепрограммы
                “Русский дом”, заметки из газеты “Радонеж” и “Черная сотня”. </em></strong></p>
            

            
            <p>РАДИО “РАДОНЕЖ”,
                29 ИЮНЯ, ВОСКРЕСЕНЬЕ</p>
            

            
            <p><strong><em>Прямой
                эфир (первый час передачи)</em></strong></p>
            

            
            <p><strong><em>О.
                Дмитрий Смирнов (приветливо):</em></strong>
                “Добрый вечер. Что Вы хотите спросить?”</p>
            

            
            <p><strong><em>Слушатель:</em></strong> Здравствуйте. Вы меня слышите? Вас беспокоит
                раб Божий Лев, прихожанин храма Николы в Пыжах. Батюшка, сегодня, к сожалению,
                в храме Георгия Кочеткова произошло прискорбное событие: священник этого храма
                о. Михаил был отвезен на санитарной машине в психиатрическую больницу N 14, что
                на станции метро “Каховская”. Свидетелем был алтарник нашего храма Владимир. Он
                рассказал все это и я несу полностью ответственность за свои слова. Повторяю:
                священник о. Михаил был избит и увезен в психиатрическую больницу N 14. Я очень
                Вас прошу помолиться о здравии о. Михаила и посоветоваться, чтобы что-то
                предпринять по этому поводу.</p>
            

            
            <p><strong><em>О.
                Дмитрий Смирнов (мирно и размеренно):</em></strong> Дело в том, что, во-первых, кто тут может что предпринять? В
                больнице моментально выяснится, человек здоров или болен, потому что на самом
                деле устроиться в психиатрическую больницу не так-то просто. Вот. А то, что
                происходит вот такое насильное помещение в психушку — явление для нашей русской
                действительности очень такое известное, мы еще, собственно, не остыли от
                предыдущей эпохи, это обычное дело. Сегодня в храме читали утром — “блаженны,
                когда вас поносят, когда вас изгоняют”. Это явление такого порядка, которое
                можно было ожидать. Это прискорбно, но никакого удивления не вызывает. Я уже об
                этом слышал, хотя ни с какими очевидцами еще не беседовал. Но это неважно. У
                меня нет никакой власти и никаких возможностей в это дело вмешиваться. А
                помолиться — я помолюсь, как могу, конечно. Прошу и вас помолиться, и всех, кто
                нас слышит, чтобы вся эта печальная история каким-то образом разрешилась.</p>
            

            
            <p><strong><em>Спустя
                15 минут о. Дмитрия поправили. — Ред.;</em></strong></p>
            

            
            <p><strong><em>Слушатель:</em></strong> Алло, здрасьте. Ответьте, пожалуйста на
                такой вопрос. Может быть, не очень вежливо так говорить священнику, но
                все-таки, что касается о. Михаила Дубовицкого, не кажется ли Вам, что все-таки
                нельзя проявлять такую теплохладность, потому что речь касается, ну, так
                скажем, все-таки духовного лица, тем более не очень пристойно говорить о том,
                что так просто в психбольницу не попадешь, потому что совершилось действительно
                надругательство, вообще— нечто.</p>
            

            
            <p><strong><em>О.
                Дмитрий Смирнов: (извиняющимся тоном, нервно):</em></strong> Нет, я, понимаете, имел в виду-то совсем
                другое. Я говорил, что врачи моментально, если человек здоров, никто его силком
                держать не будет, ни за какие деньги. Я говорю, это имелось в виду, а не то,
                что... Конечно, это возмутительный факт, если это так, как вот мне об этом
                поведали. Но, понятно, вот, то, что на этом приходе происходит, возмущает не
                только этим, а и другими всякими деяниями. Церковь возмущается уже на
                протяжении нескольких лет, как это все появилось. Вот. А что Вы прикажете мне
                делать? Что, идти драться? Это, к сожалению большому, это противоречит канонам.
                Что, призвать к восстанию, громить? Чтобы из одного зла сделать еще большее? А
                с другой стороны, совершенно понятно, что приход туда о. Михаила, в ту
                ситуацию, которая там была, совершенно ясно, что это могло кончиться чем
                угодно. Это, конечно, что-то такое экстраординарное. Такого еще случая не было,
                по-моему, у нас в истории. Чтобы прямо так вот из храма, да прямо с милицией,
                да прямо чтобы еще предварительно избив...Вот.</p>
            

            
            <p>Тут Вы зря
                обвиняете меня в теплохладности. Хорошо так батюшку поисповедовать, его
                посмирять, поставить его на место, это все очень, так сказать, для мирянина,
                может быть, выглядит довольно браво. Но я эту ситуацию с приходом о. Георгия
                переживаю не менее, я думаю, болезненно, чем Вы. Просто я немного постарше Вас
                и знаю о том, что, на собственном уже жизненном опыте убедился, что открывать
                военные действия и начинать драку — обычно результатом бывает, ну, гораздо
                худшая ситуация, чем была вначале. Вот. Так всегда бывает.</p>
            

            
            <p><strong><em>Радио
                “Радонеж“, второй час эфира 29 июня — трансляция записи:</em></strong></p>
            

            
            <p><strong><em>Ведущий
                С.Герасимов:</em></strong> Современные
                обновленцы, наконец, показали свое лицо. По благословению о. Георгия Кочеткова
                священник его храма Михаил Дубовицкий во время богослужения был связан в
                алтаре, избит и насильно увезен в психиатрическую больницу только за то, что он
                совершал богослужение согласно канонам РПЦ. Рассказывает очевидец, Владимир
                Сергеев, алтарник московского храма.</p>
            

            
            <p><strong><em>Далее
                следует текст, почти буквально совпадающий с напечатанным выше свидетельством
                В.Сергеева — см. раздел “Говорят очевидцы”.</em></strong></p>
            

            
            <p>РАДИО “РАДОНЕЖ”,
                30 ИЮНЯ, ПОНЕДЕЛЬНИК</p>
            

            
            <p><strong><em>Слушатель
                сообщает о повторной госпитализации о. Михаила, предполагая причиною то, что
                его “таскали за волосы в алтаре”. Владимир Сергеев повторяет свой рассказ,
                включив в него часть свидетельства М.Намаконовой (см. раздел “Говорят очевидцы”)
                и дополнительные подробности, например, то, что из алтаря послышались крики о.
                Михаила такие, будто его душили, после этого Владимир бросился в алтарь, но был
                остановлен вышедшим оттуда милиционером (видимо, душившим о. Михаила). </em></strong></p>
            

            
            <p><strong><em>Первый
                раз выступает иером. Анатолий (Берестов) со следующими тезисами:</em></strong></p>
            

            
            <p><strong><em>1)
                “из алтаря храма был насильственно госпитализирован психически здоровый
                человек”;</em></strong></p>
            

            
            <p><strong><em>2)
                “это подсудное дело по отношению к врачам и милиции”;</em></strong></p>
            

            
            <p><strong><em>3)
                “есть непосредственная связь между А. Введенским и о.о. Георгием Кочетковым и
                Александром Борисовым.”</em></strong></p>
            

            
            <p>РАДИО “РАДОНЕЖ”,
                1 ИЮЛЯ, ВТОРНИК</p>
            

            
            <p><strong><em>Прот.
                Валентин Асмус утверждает, что было избиение, физическое насилие, что о.
                Георгия нужно извергнуть из сана, а мирян отлучить от Церкви, а также, что:</em></strong></p>
            

            
            <p>—“Священнику
                Георгию Кочеткову и его общине свойственен аморализм”;</p>
            

            
            <p>—“Это своего рода
                тоталитарная секта, где дрессируются зомбированные люди”;</p>
            

            
            <p>—“О. Михаил был
                представителем всей церкви в этом страшном месте, где происходит
                надругательство над православием”.</p>
            

            
            <p><strong><em>Слушательница:</em></strong> “Это не православная община, а просто
                вражеская орда”.</p>
            

            
            <p><strong><em>Ведущий:</em></strong> “Это не имеет ничего общего с православием,
                любой нормальный человек должен прийти в ужас. Надеемся, это произойдет и со
                священноначалием”.</p>
            

            
            <p>РАДИО “РАДОНЕЖ”,
                2 ИЮЛЯ, СРЕДА</p>
            

            
            <p><strong><em>На
                вопросы в прямом эфире отвечают председатель общества “Радонеж” Е.Никифоров и
                В.Н.Калинин, заслуженный юрист России, советник патриарха.</em></strong></p>
            

            
            <p><strong><em>Вопрос
                радиослушательницы:</em></strong> ...Как
                с точки зрения закона выглядит то, что уже три дня радиостанция “Радонеж”
                клевещет, что, якобы, у нас в алтаре избили, связали и душили священника, хотя
                ничего такого не было, его никто пальцем не тронул, пока не приехала
                “психиатрическая скорая” и не признала у него острое расстройство и необходимость
                срочной госпитализации. При этом был врач, была милиция, которую, кстати,
                прихожане о. Михаила вызвали. И вот как, с точки зрения закона, выглядит такая
                клевета? </p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Я должен сказать, что то, о чем говорит наша
                слушательница, — это общая, так сказать, концепция этой общины<a name="r1" href="#1">[1]</a>. Там утверждается даже то,
                что бедный о. Михаил получил ранения, которые были освидетельствованы в
                милиции2, от того, что он бросался на окна<a name="r2" href="#2">[2]</a>. Т.е., как та
                унтер-офицерская вдова, которая сама себя выпорола. Но кроме того, это чистая
                клевета, то, о чем вы, слушательница, говорите, потому что о. Михаил был госпитализован
                силовым образом и в клинике ему не было поставлено никакого диагноза, наоборот,
                он был выпущен, как совершенно здоровый человек, в этот же вечер.</p>
            

            
            <p><strong><em>В.Калинин:</em></strong> ...Я не могу согласиться с вашим таким категорическим
                утверждением, что все было так, так, так...Давайте подождем. Потому что если
                будет соответствующее расследование, оно, наверное, все-таки выяснит, кто в чем
                виноват. И тогда можно будет говорить об этом без упоминаний таких слов,
                которыми вы пользуетесь: клевета и что-то еще. Давайте будем терпимы. И если вы
                прихожанка храма Георгия Кочеткова, мой вам совет: в этом случае тоже быть
                терпимой. И не надо впереди прокурора утверждать то или иное. Я как юрист могу
                сказать вам с улыбкой, что у нас действует презумпция невиновности во всех
                случаях. </p>
            

            
            <p>РАДИО “РАДОНЕЖ”,
                3 ИЮЛЯ, ЧЕТВЕРГ</p>
            

            
            <p><strong><em>Выступление
                в прямом эфире прот. Владислава Свешникова </em></strong></p>
            

            
            <p><strong><em>Ведущая
                Е.Себина:</em></strong> Я бы хотела
                спросить о тех печальных событиях, которые, к сожалению, возмутили спокойствие
                московской православной общины — так, если широко можно сказать, — и связаны с
                отцом Михаилом, который служит в храме в Печатниках. У нас очень много звонков.
                К сожалению, мы не все их можем брать в прямой эфир, поэтому, просто по просьбе
                радиослушателей, буквально, два слова. Как Вы относитесь к этим событиям?</p>
            

            
            <p><strong><em>О.
                Владислав:</em></strong> Ну, хорошо,
                буквально два слова. Я отношусь ровно так же, как относится вся студия
                “Радонеж”, и иначе относиться не могу. Мне кажется, что, в некотором смысле,
                хорошо, что случились эти события, потому что они явили, пожалуй, довольно уже определенно,
                православному миру, во всяком случае, в Москве, что являют собою не только те,
                кто руководит этой общиной, но и в общем и целом... Если у тех людей, которые
                там продолжают быть, работает совесть, она не может не подсказать, что то
                безобразие, которое там совершилось, и их собственными руками, свидетельствует
                о том, что, во-первых, нравственная сторона в жизни этой общины очень сильно,
                видимо, страдает, и в этом не может не быть вины тех, кто ей руководит;
                во-вторых, по-видимому, для всех все больше становится ясно, что желая
                почему-то оставаться в недрах и рамках Русской Православной Церкви, эта община
                все более приобретает характер замкнутой секты. И, наконец, в-третьих. Довелось
                слышать, что настоятель храма, еще до прихода отца Михаила, несколько раз
                говорил в храме о том, что священник, которого ему посылают, неканонически
                рукоположен, потому что ему нет еще тридцати лет, это канонами Православной
                церкви запрещается рукоположение до тридцати лет4. Верно. Но, простите, скажу
                грубенько: чья бы корова мычала! Известно, что главный патрон в архиерейском
                сане этого храма очень, по-видимому, доброжелательно — это самое мягкое слово,
                которое может быть — относящийся к отцу Георгию, известный владыка Кирилл был
                рукоположен в архиереи, а не в иереи, до тридцати лет<a name="r3" href="#3">[3]</a>. Так что с канонами уж
                давайте погодим. Оказывается, что на каноны смотреть стоит только тогда, когда
                это выгодно почему-либо. Оставьте, все это на самом деле некрасиво совершенно. Каноническая
                жизнь в нашей церкви, конечно, не пострадает ни от того, что владыка Кирилл был
                рукоположен до тридцати лет в архиереи, ни от того, что о. Михаил, так же, как
                огромное множество священников нашей Православной Церкви, рукоположен до
                тридцати лет — и ничего, живет Святая Церковь! И уж от этого она точно не
                умирает! Вот... Ну а объявлять священника сумасшедшим и выгонять его — это
                такое ужасное дело прихода, что о нем даже противно говорить.</p>
            

            
            <p><strong><em>Вопрос радиослушательницы: </em></strong>По поводу того, что случилось в Печатниках. Это из серии “нарочно не придумаешь”. И вот очевидно, что здесь провокация. А самый главный режиссер этой провокации — это нечистая сила. И вот вопрос такой. Как же так: люди ходят, ходят в церковь, и с той стороны, и с этой стороны, и в то же время они так вот подвластны этой нечистой силе?</p>
            

            
            <p><strong><em>О. Владислав: </em></strong>Вы знаете, прежде всего, относительно этого случившегося я бы сказал так: даже если десятая часть того, что известно, была бы правдой, достаточно было бы того, чтобы признать, что чем бы это ни было вызвано, какими бы провокационными действиями, нечистой силы или отдельных лиц, это ни вызывалось, достаточно того, чтобы было... Одной десятой части было бы достаточно для того, чтобы сказать, что в храме происходят явно далекие от православного самосознания и православного бытия явления. Ошибки слишком очевидны. И в любом случае, вызывать “скорую помощь” психиатрическую... Достаточно уже одного этого факта, и ни о чем говорить больше не приходится. Достаточно одного этого. </p>
            

            
            <p>БЕСЕДА
                ПРЕДСЕДАТЕЛЯ ОБЩЕСТВА “РАДОНЕЖ” </p>
            

            
            <p>Е. НИКИФОРОВА С
                ИГ. ТИХОНОМ ШЕВКУНОВЫМ</p>
            

            
            <p><strong><em>Радио
                “Радонеж”, 3 июля 1997 г. Несколько измененный вариант той же беседы помещен в№
                11 (55) газеты “Радонеж” (июль 1997 г.) под названием “Этот Карфаген лжи должен
                быть разрушен!”. Здесь приводится в сокращении (составители выпустили из текста
                те места, в которых затрагиваются во-первых, конфликт вокруг собора Сретения
                Владимирской иконы Божией Матери и во-вторых, конференции, направленные на
                разгром общины о. Георгия Кочеткова — об этих событиях читатель может узнать из
                предыдущих выпусков “Христианского вестника” №№ 1(3/4, 1994 г.) и 2(1996 г.)) </em></strong></p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Батюшка, если позволите, мы начнем сегодняшнюю
                беседу с заметки, которая напечатана в “Московском комсомольце”. Она подписана
                Сергеем Бычковым. Батюшка, как вы могли бы прокомментировать достоверность
                сведений, которые приводит “Московский комсомолец”?</p>
            

            
            <p><strong><em>Иг.
                Тихон:</em></strong> Конечно, можно
                было бы поиронизировать и пообличать, и прочее, и прочее, и прочее... Но вы
                знаете, нечто подобного, конечно же, все мы ждали. И все это не удивительно.
                Намного более страшно и удивительно другое: то интервью, которое я слышал по
                радио “София”. Это интервью давали люди, которые в это время присутствовали в
                храме...</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Кстати, я хочу извиниться перед нашей
                аудиторией, потому что за день до сегодняшней нашей передачи я в прямом эфире
                сказал, что интервью давал сам о. Георгий Кочетков. Это не так. Действительно,
                давали прихожане его храма.</p>
            

            
            <p><strong><em>Иг.
                Тихон:</em></strong> Да, духовные дети
                и последователи отца Георгия Кочеткова. И вот, та ложь, которую мне довелось
                услышать в этой передаче, вот это вот воистину ужасно, потому что это
                осознанная ложь, которую говорили, свидетельствуя церкви люди, которые называют
                себя людьми церковными, люди, которые называют себя людьми православными. Они
                свидетельствовали на абсолютно психически здорового, нормального, прекрасного,
                кроткого человека, называли его “безумным”<a name="r4" href="#4">[4]</a>. Люди, которые прекрасно
                знают слова Спасителя: “А кто скажет ближнему своему “безумный”, подлежит
                геенне огненной” сознательно делали это, и это иначе, как хулой на Святого Духа,
                я назвать не могу. Сознательное противление Истине. Когда они говорили о том,
                что отец Михаил сам нанес себе многочисленные раны и удары, которые
                освидетельствованы в травматологическом пункте, и есть соответствующий
                документ, то вспоминалась только унтер-офицерская вдова, которая сама себя
                высекла. Он сам нанес себе, якобы, удары, кидаясь на окна<a name="r5" href="#5">[5]</a>. Комментировать это любой
                нормальный человек откажется. Монахи, два, которых действительно я послал (я
                сам служил литургию и не мог отойти от престола), двух монахов, которые
                исповедовали, отца Анастасия и отца Никандра, я послал, чтобы они... К нам прибегали
                люди все время, люди из храма, и наши прихожане, которые находились там, два
                человека, и многочисленные прихожане других храмов и умоляли нас что-то сделать.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Но говорят, что о. Никандр был в нетрезвом
                состоянии?</p>
            

            
            <p><strong><em>Иг.
                Тихон:</em></strong> О. Никандр в том
                рапорте, который он написал на имя Святейшего... Он перед этим исповедовал
                около часа, и потом вернувшись, исповедовал еще полтора часа, и человек сто
                или, я не знаю, там, около ста, которых он исповедовал, своей христианской
                совестью подтвердят, что это просто клевета. О. Никандр пишет, что когда он
                все-таки вошел в алтарь через Царские врата, то глумясь и издеваясь, к нему
                подошли алтарники, говоря: “Батюшка, да вы выпимши. Батюшка, да вы пьяны.
                Батюшка, успокойтесь.” Мы это все испытали множество и множество раз в то
                время, когда, к несчастью, соседствовали с этой общиной.Вся жизнь в этой общине
                построена на лжи, на самой гнусной, отвратительной, недостойной христианина
                лжи...</p>
            

            
            <p>Ложь
                действительно во всем. Так, Кочетков говорит о том, что Поместный Собор 18-19
                года благословил совершение богослужений на русском языке, если епископ города
                благословляет таковое<a name="r6" href="#6">[6]</a>. Но если бы “эти
                бессмысленные кочетковцы”, как апостол Павел говорил, нашли бы труд себе
                заглянуть в постановления Собора 17-18 года, то ничего подобного они бы не
                увидели. Они могут посмотреть недавно выпущенные в Новоспасском монастыре
                “Постановления” или пойти в библиотеку и в архивы и посмотреть то же самое.
                Ничего подобного там нет! Дискуссии на эту тему, да, велись. Но в постановлениях
                Собора близко нет, напротив, Собор призывает священноначалие беречь
                церковно-славянский язык. По поводу церковно-славянского языка постоянная ложь
                исходит от Кочеткова. Он говорит о том, что святитель Феофан Затворник не
                сегодня-завтра призывает — это я говорю цитату — не сегодня-завтра призывает
                перевести наши богослужебные книги на русский язык<a name="r7" href="#7">[7]</a>. Но он недоговаривает, что
                святитель Феофан пишет: надо перевести наши священные книги, но снова на
                церковно-славянский язык. Подчеркивает: но снова на церковно-славянский язык,
                только чтобы это было более и более понятно. </p>
            

            
            <p>А что касается их
                переводов, это же тоже полнейшая ложь! Все русские переводы, которые предпринял
                о. Георгий, это просто ложь. Это просто кощунство над Священным Писанием и
                сознательная ложь. Вот о. Валентин Асмус в своей статье приводит, например,
                стих на Аллилуия, стих, который знают все православные христиане Великим
                постом: “Приложи им зла, Господи, приложи зла славным земли”. А у Кочеткова:
                “Ты приумножил народ, Господи, умножил народ, — прославил Себя распространил
                все пределы земли<a name="r8" href="#8">[8]</a>”.
                Вот на такой лжи строятся его переводы.К какому Православию, какой вере, какому
                Богу приведет, основываясь на такой лжи, человек людей, которые доверились ему.
                Он приведет его к отцу лжи! Больше ни к кому. </p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Батюшка! Но все же, может быть, мы вернемся
                к тому конфликту, я бы даже сказал, той трагедии, которая произошла в храме,
                этому преступлению, можно даже сказать, которое было совершено непосредственно
                Кочетковым и его ближайшими сподвижниками. Вот то, что описал С. Бычков, не
                могли бы вы все-таки сказать, что действительно произошло. Вот сейчас прошло
                уже несколько дней, картина более или менее ясна. Подробно: что произошло в
                храме?</p>
            

            
            <p><strong><em>Иг.
                Тихон: </em></strong>Произошло то, что
                в течение почти двух месяцев назначенный вторым священником в храм Успения в
                Печатниках о. Михаил Дубовицкий подвергался изощреннейшим издевательствам. Вот,
                я принес специально письмо мне одной нашей прихожанки, Екатерины Толстой,
                которая вместе с другими прихожанами и нашего храма и других храмов была
                свидетельницей этих издевательств. Вот что она пишет: “Мне довелось быть
                свидетелем издевательств и кощунственного отношения над православным священником
                о. Михаилом, на службах 7 июля и 21 июля. Во время богослужения литургии члены
                общины бросали оскорбительные реплики, перебивали священника. Во время
                проповедей пытались заглушить, крича хором: “Господи, помилуй!” Принимать
                божественное причастие из рук о. Михаила отказывались. На моих глазах одна
                прихожанка перед служением молебна подошла к о. Михаилу и похлопав его по плечу
                попросила помолчать, чтобы дать им возможность помолиться одним. Рядом услышала
                реплику: “Ты такой святой у нас, что тебе только губки и уксуса не хватает!”
                Вот это вот слышали несколько человек, стоящих рядом, которые могут
                христианской совестью своей это засвидетельствовать. И вы знаете, и прихожане
                о. Георгия тоже в ужасе. Некоторые подходили ко мне, говорили: “О. Тихон, вы
                простите, вы, конечно, так сказать, почти антагонисты, но мы в ужасе от того,
                как издеваются над этим несчастным молодым человеком, молодым священником”. Это
                действительно жестоко и ужасно. Это бесчеловечно. И сейчас это бесчеловечие
                доходит до того, что здорового человека... Ну, действительно, любой человек с
                поврежденной психикой сошел бы с ума от тех издевательств, которыми исполнена
                была его жизнь вот эти вот последние два месяца. И вот его хотят объявить
                сумасшедшим, и вот в газетах об этом печатают, и диагноз... С. Бычков пишет,
                что был поставлен диагноз, что он... у него...</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Острое психотическое состояние.</p>
            

            
            <p><strong><em>Иг.
                Тихон:...</em></strong>в этот же день.
                Через несколько часов после помещения в больницу состоялся консилиум, который
                признал о. Михаила совершенно здоровым, совершенно здоровым<a name="r9" href="#9">[9]</a>. На следующий день действительно
                состоялся консилиум, который еще раз признал просто полностью его состояние
                психически совершенно нормальным и сейчас, я думаю, в связи с тем, что, по всей
                видимости, назначат расследование этого просто беспрецедентного случая, то его
                психическое состояние будет освидетельствовано самым-самым серьезным образом.
                То, что произошло в этот день, вы знаете. Над ним было совершено
                надругательство: он был избит, с него были сорваны священнические одежды по
                наущению о. Георгия, это его духовные чада это делали, он был объявлен
                сумасшедшим, кощунственная началась молитва... Человека, которого повезли на
                Голгофу, в буквальном смысле слова чуть не убили, потому что о. Михаил был при
                смерти после того, как ему по непонятным совершенно причинам против всех законов
                и медицинских, и юридических ввели, по всей видимости, огромную дозу
                нейролептиков, после чего, через некоторое время, у него начались страшные
                судороги, и он, как говорят врачи, мог умереть от удушья. Так вот, когда они
                посылали на Голгофу, они за него еще и молились! Т.е., более кошмарной
                ситуации, более нечто немыслимого Русская церковь не знала, ну, я не знаю,
                просто боюсь впадать в исторические экскурсы. А отношение к матушке?! Когда
                несчастная женщина, вот действительно, трепеща позвонила и попросила: “Скажите
                нам только... мне только, где мой батюшка, чтоб я нашла”, — люди, которые
                прекрасно знали, в какую больницу они его определили, расхохотались, как она
                говорит, ей в лицо, женщина какая-то, когда второй раз она звонила,
                расхохоталась ей в лицо и сказала: “Ищи сама его по психбольницам, тоже мне,
                матушка.” Вот такой жестокости просто не бывает, не бывает по отношению... ни к
                кому, ни к животному, ни к людям. “Блажен, иже и скоты милует.” Но здесь, по
                всей видимости, мы для них даже не скоты, нечто другое. Все это страшно. Все
                это просто страшно. Мы, зная даже нрав этой общины и зная, насколько далеко
                зашло их духовное нечувствие, и при всем том, что они все время говорят о
                любви, о свободе и так далее, и так далее, мы, честно говоря, даже не ожидали,
                что нечто подобное может случиться. Сейчас о. Михаил освидетельствован в
                травмопункте, сняты фиксации многочисленных ран, и о. Анатолий Берестов,
                профессор, тоже это все видел. Просто здесь уже, конечно же, решает
                священноначалие, но люди просто, просто в ужасе... </p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Меня, Вы знаете, что удивило, приехали к нам
                православные люди из Твери, а православные по всей стране обеспокоены этими
                метастазами кочетковской этой церкви... Вот эти кочетковцы в Твери также
                проявились. И вот как любопытно. Они, с одной стороны, проповедуют как бы такую
                свободную, такую мистическую Церковь, харизматическую Церковь, где сплошная
                свобода от каких-то больно жестких канонов и правил, а, с другой стороны, когда
                начинается утреня на всенощном бдении, они все поднимались и уходили. Т.е., с
                одной стороны, законничество, да, а с другой стороны, проповедь неизъяснимой,
                безграничной свободы.</p>
            

            
            <p><strong><em>Иг.
                Тихон:</em></strong> Евгений
                Константинович, можно долго и бесконечно говорить об этой лжи, потому что ложь,
                к несчастью, бесконечна... </p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> В таком случае, ну где же тогда это системообразующее качество, вот тот критерий, в котором объединяется весь набор этих несуразностей?</p>
            

            
            <p><strong><em>Иг. Тихон: </em></strong>Создается параллельная структура в Русской православной церкви, основанная на отрицании всего, что есть в Церкви. Вот эта вот структура не только создается, она уже создана, она работает совершенно однозначно на разрушение и на прелесть людей, которые, к несчастью своему, дали себя обмануть... Действительно, несчастнейшие люди. Я могу здесь только вспомнить слова о. Иоанна Крестьянкина, нашего старца, который, прекрасно знает эту ситуацию и который сам пережил обновленчество и пережил от обновленцев очень много, и он говорил и мне, и не только мне, многим, что если мы вот это вот страшное движение не разорим, то они разорят церковь. Этот Карфаген должен быть разрушен.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Я знаю, что у Вас сегодня день пострига и
                день рождения, и хотел бы вас поздравить и пожелать вам крепости духовной в
                стоянии в святом православии, благоустроении вашему монастырю и, к сожалению,
                мне нечего даже вам подарить в этот день, столь радостный вдвойне для Вас.</p>
            

            
            <p><strong><em>Иг.
                Тихон:</em></strong> Ну, спасибо
                большое за поздравления. Но подарок вот мне уже сделал “Московский комсомолец”
                этой статьей. А для монаха какой самый лучший подарок в день пострига?
                Поношение! Так что огромная благодарность Сергею Бычкову за этот прекрасный,
                действительно, для монаха подарок. Спасибо. </p>
            

            
            <p>БЕСЕДА Е.
                НИКИФОРОВА </p>
            

            
            <p>С ЗАВ.
                КАНЦЕЛЯРИЕЙ МОСКОВСКОЙ ПАТРИАРХИИ </p>
            

            
            <p>ПРОТ. ВЛАДИМИРОМ
                ДИВАКОВЫМ<a name="r10" href="#10">[10]</a></p>
            

            
            <p><strong><em>Радио
                “Радонеж”, 3 июля 1997 г.</em></strong></p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> О.Владимир, когда Московской Патриархии
                стало известно о трагедии, которая произошла в храме Успения в Печатниках?</p>
            

            
            <p><strong><em>О.
                Владимир Диваков:</em></strong> Дело в
                том, что я... Как вам сказать, я, наверное, скажу о себе. Я закончил
                Божественную Литургию, и вдруг, значит, какой-то звонок. Меня просят подойти. Я
                подошел. Взволнованные прихожане, это духовные дети о. Михаила, сообщают, что,
                значит, батюшку избили, избили алтарники и члены этой общины о. Георгия, и (неразборчиво)
                отвезли в больницу. Знаете, поначалу это как-то не укладывалось в голове. Я
                просто опешил и не знал даже, что отвечать. Я потерял дар речи. Ну как в
                православном храме могло быть такое? Да это просто невозможно. Вот
                представьте... И я даже не нашелся, что им сказать с перепугу. Потом положил
                трубку и стоял как ошарашенный. На меня все смотрят: может, что-нибудь
                случилось? Я говорю, вот знаете, такое известие получил, что-то непонятное. Ну
                не может быть такое. И пошел тут же к о. Олегу, спросил: о. Олег, Вы —
                благочинный, скажите, что там произошло в Печатниках. И он говорит: не знаю. Я
                ему объяснил: Вы знаете, вот такой звонок был. — Да не может быть! Просто
                схватился за голову. Это какой-то кошмар. Ну вот. А что произошло? А что
                произошло, батюшке не давали сначала канон читать, потом не давали первый час
                читать, потом его затолкали в алтарь, чтобы народ там не видел, потом начали
                избивать. Он звал о помощи, вот. Но поскольку простые смертные не имеют доступ
                в алтарь, естественно, они не могли его защитить. Потом появились омоновцы.
                Потом появилась карета скорой помощи. И в конечном счете, говорит, увезли
                батюшку, говорит, уже избитого, порванный подрясник на нем. Я скажу, о. Олег
                говорит, слушайте, я даже не знаю, что мне сейчас делать. Пойти туда — но меня
                несколько дней тому назад, когда был он там, его там в храм не пускали, самого
                благочинного не пускали в храм, там пикет устроили — и не пускали. Расшвыривал
                руками для того, чтобы пробиться в храм. Вот по этому поводу о. Георгию звонил,
                протест выражал. О. Георгий сделал вид, что не знает, кто это и что это такое,
                хотя это его духовные чада. Поэтому он говорит, я даже не знаю, как ехать туда?
                Может, сейчас чуть-чуть остынет, я все-таки постараюсь как-нибудь выяснить это.
                Позвонил я в Патриархию тут же. Может, Патриархия хотя бы знает? Ну не может
                быть, чтобы в Патриархии-то не знали, не сообщили Патриархии о таком случае.
                Приезжаю в Патриархию, в Патриархии дежурный священник говорит: вы знаете, нет,
                я неотступно был у телефона, и никаких сообщений не поступало. Из Печатников не
                поступало? Нет, не поступало. Куда ни звонил, никто ничего не знает. Потом уже
                и домой звонил о. Михаилу, его нет дома. Потом уже где-то пополночь дозвонился,
                и сказали, что вот, его действительно привезли из психбольницы, но никаких,
                признали его здоровым, отпустили домой, так что никаких, все благополучно. Но
                благополучно, благополучно, но мне никак не верилось, что вообще такое могло
                случиться. Ну может, какой-то нервный срыв, что-то случилось все-таки? И запросила
                Патриархия все-таки психиатрическую больницу: в чем дело? Причина какая? Вот
                тут и узнали из ответа психиатрической больницы, что он был в нормальном
                состоянии, никаких там не было причин для госпитализации. Никакого нервного
                срыва не было. На следующий день я пришел в Патриархию, и первое, что я пытался
                уточнить, что есть ли какое-нибудь сообщение, хотя бы письменное, от настоятеля
                в Печатниках? В чем дело, что там произошло? Оказалось, никакого письменного сообщения
                нет. И до сего времени, как говорят, нет. Хотя где-то там уже благочинный добивался,
                от кого-то что-то узнавал, как это происходило, вот, но в то же время,
                официального такого сообщения нет.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Вроде бы его нет даже в Москве. </p>
            

            
            <p><strong><em>О.
                Владимир:</em></strong> Да, он уехал в
                паломническую поездку. Он заранее испросил благословения у Святейшего, это
                понятно, но если такое случилось, даже если он заранее получил благословение,
                но я думаю, что он должен был остаться, это чрезвычайное событие. Это только
                было во времена, не знаю какой, советской власти и во время гонений на церковь,
                чтобы избивали священника. А тем более, в святая святых — в алтаре. Это же
                просто не укладывается в голове. В сознании православного человека не
                укладывается такое. И больше того, как мне рассказали, что вот, те православные
                рассказывали, что во время избиения, поскольку о. Михаил кричал, то там
                Копировский, он значит, в это время регентовал народом, чтобы народ пел, и
                громче пел, он призывал громче петь, чтобы заглушить крики и это (истязание?
                неразборчиво) его<a name="r11" href="#11">[11]</a>. А этот Копировский один
                из кандидатов о. Георгия Кочеткова к рукоположению в священный сан.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Нет, действительно не укладывается в
                сознании. </p>
            

            
            <p><strong><em>О.
                Владимир:</em></strong> Да.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Батюшка, насколько я знаю, если даже просто
                тяжело заболевает клирик, настоятель обязан поставить в известность
                священноначалие.</p>
            

            
            <p><strong><em>О.
                Владимир:</em></strong> Несомненно,
                несомненно обязан поставить в известность священноначалие. Так всегда и бывает.
                Вот. Но в данном случае этого не произошло. У нас бывает так. Или инфаркт, или
                же что-нибудь случается с человеком, какое-нибудь заболевание случается, или
                умирает человек, тут же рапорт следует от настоятеля Святейшему Патриарху. В
                данном случае этого не было.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Батюшка, но как с этим сейчас быть? В общине,
                и с богослужением в храме? Ведь вот хотелось бы знать, может ли совершаться
                божественная литургия в алтаре, где избивали священника, и пролилась кровь,
                возможно?</p>
            

            
            <p><strong><em>О.
                Владимир:</em></strong> Кровь
                пролилась, (очевидцы? неразборчиво) говорят, что поскольку у него были ссадины
                и все, пролилась кровь. Если пролилась кровь, то, конечно, не имеет права в том
                месте уже совершаться богослужение, должно быть переосвящено это место,
                освящено заново. Но в отношении алтаря там, знаете, особый разговор. Дело в
                том, что там алтарь-то практически это ж не алтарь. Там это четверик храма,
                который приспособлен под алтарь временно. По непонятным причинам там временно
                существует алтарь. Когда я еще был благочинным того округа, я неоднократно
                напоминал, что там есть же алтарь, который расположен за этим алтарем, он
                используется как складское помещение<a name="r12" href="#12">[12]</a>, что его следует восстановить,
                и в нем следует служить. Но, видимо, тому мешает, что там, в том месте, где
                царские врата, там идет лестница наверх, в том месте, где устроил сейчас себе
                кабинет о. Георгий. И, видимо, он не хочет лишаться этих удобств, и поэтому,
                видимо, так этот алтарь продолжает существовать. А так-то, конечно, должно быть
                переосвящено, и только после этого может совершаться богослужение. Это уже
                естественно, поэтому тут все канонические правила, поэтому невозможно совершать
                богослужение, естественно, в таком виде, в каком сейчас есть.</p>
            

            
            <p><strong><em>Е.Никифоров:</em></strong> Спасибо. </p>
            

            
            <p>ТЕЛЕПЕРЕДАЧА
                “РУССКИЙ ДОМ”, 3 ИЮЛЯ </p>
            

            
            <p><strong><em>Голос
                за кадром:</em></strong> Это произошло
                29 июня, в день всех русских святых, во время Божественной литургии.
                Надругательство над священником Михаилом Дубовицким потрясло всех православных,
                бывших в этот день в храме. Вот что рассказывают очевидцы событий,
                происходивших в течение 2-х месяцев<a name="r13" href="#13">[13]</a>.</p>
            

            
            <p><strong><em>Владислав
                Аксенов:</em></strong> Там происходила
                травля православного священника. Травили всем приходом, грамотно, сознательно,
                всей стаей. Прерывали службы, не давали ему говорить, постоянно улюлюкали,
                крики “Господи, помилуй” — это не молитва “Господи помилуй”, это крики какие-то.
                Не давали ему говорить проповеди, отключали микрофон. Кинокамера, которая не
                отключалась ни на секунду буквально, эту кинокамеру уткнули ему в лицо<a name="r14" href="#14">[14]</a>. Он говорил: “Я не
                благословляю снимать, отойдите от меня, оставьте меня в покое”, — ноль внимания.</p>
            

            
            <p>Владимир Сергеев<a name="r15" href="#15">[15]</a>: Он сразу показал, что он
                православный священник, не пойдет на уступки, ни по каким книжечкам, брошюркам
                не будет служить, которые Кочетков ему подсовывал, и собирается служить, по
                которому благословил Святейший патриарх, по тому требнику, которым его благословил.
                Все его люто ненавидели. Это было видно и на их лицах, т.е. они игнорировали
                его полностью, или же с какими-нибудь словами обращались к нему, или даже
                действиями — толкали, заминали плечами, вырывали у него требник.</p>
            

            
            <p><strong><em>Д.С.Чураков:</em></strong> Всякое слово о. Михаила, всякая проповедь,
                перед Евангелием, после Евангелия, во время богослужения, сейчас же выходил священник
                Георгий Кочетков и начинал все это говорить противоположное, хотя о. Михаил
                говорил строго то, тому, чему учит вся Православная церковь.</p>
            

            
            <p><strong><em>Иером.
                Никандр:</em></strong> О. Георгий
                Кочетков мне сказал, что о. Михаил находится в состоянии нервного срыва, у него
                острый психический невроз или что-то в таком духе и он нуждается в срочной
                изоляции <strong><em>(!!! — ред.)</em></strong>. Его тащили несколько человек, прямо волоком,
                совершенно зверски, отвратительно, миряне и милиция, тащила его к выходу через
                запасной... к запасному выходу алтаря, где, я увидел, стояла машина скорой
                помощи. На меня тут же, не дав ступить двух шагов, набросились очень крепкие
                молодые люди, они и все время находились в алтаре.</p>
            

            
            <p><strong><em>Свящ. Михаил Дубовицкий: (интервью в больнице)</em></strong>: Я совершал богослужение строго по тем канонам и правилам, которые положены в Русской православной церкви. В частности, на церковнославянском священном языке, который о. Георгий кощунственно оскверняет своим таким богослужением, переводами такими самочинными, которые никто из иерархов не благословлял его совершать богослужение на этих самочинных его переводах. В случае либерального нашего отношения к этому явлению может настолько секуляризировать церковь, что она потом не будет уже иметь той духовной силы, по которой собственно и получила она название: наша великая Россия и святая Русь.</p>
            

            
            <p><strong><em>А.Крутов,
                ведущий телепрограммы:</em></strong> В
                течение двух месяцев чада о. Георгия Кочеткова издевались над священником —
                священником, которого прислал им Святейший патриарх. И как в самые дремучие
                советские времена здорового человека — вы сами это видели — объявили
                сумасшедшим и отправили в психбольницу. Я был в этот день на литургии в соседнем
                храме, в Сретенском монастыре и видел, как к иг. Тихону пришли взволнованные
                люди, что-то объясняли и один из монахов поспешил — это был иером. Никандр —
                как оказалось, в соседний храм, где происходила расправа духовных чад Георгия
                Кочеткова над неугодным священником. То, что вы видели сейчас в репортаже, это
                малая часть того, что произошло. И вот, слушая рассказ очевидцев тогда, я
                подумал: ведь на катехизических курсах чада духовные Георгия Кочеткова
                обязательно слышали слова Евангелия: ”кто скажет ближнему своему “безумный” —
                подлежит геенне огненной.” Во что же они, обновленцы, ставят слова Христа, если
                поступают, как фарисеи: здорового человека отправляют в больницу, а сами в это
                время лицемерно молятся о его здоровье. И это в то время, когда о. Михаилу,
                здоровому человеку, вкалывают лошадиную дозу нейролептиков, от которой он чуть
                не умирает и, как видите, до сих пор находится в больнице. И вот у нас в гостях
                иером. Анатолий Берестов, он встречался с о. Михаилом, видел его, что Вы можете
                сказать, как Вы оцениваете то, что произошло в этом храме.</p>
            

            
            <p><strong><em>Иером.
                Анатолий Берестов:</em></strong> Я не
                могу согласиться с термином “неообновленчество”. Это давнее обновленчество
                наших старых 20-х годов. Это во-первых. Я был уверен в скандальном конце
                пребывания о. Михаила в этом обновленческом протестантском храме. Иначе, я
                другого не подозревал. Я только не предполагал, что столь страшная, трагическая
                концовка будет пребывания о. Михаила в этом храме. Ну, что я могу сказать? Я
                видел о. Михаила не один раз после трагических событий. Во-первых, 29 июня — в
                тот день, когда произошла эта трагедия для всей Русской православной церкви,
                мне позвонили мои знакомые и как врача попросили меня выяснить о состоянии
                здоровья о. Михаила. Я созвонился с 14-й психиатрической больницей, с дежурным
                врачом 9 отделения Яшиным Николаем Анатольевичем и сразу выяснил, что они
                считают о. Михаила практически здоровым в психическом отношении человеком. Надо
                отдать им должное, что в этот же день они его выписали не под расписку, как
                больного, а без расписки, как здорового. На следующий день Московская
                Патриархия получила ответ на свой запрос от администрации больницы, что
                комиссионно о. Владимир (поправляется) о. Михаил был признан практически
                здоровым<a href="#_ftn16">[16]</a>.</p>
            

            
            <p><strong><em>А.Крутов:</em></strong> О. Михаил, простите, о. Анатолий, вот Вы —
                врач-психиатр.</p>
            

            
            <p><strong><em>Иером.
                Анатолий:</em></strong> Невропатолог. </p>
            

            
            <p><strong><em>А.Крутов:</em></strong> Невропатолог<a name="r17" href="#17">[17]</a>. Скажите, имели право
                врачи забирать в психушку о. Михаила?</p>
            

            
            <p><strong><em>Иером.
                Анатолий:</em></strong> Конечно, они
                не имели такого права. И у меня создается такое впечатление, что здесь заранее
                спланированная акция. Что произошло, смотрите: в этот день в алтаре оказался
                посторонний человек с видеокамерой, посторонняя женщина (вы знаете, что по православным
                правилам женщина не может быть в алтаре). Эта женщина — врач-психиатр. Скажите,
                случайно это или нет? Оказались вооруженные боевым оружием милиционеры, потом —
                ОМОН. Происходила дикая травля о. Михаила и в результате приехала скорая помощь
                психиатрическая и он насильственно был госпитализирован.</p>
            

            
            <p><strong><em>А.Крутов:</em></strong> Недавно по радиостанции “София”<a name="r18" href="#18">[18]</a> выступали одни кочетковцы
                и говорили, что врачи обнаружили следы побоев. Вот они говорят, что о. Михаил
                сам себя побил, бросаясь на окна, ну, как унтер-офицерская жена.</p>
            

            
            <p><strong><em>Иером.
                Анатолий (описывает следы побоев):</em></strong>
                И на спине, и на руках, и на ногах, и спереди, и сзади.</p>
            

            
            <p><strong><em>А.Крутов:</em></strong> Скажите, ну а зачем врачам было нужно
                вводить эту огромную дозу нейролептиков?</p>
            

            
            <p><strong><em>Иером.
                Анатолий объясняет, что неизвестно, была ли эта доза, просто симптомы похожие.
                К сожалению, в 7-й горбольнице нет токсикологического отделения и невозможно
                провести анализ крови на содержание нейролептиков. </em></strong></p>
            

            
            <p><strong><em>А.Крутов:</em></strong> Вот получается, что так, хитростью, обманом,
                засаживают здоровых, но неугодных священников в психбольницы. Может быть, это и
                есть то новшество, которое вводит Георгий Кочетков в церковную жизнь Москвы?</p>
            

            
            <p><em><strong>Иером. Анатолий:</strong> </em>Я не побоюсь сказать, я уверен, что не возьму греха на душу, назвав его клятвопреступником... Когда архиерей рукополагает ставленника, перед посвящением читается присяга. Он же нарушил эту клятву. Поэтому можно констатировать, что он является клятвопреступником. Безусловно, это и привело его в конце концов к таким страшным, криминальным последствиям.</p>
            

            
            <p><strong><em>А.Крутов:</em></strong> Ну что ж, о. Анатолий. Спасибо, что Вы нашли
                время прийти к нам в “Русский дом”. Я думаю, что эта трагедия, происшедшая в
                храме в Печатниках, просто так не затухнет. И мы еще вернемся к нему. И я
                думаю, что все православные миряне и священники, и священнослужители ждут, как
                разрешится эта трагедия. Ведь если нет правды на земле, то ведь на небе-то она
                обязательно есть. И неужели вот по этому пути, пути клеветы и психушек
                церковные реформаторы предлагают идти Русской православной церкви? Неужели
                Русская православная церковь пойдет по этому пути, предлагаемому реформаторами?</p>
            

            
            <p>ОБРАЩЕНИЕ “ЧЕРНОЙ
                СОТНИ” </p>
            

            
            <p>В ГАЗЕТЕ “ЧЕРНАЯ
                СОТНЯ” № 7—8 (49—50)</p>
            

            
            <p>Ваше
                Святейшество!</p>
            

            
            <p>Редакция газеты
                “Черная сотня” обращается к Вам в связис вопиющим нарушением священником
                Московской Патриархии о. Георгием Кочетковым Апостольских правил. Согласно
                Апостольским Правилам N 27, 56, а также Правила Двукратного Собора N 9, данный
                священник должен быть немедленно извержен из сана.</p>
            

            
            <p>Православной
                общественности давно известны еретические взгляды и действия Кочеткова
                (дом.тел.<a name="r19" href="#19">[19]</a>)
                и иже с ним. В Православной печати не раз говорилось о том, что Кочетков,
                откровенный приверженец “обновленного” Православия, изменяет по своему
                усмотрению церковное богослужение, переводит богослужение на разговорный язык,
                заводит в общине баптистские порядки. До поры до времени это сходило ему с рук.
                Сходило с рук даже то, что с момента назначения в храм Успения Богородицы
                священника отца Михаила (Дубовицкого) над последним с благословения Кочеткова
                буквально глумились баптиствующие поклонники священника-еретика.</p>
            

            
            <p>Всякий предел
                христианскому долготерпению пришел в День всех Святых, в земле Российской
                Просиявших — 29 июня. В этот день варвары избили отца Михаила и незаконно
                отправили его в психиатрическую больницу (подробности этого уголовного
                преступления — в приложении). Разумеется, и милиционеры, и сотрудники “Скорой
                помощи” ответят за свои действия, но речь не о гражданских лицах.</p>
            

            
            <p>Терпеть более
                Кочеткова в сане священника у православных сил нет. От имени редакции, от имени
                соратников Черной Сотни я ответственно заявляю, что Православно-патриотические
                организации, включая казачество, до такой степени возмущены случившимся, что
                могут, не дожидаясь Вашего решения, самостоятельно решить судьбу еретика и
                провокатора: так, как это делалось прихожанами встарь — за бороду — и из храма
                долой.</p>
            

            
            <p>Нижайше просим
                Вас защитить чистоту Православной Веры от неообновленца и провокатора Георгия
                Кочеткова, к сожалению, пока еще “отца”.</p>
            

            
            <p>С надеждой на то,
                что наша просьба будет услышана,</p>
            

            
            <p>от имени соратников
                Черной Сотни</p>
            

            
            <p>Раб Божий
                Александр (Штильмарк)</p>
            

            
            <p><strong><em>В
                течение периода расследования инцидента (о том, как оно проходило, рассказывает
                раздел “Комиссия”) “Радонеж” и “Русский дом” не забывали ни о. Георгия, ни его
                общину. Краткие, но постоянные воспоминания о них составили около 6 часов
                вещания за три месяца — почти ничего (за одну неделю, прошедшую с 29 июня, было
                потрачено столько же эфирного времени). Здесь мы приводим лишь некоторые
                характерные и любопытные фрагменты.</em></strong></p>
            

            
            <p>РАДИО “РАДОНЕЖ”,
                22 ИЮЛЯ<a name="r20" href="#20">[20]</a> </p>
            

            
            <p><strong><em>Дьяк.
                Андрей Кураев:</em></strong> Что ж,
                кончается месяц, который в жизни нашей церкви, особенно Московской епархии,
                полон был размышлениями над тем инцидентом, который произошел в конце июня. Вы
                все знаете, что запрещен в священнослужении священник Георгий Кочетков, создана
                по распоряжению Святейшего патриарха комиссия, которая будет заниматься
                происшествием в храме Успения в Печатниках. Комиссию возглавил еп. Алексий
                Орехово-Зуевский, наместник Новоспасского монастыря в Москве, человек, который
                известен своими строго православными взглядами и который никогда не скрывал
                отрицательного отношения к тем экспериментам, прежде всего богослужебным,
                которые проводились на приходе о. Георгия Кочеткова. Поэтому в общем-то можно
                считать, что проблема угрозы нашей церкви слева в общем-то ослабла...</p>
            

            
            <p>ГАЗЕТА “РАДОНЕЖ”
                № 16—17(61), ОКТЯБРЬ 1997 г.</p>
            

            
            <p><strong><em>Анонимный
                материал</em></strong></p>
            

            
            <p>Дело
                священника-обновленца Георгия Кочеткова близится к своему логическому
                завершению. Около трех месяцев длилось в Министерстве здравоохранения
                внутриведомственное расследование драматических событий, происшедших в храме
                Успения Богородицы в Печатниках, когда там 29 июня этого года по инициативе
                священника Георгия Кочеткова по ложному диагнозу был насильственно
                госпитализирован в психиатрическую клинику его собрат по служению у престола
                Божия о. Михаил Дубовицкий. Вопрос о здоровье (или нездоровье) о. Михаила в
                этой истории был ключевым, поскольку наличие психического заболевания у него
                отчасти (по крайней мере, в глазах общественного мнения) оправдывало применение
                физической силы в момент его разоблачения мирянами священнических одежд,
                двухчасовое удержание в алтаре до приезда психиатра, получение множественных
                кровоподтеков и ссадин во время принудительного водворения его в карету «скорой
                помощи», временную потерю трудоспособности. Именно поэтому в эти месяцы так
                немилосердно упорствовали священник Кочетков, участники насилия и их
                многочисленные защитники в своих обвинениях в сумасшествии о. Михаила, несмотря
                на то, что к вечеру 29 июня (а потом — еще дважды) он был освидетельствован
                психиатрами и признан здоровым. Эта клевета, растиражированная радиоэфиром и
                многими газетами, гуляет теперь по всему свету. Специальная комиссия
                Министерства здравоохранения, рассматривавшая лишь медицинскую сторону этого
                инцидента, пришла к заключению, что о. Михаил Дубовицкий психически абсолютно
                здоров и никаких медицинских поводов к принудительной госпитализации не было
                обнаружено, и признала действия медработников, попустивших насилие,
                неправомерными. Министерство здравоохранения принесло о. Михаилу свои искренние
                извинения, показав тем самым некоторым людям, называющим себя православными,
                пример истинного христианского отношения к человеку. <br/></p>
            


            <hr class="wp-block-separator"/>


            
            <p><a name="1" href="#r1">[1]</a> &nbsp;Участковый милиционер — см. раздел “Говорят
                документы” — вопреки утверждению Е.Никифорова, засвидетельствовал, что насилия над
                о. Михаилом в храме Успения не совершалось. </p>
            

            
            <p><a name="2" href="#r2">[2]</a>
                &nbsp;Прихожане Успенского храма никогда этого
                не утверждали.&nbsp; Выступая накануне, 1 июля,
                по радио “София”, они утверждали прямо противоположное. </p>
            

            
            <p><a name="3" href="#r3">[3]</a> О. Георгий
                до прихода о. Михаила ничего подобного говорить не мог, так как не был предупрежден
                заранее о том, что в его храм направляется второй священник. Об этом он узнал только
                от самого о. Михаила. Уже позже, когда о. Михаил обвинил о. настоятеля в нарушении
                канонов, тот попросил указать, каких именно, и добавил: не вам бы, священнику, рукоположенному
                неделю назад, и к тому же, до наступления тридцатилетнего возраста, говорить о нарушении
                канонов. </p>
            

            
            <p><a name="4" href="#r4">[4]</a> Этого слова
                в радиопередаче “Православная община” от 1 июля 1997 г. не произносилось — см. расшифровку
                передачи в разделе “Нет ничего тайного, что не было бы узнано”. </p>
            

            
            <p><a name="5" href="#r5">[5]</a>
                См. расшифровку передачи в разделе “Нет ничего тайного, что не было бы узнано”.</p>
            

            
            <p><a name="6" href="#r6">[6]</a> Имеется в
                виду Поместный собор Российской православной церкви 1917-1918 гг. О решении Собора
                по вопросу о богослужебном языке см. статью В.Котта в журнале “Православная община”
                №46. </p>
            

            
            <p><a name="7" href="#r7">[7]</a>
                Точнее об этом см. раздел “Дискуссия о богослужении” в приложении к настоящему сборнику.&nbsp; </p>
            

            
            <p><a name="8" href="#r8">[8]</a>
                Этот стих взят из синодального перевода с еврейского подлинника, который, естественно,
                отличается от церковно-славянского перевода с греческого подлинника. </p>
            

            
            <p><a name="9" href="#r9">[9]</a> См. заключение
                комиссии Минздрава в разделе “Медицинские документы”.</p>
            

            
            <p><a name="10" href="#r10">[10]</a> Об этом
                же см. беседу с А. Копировским в разделе “Нет ничего тайного, что не было бы узнано
            </p>
            

            
            <p><a name="11" href="#r11">[11]</a> А. М. Копировский
                в этот день вышел&nbsp; регентовать только на Литургии,
                уже после того, как о. Михаила увезла скорая помощь. </p>
            

            
            <p><a name="12" href="#r12">[12]</a>
                В старом алтаре располагалась храмовая ризница.</p>
            

            
            <p><a name="13" href="#r13">[13]</a> Оговорка понадобилась, т.к. двое из четырех “свидетелей” не были 29 июня в храме.</p>
            

            
            <p><a name="14" href="#r14">[14]</a> В храме и&nbsp; до назначения о. Михаила часть служб записывалась на видеокамеру. После его прихода камера включалась только если о. Михаил начинал вести себя неадекватно и только затем, чтобы иметь документальное свидетельство бесчинств второго священника.</p>
            

            
            <p><a name="15" href="#r15">[15]</a> Владимир Сергеев, алтарник о. Александра Шаргунова — тот самый человек, который закричал в храме: “Не приближайтесь ко мне, нечистые!”</p>
            

            
            <p><a name="16" href="#r16">[16]</a> См. соответствующие
                документы в разделе “Говорят документы”.</p>
            

            
            <p><a name="17" href="#r17">[17]</a>
                Иером. Анатолий (Берестов) — специалист по детскому церебральному параличу. </p>
            

            
            <p><a name="18" href="#r18">[18]</a> См. расшифровку
                передачи “Православная община” от 1 июля в разделе “Нет ничего тайного, что не было
                бы узнано”. </p>
            

            
            <p><a name="19" href="#r19">[19]</a> Здесь приводится
                — видимо, для сведения заинтересованных в беседах с батюшкой лиц — номер домашнего
                телефона о. Георгия Кочеткова, который мы нашли уместным опустить.</p>
            

            
            <p><a name="20" href="#r20">[20]</a> &nbsp;этот день о. Георгию и его прихожанам стало известно
                о том, что десять дней назад была создана и приступила к работе церковная комиссия
                по расследованию случавшегося 29 июня.</p>
            
        </div>
    );
};

export default Zaos5;
