import React from "react";
import './text.css';
const Zaos8 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Глава 8. Что же дальше?</h2>
            
            <p>Глава 8. Что же
                дальше? </p>
            

            
            <p>“Как Вы знаете,
                Ваше Святейшество, и я, грешный, прихожанин храма Успения в Печатниках, и не
                стану отказываться от уз, меня с приходом соединяющих; менее всего — в минуту,
                для него столь горькую. Не скрою также, что мне трудно вообразить </p>
            

            
            <p>себе лиц, которых
                я знаю, за делом, до такой степени на них непохожим. Но я стараюсь исходить не
                из собственных чувств — “за” или “против” кого бы то ни было. Мое скорбное
                удивление относится к тому, что высокоавторитетные для верующего распоряжения
                Вашего Святейшества, назначая расследование происшествия и объявляя состав
                учреждаемой&nbsp; для того комиссии, прежде
                всякого расследования уже однозначно предрешают&nbsp;
                безусловную правоту одной версии. </p>
            

            
            <p>В течение годов
                Вашего Патриаршего служения можно было радоваться тому, насколько тщательно
                Ваше Святейшество избегало отождествить себя с одной из крайних позиций </p>
            

            
            <p>в Церкви, держась
                от них на более или менее равной дистанции. Тем более поразил меня текст
                вышеназванных постановлений, отчетливо предполагающий, что приговор,
                собственно, </p>
            

            
            <p>уже вынесен.
                Неужели это вправду так?”</p>
            

            
            <p>(Из обращения
                академика Сергея Аверинцева </p>
            

            
            <p>к патриарху
                Алексию II)</p>
            

            
            <p>СВЯТЕЙШИЙ
                ПАТРИАРХ НЕ ДОПУСТИТ НЕПРАВДЫ,</p>
            

            
            <p>СЧИТАЕТ СВЯЩЕННИК
                ГЕОРГИЙ КОЧЕТКОВ</p>
            

            
            <p>Интервью
                корреспонденту газеты “Интерфакс-АИФ” </p>
            

            
            <p>№37 (115),&nbsp; 22—28 сентября 1997 г.</p>
            

            
            <p>&nbsp; — Каково внутреннее состояние священника, во
                время службы находящегося с прихожанами, а не на своем обычном месте?</p>
            

            
            <p>&nbsp; — Конечно, когда священник не выполняет того
                служения, к которому призван Богом, он испытывает печаль. И это требует
                каких-то действий. Может быть, покаянных, а может быть, по налаживанию
                разорванных&nbsp;&nbsp;&nbsp;  связей любви и доверия.
                Мне кажется, что главная наша проблема сегодня заключается именно в&nbsp; этом. </p>
            

            
            <p>&nbsp; Только что одна наша давняя прихожанка
                рассказала мне со слезами, как она пришла в Сретенский монастырь. Мы много лет
                там служили, возрождали главный храм. Так вот, первое, что сказал ей священник:
                ”Не ходи к отцу Георгию Кочеткову”. Она говорит, мол, как же, я люблю его — и
                расплакалась. А он ей ответил: ”Тогда не ходи к нам”. Вот это пример самого
                трагического разрыва связей души. </p>
            

            
            <p>&nbsp; Да, отец Михаил, наш второй священник, был
                отправлен в психиатрическую больницу, пусть и на один день. Врачи поставили
                однозначный диагноз, говорящий о том, что другого выхода в тот день не было.
                Все это совершалось в рамках закона, максимально мягко, под наблюдением милиции
                и врачей. Но все равно нам от этого тяжело. Нельзя было доводить до такого
                обострения болезни священника, нельзя было его так настраивать на непослушание
                настоятелю, на кощунственные действия в алтаре, которые приравниваются к
                смертному греху. Его действительно спровоцировали. Мы знаем кто, я не буду
                называть этих имен.</p>
            

            
            <p>&nbsp; — Но ведь мы, наоборот, все время слышим о
                том, что это вы провоцировали здесь возмущения.</p>
            

            
            <p>&nbsp; — Я понимаю, откуда это идет. Всем известно,
                что уже с первых дней появления отца Михаила радиостанция “Радонеж” стала петь
                ему дифирамбы как очень образованному человеку, борцу за чистоту православия и
                т.д. Он возомнил себя духовным лидером, духовным воином, но ведь он
                священник...</p>
            

            
            <p>&nbsp; Мы надеемся на то, что Святейший Патриарх не
                допустит позора Церкви, неправды в Церкви. Для нас это вопрос принципиальный.
                Когда возникают такие острые противоречия, колеблется вера в Церковь. Не в
                Бога, а именно в Церковь. </p>
            

            
            <p>&nbsp; — Когда церковные иерархи принимали решения,
                думали ли они о будущем общины? Что произойдет с ней?</p>
            

            
            <p>&nbsp; — К сожалению, мы часто сталкиваемся с
                идеологическим и политическим подходом при определении отношения к тому или иному
                событию. Вот и члены комиссии по выяснению обстоятельств инцидента открыто
                говорят, что это была просто провокация со стороны отца Михаила. Но тем не
                менее считают, что с нашей общиной нужно было бороться, а для этого все
                средства хороши. </p>
            

            
            <p>&nbsp; Но давайте посмотрим по плодам, по духу и
                смыслу происходящего в нашей Церкви: что лучше, а что хуже. Да, можно иногда
                оставить 99 овец, а пойти за одной погибающей, но нельзя ради гонора одной
                бросить всех. Это не евангельский случай. Нельзя относиться к людям с
                пренебрежением, тем более выбросить за борт целую общину. Такие вещи делать, на
                мой взгляд, недопустимо. </p>
            

            
            <p>&nbsp; — Несмотря на гонения, вам удалось в
                назначенные сроки провести собор. Расскажите об этом.</p>
            

            
            <p>&nbsp; — Уже восемь лет мы ежегодно проводим
                собрание Преображенского и Сретенского братств. Буквально за неделю
                организовали очередной собор, на котором было принято два обращения к
                Святейшему Патриарху. Первое касалось проблем церковной жизни. Мы говорили
                только от своего имени, но верим, что все вопросы, которые там поставлены, —
                касаются ли они миссии, катехизации, приходской жизни, богослужения,
                образования, детской и издательской работы, — глубоко волнуют нашу иерархию.
                Это проблемы, которые срочно нужно решить, иначе мы потеряем возможность
                привлечения к Богу людей. Люди хотят найти в Боге источник любви и свободы.
                Слишком часто злоупотребляли этими понятиями в нашей стране, и многие стремятся
                обрести истину. Не будем же ставить препятствия на их пути. Этому посвящено
                первое обращение.&nbsp; Второе связано с положением
                нашего прихода. Мы говорим о том, что не чувствуем себя единственно правыми, но
                нас заставляют каяться в том, чего мы не совершали. Не должно быть так, что к
                людям относятся как к врагам Церкви только потому, что о них сказали неправду.</p>
            

            
            <p>&nbsp; — А правду узнать они не хотят?</p>
            

            
            <p>&nbsp; — К сожалению, возможностей не так много. Вот
                одной из них станет, может быть, ваша публикация. </p>
            

            
            <p>&nbsp; — Дай Бог.</p>
            

            
            <p>ОБРАЩЕНИЯ ЧЛЕНОВ
                И ДРУЗЕЙ </p>
            

            
            <p>СРЕТЕНСКОГО И
                ПРЕОБРАЖЕНСКОГО БРАТСТВ</p>
            

            
            <p>“Сретенский
                листок”&nbsp; №10(64), август 1997 г.</p>
            

            
            <p>“Русская мысль”
                №4188, 11—17 сентября 1997 г.</p>
            

            
            <p>Internet
                www.chat.ru/~sretenie</p>
            

            
            <p>&nbsp; Его Святейшеству, </p>
            

            
            <p>&nbsp; Святейшему Патриарху Московскому </p>
            

            
            <p>&nbsp; и всея Руси АЛЕКСИЮ II </p>
            

            
            <p>&nbsp; членов православного </p>
            

            
            <p>&nbsp; просветительско-благотворительного </p>
            

            
            <p>&nbsp; братства “Сретение”</p>
            

            
            <p>&nbsp; ПРОШЕНИЕ</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыка,
                благословите!</p>
            

            
            <p>&nbsp; Почтительнейше препровождаем Вам два
                Обращения на Ваше имя, составленные и подписанные в дни празднования
                Преображения Господня членами и друзьями братства “Сретение” из 16 епархий Русской
                Православной Церкви из шести стран.</p>
            

            
            <p>&nbsp; Первое Обращение содержит просьбу к Вам, Ваше
                Святейшество, как первоиерарху нашей Русской Православной Церкви, в связи с
                назревшими у нас в братстве и требующими скорейшего церковного разрешения
                проблемами. Подписавшие (в количестве 1053 чел.) глубоко верят и надеются, что
                выразили желание и многих других людей, которые в дальнейшем могли бы сыновне и
                почтительно подтвердить это Вашему Святейшеству.</p>
            

            
            <p>&nbsp; Второе Обращение касается ситуации,
                сложившейся, к сожалению, вокруг храма Успения Пресвятой Богородицы в
                Печатниках. Его подписали 1036 чел.</p>
            

            
            <p>&nbsp; Смиренно просим Вас обратить Ваше
                Первосвятительское внимание на эти единогласно одобренные тексты. Мы же со
                своей стороны готовы принять то решение, которое пред Лицом Господа Вам
                представится наилучшим.</p>
            

            
            <p>&nbsp; По поручению членов и друзей Братства, </p>
            

            
            <p>&nbsp; Вашего Святейшества недостойные послушники </p>
            

            
            <p>&nbsp; и усердные богомольцы,</p>
            

            
            <p>&nbsp; Председатель братства “Сретение” С. С.
                Тюльпин</p>
            

            
            <p>&nbsp; Секретарь Д.С.Гасак</p>
            

            
            <p>&nbsp; Его Святейшеству, </p>
            

            
            <p>&nbsp;&nbsp;Святейшему Патриарху Московскому </p>
            

            
            <p>&nbsp; и всея Руси АЛЕКСИЮ II&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
            

            
            <p>&nbsp; ОБРАЩЕНИЕ</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыка!</p>
            

            
            <p>&nbsp; К Вам, предстоятелю Русской Православной
                Церкви, смиренно обращаются православные христиане из разных приходов,
                находящихся в Москве, а также в других городах нашей страны и за границей.</p>
            

            
            <p>&nbsp; Все мы чада нашей Матери — Русской
                Православной Церкви, ее радости — наши радости, ее&nbsp; беды — наши беды. Сильнейшая боль охватывает
                наши сердца из-за того, что не находят удовлетворения жизненно важные для нас
                духовные потребности, не разрешаются существенные церковные проблемы.</p>
            

            
            <p>&nbsp; Радостно видеть прекращение внешних гонений,
                открытие многочисленных новых храмов, монастырей, духовных школ и издательств.
                Однако огромное число людей, ищущих Бога, остается вне Православной церкви или,
                едва придя в нее, уходит в секты или “в никуда”. Духовное и
                культурно-историческое сокровище Православия остается востребованным в нашей
                стране лишь в очень малой части.</p>
            

            
            <p>&nbsp; Ваше Святейшество! Господь даровал нашей
                Церкви великий исторический шанс — после многих лет “вавилонского пленения”
                приобщить к Православию наших сограждан, обретающих веру в Бога. Мы осознаем,
                что церковное возрождение является многотрудным делом и требует самого
                бережного отношения к церковному организму. Тем более необходимо обсуждение
                существующих церковных проблем и опыта их практического решения.</p>
            

            
            <p>&nbsp; Благословите изложить и смиренно предложить
                Вашему вниманию следующее:</p>
            

            
            <p>&nbsp; 1. Нам думается, что крайне важно устранить
                препятствия для деятельности приходов, стремящихся возродить почти
                отсутствующую сегодня православную миссионерскую деятельность. Ее нельзя
                подменять духовно бесплодной “контр-миссией”, основанной, главным образом, на
                обвинениях, а не на свидетельстве Истины. В нормальных случаях с этим связана
                достаточно длительная, полная и последовательная катехизация взрослых, в
                соответствии с каноническими требованиями, перед крещением или для крещеных в
                детстве и не прошедших научения.</p>
            

            
            <p>&nbsp; 2. Для более полного участия верных в
                богослужении и понимания ими смысла богослужебных текстов целесообразно, на наш
                взгляд, разрешить их русификацию в тех приходах, где об этом просят прихожане,
                — в полном соответствии с решением Поместного Собора от 9/22 сентября 1918 г.
                Мы полагаем также, что в таких приходах целесообразно разрешить и произнесение
                вслух священнических молитв, чтение Священного Писания по-русски (или
                по-церковнославянски и потом по-русски) лицом к народу, с проповедью после
                него, совершение богослужений суточного круга в соответствующее им время (вечерни
                — вечером, утрени — утром и т. д.). Мы убеждены также, что ни одно таинство не
                может восприниматься как частная треба, и что они должны совершаться бесплатно.
                По нашему мнению, заслуживает распространения опыт крещальных и венчальных
                литургий, служения литургии св. апостола Иакова, возрождения древнейшего
                литургического обряда “целования мира” перед анафорой, других форм участия
                всего народа в храмовом богослужении, в частности, общего церковного пения.</p>
            

            
            <p>&nbsp; 3. Очень желательно снять препятствия для
                регулярного причащения, которое, как правило, могло бы быть еженедельным. В
                этом случае тяжким бременем, на наш взгляд, становится требование частной
                исповеди и дополнительного поста перед каждым причастием, обусловленное
                бытовавшей когда-то прискорбной практикой эпизодического причащения (один или
                несколько раз в год).</p>
            

            
            <p>&nbsp; 4. Крайне необходимо, мы считаем, и
                возрождение изначально присущей Церкви общинности с учетом опыта, накопленного
                в русском и мировом Православии в ХХ веке, в частности, инициативы домашних встреч
                мирян для совместной молитвы, чтения и изучения Священного Писания.</p>
            

            
            <p>&nbsp; 5. Ждет своего разрешения вопрос возрождения
                принципов местной соборности, в связи с чем только и возможно говорить о выборе
                на приходах богослужебного языка и выборности священников и диаконов, а также
                вопрос обеспечения реальной возможности прямых отношений прихожан и их
                епископов.</p>
            

            
            <p>&nbsp; 6. Мы крайне обеспокоены продажей во многих
                храмах книг и брошюр, проникнутых духом сектантства, филетизма и
                фундаментализма, фактически призывающих к расколу (например, “Православие или
                смерть”, “Сети обновленного православия”, “Современное обновленчество —
                протестантизм восточного обряда”, “Антихрист в Москве” и т.п.). Огромный вред
                авторитету нашей Церкви наносят также, на наш взгляд, и передачи выступающих от
                ее имени и злоупотребляющих своей монополией в эфире радиостанции “Радонеж”,
                телепрограммы “Русский дом”, публикации газет “Русь державная”, “Русский
                вестник” и “Радонеж”.</p>
            

            
            <p>&nbsp; 7. Мы — за то, чтобы не прекращать братский
                диалог с представителями других христианских конфессий без искусственного
                затушевывания наших различий, но против попыток политизации Православия,
                ограничения его национальными рамками и использования в качестве
                государственной идеологии, в частности, навязывания монархии как единственно
                возможной формы общественного устройства, неправомерно отождествляемой с
                православной традицией.</p>
            

            
            <p>&nbsp; Если для кого-то из наших братьев и сестер во
                Христе перечисленные проблемы неактуальны, или они не считают необходимым их
                быстрое решение, мы никак не навязываем им свое мнение и готовы к его
                обсуждению в духе открытости и христианской любви.</p>
            

            
            <p>&nbsp; Все изложенное в этом письме основано на
                личном опыте, который, безусловно, не исчерпывает возможные пути решения
                выявившихся проблем. Мы, может быть, самые малые и недостойные, но все же
                реальные и живые члены Церкви. Мы стремимся жить полной церковной жизнью и со
                всеми проблемами, о которых было сказано выше, сталкиваемся уже сейчас. Поэтому
                мы смиренно просим Вас поддержать всех тех, кто на основе Священного Писания и
                Предания Церкви стремится к практической реализации ее многообразного опыта,
                омытого в последние десятилетия кровью новомучеников и исповедников российских,
                и подкрепить их действия Вашим первосвятительским советом, молитвой и
                благословением.</p>
            

            
            <p>&nbsp; Вашего Святейшества недостойные послушники</p>
            

            
            <p>&nbsp; и богомольцы&nbsp;
                — (прилагается 1053 подписи)</p>
            

            
            <p>&nbsp; Его Святейшеству,</p>
            

            
            <p>&nbsp; Святейшему Патриарху Московскому </p>
            

            
            <p>&nbsp; и всея Руси АЛЕКСИЮ II</p>
            

            
            <p>&nbsp; прихожан храма Успения </p>
            

            
            <p>&nbsp; Пресвятой Богородицы </p>
            

            
            <p>&nbsp; в Печатниках в Москве</p>
            

            
            <p>&nbsp; ОБРАЩЕНИЕ</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыка,
                благословите!</p>
            

            
            <p>&nbsp; Обращаемся к Вам в связи с разбирательством
                инцидента, происшедшего 29 июня с.г. в храме Успения Пресвятой Богородицы в
                Печатниках. Мы просим Вас, Ваше Святейшество, как своего архипастыря, о Вашем
                личном участии в этом деле для установления правды. Иначе может, хоть и на
                время, восторжествовать неправда на основе человеческих пристрастий.</p>
            

            
            <p>&nbsp; Уже в день инцидента, ни в чем не
                разобравшись или и не желая этого, на скорую руку выступила радиостанция
                “Радонеж”, позже — газета “Радонеж” и телепрограмма “Русский дом”. Сложная,
                драматическая ситуация была подана ими массовому слушателю и зрителю
                односторонне и со страшными искажениями, провоцирующими буквально натравливание
                на наш приход других православных верующих (не говорим уже о соблазне для
                неверующих). Однако в соблазне обвиняют нас, — как будто не было 2-х месяцев
                непрерывных провокаций и унижений прихожан и нашего настоятеля о. Георгия
                Кочеткова со стороны свящ. Михаила Дубовицкого, руководимого людьми, которые и
                стали нас потом обвинять (например, иг. Тихон (Шевкунов)). </p>
            

            
            <p>&nbsp; Мы надеялись найти защиту у благочинного о.
                Олега Клемышева и архиепископа Арсения, к которым мы многократно обращались, но
                их действия (как и бездействие) лишь усугубляли сложившееся положение.
                Естественно, что создавшаяся безблагодатная ситуация не могла исчезнуть сама по
                себе. О. Георгий и мы делали все, что могли, для нахождения мирного выхода из
                создавшегося положения. Однако сейчас названные выше СМИ и выступающие в них, в
                том числе священники, усиленно формируют представление об общине храма Успения
                и о. Георгии как о злодеях, полностью создавших этот конфликт. Нам вменяют в
                вину чудовищные преступления: избиение священника в алтаре, введение ему там же
                (!) психотропных препаратов, наконец, заранее подготовленную насильственную
                госпитализацию. Для их обоснования используются явно фальсифицированные
                документы, причем полностью игнорируются свидетельства непосредственных
                очевидцев происходившего, в т.ч. врачей-специалистов и представителей отделения
                милиции.</p>
            

            
            <p>&nbsp; К сожалению, члены комиссии по расследованию
                этих событий при опросе членов Приходского совета нашего храма и прихожан не
                стараются выяснить истину, а исходят из “презумпции виновности”. Они настойчиво
                пытаются заставить каждого признать его “вину”, несмотря на отсутствие
                доказательств и готовность свидетельствовать перед Крестом и Евангелием.&nbsp; Копии личных письменных свидетельств,
                направленных на Ваше имя, игнорируются ими. Применяются давление, метод “перекрестного
                допроса”, бездоказательные обвинения во лжи. Иногда задавались прямо
                провокационные вопросы.</p>
            

            
            <p>&nbsp; Ваше Святейшество! Не дайте совершиться
                беззаконию! Мы совсем не считаем себя “не имеющими нужды в покаянии” и скорбим
                о происшедшем. Но нас заставляют каяться в том, чего мы не совершали. То, что
                происходит сейчас, больше похоже не на церковное расследование, а на суд с
                заранее заготовленным приговором. При этом обвинители и судьи выступают в одном
                лице.</p>
            

            
            <p>&nbsp; Мы всей своей христианской совестью свидетельствуем
                Вам, нашему предстоятелю пред Господом, что никто из наших братьев-алтарников,
                ни тем более о. Георгий, не наносили оскорблений и побоев свящ. Михаилу, а его
                госпитализация производилась вынужденно, в связи с его нездоровьем и
                неадекватным поведением, по однозначному и независимому настоянию врача и
                милиции. Обоснованность госпитализации была подтверждена врачебно-контрольной
                комиссией.&nbsp; </p>
            

            
            <p>&nbsp; Ваше Святейшество! Мы свидетельствуем, что
                все события, начавшиеся не 29 июня, а много раньше, могли произойти лишь
                потому, что служение о. Георгия Богу и Церкви на протяжении ряда лет вызывало
                нехристианские по духу и формам нападки отдельных людей и околоцерковных
                средств массовой информации. И сейчас для очернения его и его деятельности
                используются любые, в т.ч. самые негодные средства, — лишь бы добиться его
                отстранения и разрушения созданного им с помощью Божией прихода, имеющего столь
                необходимый Церкви миссионерско-общинный характер. В прессу и на телевидение
                беззаконно были переданы не подлежащие разглашению видеозаписи происшедшего
                (причем выборочно и тенденциозно), представленные нами следственным органам и в
                Патриархию, а также копии документов из Вашей канцелярии — письмо из Минздрава
                и т.д.</p>
            

            
            <p>&nbsp; Нельзя не заметить, что представленная Вам
                информация, вызвавшая снятие о. Георгия с должности настоятеля и запрещение в
                служении, была подготовлена крайне&nbsp;
                тенденциозно, с искажением фактов, без разговора с ним, без объективного
                расследования. Это вызвало и вызывает у многих людей сильное смущение и
                соблазн. Неужели Бог не в правде, а во внешней силе?</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыка!
                Приносим Вам нашу надежду на то, что истина будет восстановлена Вами. Со своей
                стороны мы, как и прежде, готовы искренне рассказать Вам и Церкви обо всем, что
                было и есть в приходской общине храма Успения. Но уже сейчас со слезами
                смиренно просим — не лишайте нас нашего духовного пастыря, который привел нас к
                Богу и в Церковь. Восстановите о. Георгия, претерпевающего несправедливое
                гонение, в священнослужении и настоятельстве в открытом и возрожденном под его
                руководством храме, поддержите его в плодотворном и благодатном служении во
                славу Божию и Святой Православной Церкви. Помогите оклеветанным членам
                Приходского совета и алтарникам. Пусть все “кривизны выпрямятся”, чтобы мир в
                Церкви укрепился, чтобы внутренняя и внешняя жизнь ее не была омрачена ложью и
                неправедным судом!</p>
            

            
            <p>&nbsp; Ваши недостойные,</p>
            

            
            <p>&nbsp; верные и искренние послушники и
                богомольцы&nbsp; — </p>
            

            
            <p>&nbsp; (прилагается 1036&nbsp; подписей)</p>
            

            
            <p>&nbsp;&nbsp; УКАЗ</p>
            

            
            <p>&nbsp;&nbsp; Священнику Георгию Серафимовичу Кочеткову,</p>
            

            
            <p>&nbsp;&nbsp; временно запрещенному в священнослужении, </p>
            

            
            <p>&nbsp;&nbsp; клирику г. Москвы.</p>
            

            
            <p>&nbsp;&nbsp; Протоиерею Олегу Федоровичу Клемышеву,</p>
            

            
            <p>&nbsp;&nbsp; благочинному храмов Сретенского округа, </p>
            

            
            <p>&nbsp;&nbsp; и.о. настоятеля храма Успения Пресвятой </p>
            

            
            <p>&nbsp;&nbsp; Богородицы в Печатниках г. Москвы.</p>
            

            
            <p>&nbsp;&nbsp; Приходскому собранию того же храма</p>
            

            
            <p>&nbsp;&nbsp; Священнику Михаилу Владимировичу
                Дубовицкому,</p>
            

            
            <p>&nbsp;&nbsp; клирику того же храма</p>
            

            
            <p>&nbsp;&nbsp; Копия: </p>
            

            
            <p>&nbsp;&nbsp; Духовнику г. Москвы протоиерею Владимиру
                Жаворонкову</p>
            

            
            <p>&nbsp;&nbsp; Настоятелю и Приходскому совету храма иконы
                Божией </p>
            

            
            <p>&nbsp;&nbsp; Матери “Живоносный Источник” в Царицыне г.
                Москвы</p>
            

            
            <p>&nbsp;&nbsp; В связи с завершением работы
                назначенной&nbsp;&nbsp;&nbsp; Нами&nbsp;&nbsp; комиссии по расследованию инцидента,
                происшедшего при совершении богослужения в храме Успения Пресвятой Богородицы в
                Печатниках 29 июня 1997 г. и установлением данной комиссией факта совершения
                насилия и глумления над священником Михаилом Дубовицким, а также уяснением
                причин тому, выражавшихся в систематическом проявлении самочиния со стороны
                священника Георгия Кочеткова как в богослужебной, так и в иной деятельности
                возглавляемого им прихода, что и послужило нарушению церковного мира,
                возникновению конфликтной ситуации, а также соблазну не только для людей
                православных, но и для ищущих дорогу к храму, — считаем целесообразным:</p>
            

            
            <p>&nbsp;&nbsp; 1. Священника Георгия Кочеткова, бывшего
                и.о. настоятеля храма, ответственного за совершенное насилие и других
                вышеуказанных деяний и согласно 9 правилу Двухкратного Собора, 18 правилу
                Халкидонского Собора, 34 правилу Трульского Собора и др., подлежащего
                извержению из священного сана, до настоящего времени не осознавшего своей вины
                и не принесшего покаяния, — оставить в состоянии запрещения в священнослужении
                до принесения им раскаяния, руководствуясь при этом принципом церковной
                икономии, а не акривии.</p>
            

            
            <p>&nbsp;&nbsp; Вопрос о возможности его дальнейшего
                священнослужения может быть рассмотрен Нами только по осознании им своей вины и
                принесении покаяния.</p>
            

            
            <p>&nbsp;&nbsp; 2. Лиц, совершивших насилие и
                надругательство над священником Михаилом Дубовицким, Матвеева С.И.,
                Копировского А.М., Байкова А.М., Чуева Г.А., Якунцева Г.И., Гасака Д.С.,
                уклоняющихся от принесения покаяния и отрицающих свою виновность, согласно&nbsp; 56 Апостольскому правилу, отлучить от общения
                церковного.</p>
            

            
            <p>&nbsp;&nbsp; Суждение о снятии данного прещения может
                быть принято Нами только по принесении ими покаяния в содеянном Епархиальному
                духовнику г.Москвы.</p>
            

            
            <p>&nbsp;&nbsp; 3. Лицам, принимавшим непосредственное
                участие в глумлении, оскорбительных действиях и высказываниях против священника
                Михаила Дубовицкого, Романову О.В., Котту В.К., Максимовскому В.Е.,
                Соколинскому Р.И., Кулыгину В.М., Василевской А.Д., не осознавшим своей вины и
                не принесшим покаяния в содеянном, воспретить участие в церковно-богослужебной
                жизни (чтении, пении, прислуживании в алтаре, членстве в Приходском собрании и
                пр.), а также согласно 56 Апостольскому правилу, до принесения покаяния
                Епархиальному&nbsp; духовнику г.Москвы, и в
                принятии Святых Христовых Тайн.</p>
            

            
            <p>&nbsp;&nbsp; 4. Регента хора вышеуказанного храма
                Виноградову А.Е., не подчинявшуюся указаниям служащего священника и, тем
                самым,&nbsp;&nbsp; создавшую&nbsp;&nbsp; напряжение в приходе, предупредить, что, в
                случае повторения подобного, ей будет запрещено участие в совершении
                богослужений в любом из храмов г. Москвы.</p>
            

            
            <p>&nbsp;&nbsp;&nbsp; 5. Священника Михаила Дубовицкого, клирика
                данного храма, допустившего некоторое самочиние при нетрадиционном отправлении
                богослужений в означенном храме, освободить от несомого послушания&nbsp; и назначить штатным священником храма
                “Живоносный Источник” иконы Божией Матери в Царицыне г. Москвы под руководство
                опытного духовника-настоятеля.</p>
            

            
            <p>&nbsp;&nbsp; 6. Протоиерея Олега Клемышева, временно и.о.
                настоятеля Успенского храма в Печатниках, утвердить в должности настоятеля
                данного храма с одновременным назначением председателем Приходского совета, а
                также поручением попечения о духовном окормлении приходских учебных заведений и
                братств (вместо священника Г.Кочеткова).</p>
            

            
            <p>&nbsp;&nbsp; 7. При проведении заседания Приходского
                собрания, которое следует провести под председательством Нашего викария,
                настоятелю храма надлежит озаботиться о внесении изменений в состав Приходского
                собрания, исключив из него лиц, отлученных от церковного общения и получивших
                иные прещения, а также переизбрать руководящие органы прихода, виновные в
                развитии возникшей в храме конфликтной ситуации, недопустимой для православного
                храма.</p>
            

            
            <p>&nbsp;&nbsp; Алексий</p>
            

            
            <p>&nbsp;&nbsp; Патриарх Московский и всея Руси</p>
            

            
            <p>&nbsp;&nbsp; 9 октября 1997 года</p>
            

            
            <p>&nbsp;&nbsp; № 4520</p>
            

            
            <p>Пресс-служба
                братства «Сретение»</p>
            

            
            <p>НЕОБОСНОВАННОЕ
                НАКАЗАНИЕ </p>
            

            
            <p>ОТЦА ГЕОРГИЯ
                КОЧЕТКОВА И ЕГО ПРИХОЖАН. </p>
            

            
            <p>ГОНЕНИЯ НА ОБЩИНУ
                ПРОДОЛЖАЮТСЯ</p>
            

            
            <p>“Церковно-общественный
                вестник” №25, 16.10.97 г. </p>
            

            
            <p>(приложение к
                газете “Русская мысль”, 16—22 октября 1997 г.). Здесь печатается по варианту,
                распространяемому через Interhet&nbsp;
                (www.chat.ru/~sretenie)</p>
            

            
            <p>&nbsp; 13 октября священнику Георгию Кочеткову был
                вручен Указ, подписанный Патриархом Московским и всея Руси Алексием II, о
                запрещении отца Георгия в священнослужении и отлучении от причастия 12 прихожан
                храма Успения Пресвятой Богородицы в Печатниках. Это было сделано по
                представлению комиссии, расследовавшей инцидент в храме Успения 29 июня с.г. </p>
            

            
            <p>&nbsp; В тот день второй священник храма Михаил
                Дубовицкий был&nbsp; госпитализирован скорой
                психиатрической помощью в присутствии сотрудников милиции. Это печальное
                событие было использовано для клеветнической кампании, направленной на
                дискредитацию и уничтожение миссионерской, просветительской, благотворительной
                деятельности отца Георгия Кочеткова, его общины и братства «Сретение». В Указе
                отцу Георгию и его прихожанам было инкриминировано «насилие и глумление над
                священником».</p>
            

            
            <p>&nbsp; К сожалению, решение о прещениях было принято
                без какого-либо разговора Патриарха с о. Георгием или кем-либо из членов
                приходской общины или Братства, хотя просьбы о встрече со своим епископом в
                Патриархию отправлялись. Указ Патриарха вышел буквально накануне получения
                результатов исследования того же инцидента представительной комиссией
                Министерства здравоохранения РФ. Из достоверных источников стало известно, что
                эта комиссия пришла к заключению об обоснованности госпитализации свящ. Михаила
                Дубовицкого.</p>
            

            
            <p>&nbsp; Подобное заключение принято и прокуратурой,
                которая, несмотря на давление, так и не открыла уголовное дело против отца
                Георгия.</p>
            

            
            <p>&nbsp; В этот же день во избежание нарушения
                церковного мира и в соответствии с требованием Указа Патриарха о. Георгий и
                названные в Указе миряне прошли исповедь у епархиального духовника.</p>
            

            
            <p>Борис Колымагин</p>
            

            
            <p>ОБЩИНА О. ГЕОРГИЯ
                КОЧЕТКОВА ИЗГОНЯЕТСЯ </p>
            

            
            <p>ИЗ ХРАМА, В ТО
                ВРЕМЯ КАК ОФИЦИАЛЬНОЕ РАССЛЕДОВАНИЕ ЕЕ ПОЛНОСТЬЮ ОПРАВДАЛО</p>
            

            
            <p>“Русская мысль” №
                4196, 6—12 ноября 1997 г., </p>
            

            
            <p>“Православная
                община” №42</p>
            

            
            <p>&nbsp; Как уже сообщалось, согласно Указу Святейшего
                Патриарха Алексия II, подписанному 9 октября (см. «Церковно-общественный
                вестник», 16 октября), свящ. Георгий Кочетков остается под запретом в
                священнослужении «до принесения им раскаяния», а 12 самых видных членов его
                общины отлучаются от причастия до «принесения покаяния в содеянном». Такое
                решение, как говорится в Указе, было принято «в связи с завершением работы...
                комиссии по расследованию инцидента, происшедшего при совершении богослужения в
                храме Успения Пресвятой Богородицы в Печатниках 29 июня 1997 г.&nbsp; и установлением данной комиссией факта
                совершения насилия и глумления над священником Михаилом Дубовицким, а также
                уяснением причин тому, выражавшихся в систематическом проявлении самочиния со
                стороны священника Георгия Кочеткова как в богослужебной, так и иной
                деятельности возглавляемого им прихода, что и послужило нарушению церковного
                мира, возникновению конфликтной ситуации, а также соблазну не только для людей
                православных, но и для ищущих дорогу к храму».</p>
            

            
            <p>&nbsp; Данное решение епископ Москвы Святейший Патриарх
                принял ни разу лично не связавшись и не выслушав ни о. Георгия, ни кого бы то
                ни было из общины Успенского храма. Более того, Указ был подписан им чуть ли не
                накануне обнародования документов с данными объективного расследования
                происшедшего 29 июня, из милиции, прокуратуры и Министерства здравоохранения РФ
                и, следовательно, их не учитывал.</p>
            

            
            <p>&nbsp; Однако уже 8 октября 18 отделение милиции г.
                Москвы приняло постановление об ОТКАЗЕ в возбуждении уголовного дела по факту
                нарушения общественного порядка в Успенском храме 29 июня с.г. То есть,
                наконец-то официально&nbsp; и окончательно
                было установлено, что в храме в тот день никто о. Михаила Дубовицкого не бил и
                насилия к нему не применял (вынужденная принудительная госпитализация не может
                быть квалифицирована как насилие ни юридически, ни нравственно). Расследование
                в данном отделении милиции велось по заявлению жены о. Михаила, утверждавшей,
                что будто бы «29 июня он был подвергнут насильственным действиям и направлен в
                психиатрическую больницу ... безо всяких оснований». Таким образом, 18
                отделение милиции дезавуировало письмо своего же начальника ст. лейтенанта А.
                Л. Римского от 1-го июля, написанное по запросу архиепископа Арсения,
                патриаршего викария, в котором говорилось, что якобы сотрудниками милиции была обнаружена
                «драка священников» в алтаре храма и что подобное происходило не в первый раз
                (?!). К сожалению, именно этот документ был использован как основание для Указа
                Святейшего Патриарха от 2-го июля о запрете свящ. Георгия Кочеткова в
                священнослужении «за оскорбительные действия, унижавшие достоинство ...
                священника Михаила Дубовицкого и приведшие к физической расправе над ним в
                алтаре означенного храма» (см. «Независимая газета», 9 августа 1997 г., Д.
                Горин. «Кто заказывает “избиения” священников?»).</p>
            

            
            <p>&nbsp; Кроме того, 14 октября комиссия, созданная по
                указанию зам. министра здравоохранения Российской Федерации, рассмотрев
                материалы, связанные с обоснованностью госпитализации Дубовицкого М. В. (т. е.
                о. Михаила), установила, что «психическое состояние М. В. Дубовицкого
                характеризовалось нарушением поведения с элементами неадекватности
                реагирования, включающими невозможность установления речевого контакта,
                сочетавшимися с повторяющимися выкриками о помощи в связи с якобы
                существовавшей угрозой для его жизни». Комиссия пришла к заключению, что «в
                момент стационирования М. В. Дубовицкий находился в остром реактивном
                состоянии, которое соответствует острой реакции на стресс с преобладанием
                нарушения поведения... В создавшихся условиях тактика врача Г. Л. Шафрана —
                решение вывести М. В. Дубовицкого из не поддающейся разрешению тяжелой
                психотравмирующей ситуации — БЫЛА ОПРАВДАННОЙ, ибо в противном случае действия
                врача следовало бы расценить как неоказание медицинской помощи». </p>
            

            
            <p>&nbsp; Наконец, 20-го октября вышло постановление
                местной прокуратуры об ОТКАЗЕ в возбуждении уголовного дела о незаконной
                госпитализации о. Михаила Дубовицкого в психиатрический стационар. В
                постановлении говорится, что «когда прибыла бригада скорой помощи в храм, их
                провели в помещение ризницы, в коридор, где врач увидел мужчину, лежащего на
                полу, зовущего на помощь и читающего псалмы. Все это происходило при том, что
                этого мужчину никто не трогал, никто не применял к нему силу. Врач Шафран
                установил признаки острого психического расстройства и принял решение, согласно
                закону РФ «О психиатрической помощи и гарантиях прав граждан при ее оказании»,
                о недобровольной госпитализации Дубовицкого, поскольку он был в данный момент
                социально опасен, его поведение могло привести к конфликту между прихожанами
                церкви. Шафран пояснил, что настоятель храма Кочетков был против насильственной
                госпитализации Дубовицкого.» </p>
            

            
            <p>&nbsp; Почему церковная комиссия, созданная для
                объективного расследования инцидента 29 июня, так спешила подготовить свое&nbsp; решение и, следовательно, решение Святейшего
                Патриарха до выхода окончательных объективных постановлений светских органов по
                вопросам этой печальной истории? Может быть, комиссии не было известно, что
                светские органы заканчивают свою работу и решения будут вскоре ими вынесены (в «спешке»
                комиссия под председательством епископа Алексия (Фролова), не опросила даже
                всех основных свидетелей происшедшего, хотя владыка пытался утверждать
                обратное)? Не очевидно ли, что и сейчас, когда решения светских расследований
                вынесены и объективно доказана фактическая&nbsp;
                и юридическая невиновность о. Георгия, алтарников и членов Церковного
                совета Успенской общины, до сих пор находящихся под прещениями, церковное
                начальство не намерено пересматривать свои вопиюще несправедливые решения?</p>
            

            
            <p>&nbsp; В то же время архиепископ Арсений, в
                нарушение Устава об управлении РПЦ&nbsp; и
                гражданского Устава РПЦ, своим распоряжением назначил комиссию для «проверки
                финансово-хозяйственной деятельности и передачи имущества», хотя согласно этим
                Уставам подобного рода комиссия избирается только Приходским собранием и из
                членов Приходского собрания.</p>
            

            
            <p>&nbsp; После вручения о. Георгию 13-го октября Указа
                Святейшего Патриарха двухтысячная община фактически целиком была изгнана
                из&nbsp; храма Успения в Печатниках вновь
                назначенным настоятелем и старостой прот. Олегом Клемышевым. Самым грубым и
                беззаконным образом были выгнаны с работы без предупреждения и выходного
                пособия все (!) сотрудники храма. Рвение о. Олега доходило до того, что он
                собственноручно вытолкнул из храма молодую девушку — штатного ночного дежурного
                — и привел на ее место «своего» человека. А незадолго до этого приказал ей идти
                и всю ночь дежурить на улице, ходя вокруг храма, что не предусматривалось
                условиями ее работы и потому не может рассматриваться иначе как беззаконие и
                издевательство над человеком. Сотруднице храма пришлось даже писать заявление в
                милицию, чтобы оградить себя от противоправных насильственных действий нового
                «ктитора» в сане.</p>
            

            
            <p>&nbsp; Здесь следует заметить, что насилие для о.
                Олега Клемышева — вещь обычная и даже, видимо, культивируемая им. На глазах у
                всей общины в конце воскресной литургии 5 октября с.г. о. благочинный в
                облачениях, прямо с амвона не постеснялся наотмашь ударить в лицо человека,
                попытавшегося начать видеосъемку разбушевавшегося и более похожего в тот момент
                на бесноватого настоятеля (тогда еще вр.и.о.). Поводом для выхода из себя о.
                благочинного послужило всего лишь народное пение уставного многолетия
                Патриарху, настоятелю и прихожанам в день рождения о. Георгия. Этот момент
                успела снять находившаяся в руках потерпевшего видеокамера. </p>
            

            
            <p>&nbsp; Не иначе как кощунством выглядит и тот факт,
                что о. Олег после своего утверждения в качестве настоятеля Успенского храма
                ввел в алтарь некоего В. Сергеева (алтарника прот. Александра Шаргунова),
                который 29 июня на глазах у всех, у сотен людей напал на одного из сотрудников
                храма Успения в Печатниках, разбил ему нос и пролил кровь в храме, стремясь во
                что бы то ни стало ворваться в алтарь после очередного истошного крика о.
                Михаила “о помощи”. К слову говоря, и милиция, которая сама выводила насильника
                из храма и у которой он был отбит людьми из Сретенского монастыря, и патриаршая
                комиссия по расследованию происходившего в тот день все об этом знали, но
                никаких выводов в адрес насильников, бывших в храме Успения в Печатниках и,
                увы, до сих пор в нем остающихся, не сделали.</p>
            

            
            <p>&nbsp; Можно сказать, что «идейные» противники о.
                Георгия, несмотря ни на какие свои «титанические» усилия,&nbsp; не достигли всех тех целей, к которым они
                стремятся: о. Георгий не лишен священнического сана, и его приходская община
                официально не распущена, как это было, например в 1994 г., когда общину о.
                Георгия выгнали из Владимирского собора Сретенского монастыря и «присоединили»
                к общине храма Успения в Печатниках. Стремясь наверстать упущенное, «оппоненты»
                о. Георгия теперь усиленно распространяют лживые слухи, будто бы о. Георгий в
                нарушение указа Святейшего Патриарха служит литургию дома, а кто-то из
                отлученных вместе с ним продолжает причащаться. Впрочем, один «успех»
                оппонентов очевиден: храм, который наполняли молодежь, дети и взрослые,
                христианская любовь и радость, ныне пуст. О. Георгий и другие отлученные братья
                смиренно несут епитимью, которая причиняет страдание всем членам общины,
                прекрасно знающим об их невиновности. Община ищет для себя временно другие
                храмы, где, как и в Печатниках прежде, на Евхаристии царят любовь и мир, а не
                озлобление и сектантский дух закрытости и замкнутости лишь на себя.</p>
            

            
            <p>&nbsp; Несмотря на тяжелейшие обстоятельства,
                возглавляемая свящ.Георгием Кочетковым Свято-Филаретовская высшая православная
                школа с 15 по 17 октября провела международную богословскую конференцию «Живое
                предание» (см. www glasnet.ru/~stphilaret), что говорит о решимости о. Георгия
                и других сторонников миссионерско-катехизационного и общинно-евхаристического движения
                преодолеть любые искушения и продолжить миссионерское и просветительское
                служение на благо Святой Церкви. </p>
            

            
            <p>С.Зайденберг</p>
            

            
            <p>ЖИВОЕ ПРЕДАНИЕ — </p>
            

            
            <p>ОТ ОРТОДОКСИИ К
                ОРТОПРАКСИИ</p>
            

            
            <p>Заметки о
                международной богословской конференции “Живое предание”. Конференция была
                организована Свято-Филаретовской московской высшей православно-христианской
                школой и проведена по благословению Святейшего Патриарха Московского и всея
                Руси Алексия II с 15 по 17 октября 1997 г. Статья опубликована&nbsp; в журнале “Православная община”&nbsp; № 42 </p>
            

            
            <p>&nbsp; Когда год назад обсуждали тему этой
                конференции и сформулировали ее начерно “фундаментализм и модернизм”, вряд ли
                кто-нибудь мог предполагать — насколько актуальной окажется эта тема на день
                открытия конференции.</p>
            

            
            <p>&nbsp; Действительно, большинство ее участников
                совсем недавно испытали на себе действие сил фундаментализма, некоторые —
                буквально накануне: так, игумен Вениамин Новик был изгнан из CПбДА примерно за
                месяц до открытия конференции, а доклад о. Георгия Кочеткова был написан на
                следующий день после вручения ему и двенадцати ближайшим его помощникам указа о
                запрещении в служении и отлучении от причастия... Этим определялся и характер
                конференции — не столько обсуждение тенденций современной церковной жизни, как
                это, видимо, предполагалось, сколько настойчивая попытка ответить на неотложный
                вопрос: что это было? Что это за сила, стремящаяся вытолкнуть за пределы церкви
                такое количество ее живых и деятельных членов, жаждущих служить возрождению
                церковной жизни в России? Почему она проникает в церковь и почему она приобрела
                такой размах и именно в эти дни? Тем более, что первый же докладчик сказал: не
                обольщайтесь, с этим нам придется жить еще долго.</p>
            

            
            <p>&nbsp; В свою очередь, возникал и другой вопрос: те,
                чьими руками, умами, голосами и пишущими машинками воспользовалась эта
                загадочная сила, — что они видели перед собой? Какой призрак их так напугал?
                Как они не заметили, что произошло с ними самими? Я помню свои впечатления о
                конференции “Единство церкви”, впечатление отколовшейся льдины, обитатели которой
                продолжают жить, даже не замечая, что их уносит.</p>
            

            
            <p>&nbsp; Итак, что такое фундаментализм и что такое
                модернизм? Откуда они взялись?<a name="r1" href="#1">[1]</a> </p>
            

            
            <p>&nbsp; С одной стороны очевидно, что в их основе
                лежат постоянные и естественные тенденции жизни церкви в этом мире. Слово “предание”
                есть отглагольное существительное, предание предполагает как сохранение
                передаваемого, так и какое-то изменение. (Поскольку сама по себе передача есть
                какое-то движение, а движение — всегда изменение. Помнится, несколько лет назад
                один оперный режиссер заметил, что хранение традиции есть передача традиции, а
                последняя похожа на передачу эстафеты. Советская же опера, добавлял он, похожа
                на человека, который, остановившись на обочине и вцепившись в эту эстафету,
                думает, что он ее таким образом “хранит”.) Поэтому в церкви всегда будут силы,
                которые, по крайней мере со стороны, воспринимаются как консервативные, будут и
                противоположные им. Однако, как неоднократно подчеркивалось выступавшими, в
                нормальном случае эти силы не только мирно уживаются друг с другом (как это
                было, например, в начале века в Петербургской епархии), но дополняют друг друга
                и плодотворно взаимодействуют.</p>
            

            
            <p>&nbsp; С другой стороны, фундаментализм и модернизм,
                как отмечалось на конференции, есть настоящие духовные болезни, также, к сожалению,
                всегда существующие в церкви. Говорилось даже, что именно в них — источник всех
                ересей, поскольку они “позволяют человеку чувствовать себя членом Церкви, не
                являясь таковым” (Д. Гзгзян). “Стойте и держите предания”, — увещевает апостол,
                но это “стояние и держание” не может быть пассивным, а только активным и
                творческим. Эти слова апостола перекликаются с другими: “Стойте в свободе”, —
                но ведь куда проще подменить веру набором правил или догматов, решив, что
                предание “это то, что есть на сегодняшний день”<a name="r2" href="#2">[2]</a>?, и избавив тем самым себя
                и других от этого опасного бремени свободы (фундаментализм). Или наоборот — под
                лозунгом “свободы” избавить себя от следования “узким путем”, боясь усилия и
                ответственности (т.е. настоящей свободы) и прикрываясь “достаточностью”
                заповедей о любви (модернизм). Последнее искушение звучит как: “если Ты Сын
                Божий, скажи, чтобы камни сии сделались хлебами” (Мф 4:3). Однако нельзя
                забывать, что помимо этого искушения — хлебом, есть и два других: чудом и
                властью. Последнее и нашло выражение в обновленчестве, где то, ради чего по
                видимости бралась власть — обновление церкви, оказалось пустым лозунгом,
                скрывающим поклонение голому идолу власти, кровавой и требующей все новых и
                новых жертв — своих же братьев по вере.</p>
            

            
            <p>&nbsp; Неизбежна в церкви и опасность “закваски
                фарисейской и саддукейской”, о чем предупреждал нас Сам Господь. Напомним, что
                фарисеи — это люди, отделившие себя от остального церковного народа,
                подменившие веру делами закона, заповедь Божию — заповедью человеческой,
                забывшие главное в самом законе — суд, милость и веру (или подменившие Суд
                Божий — своим собственным, человеческим и часто неправедным и жестоким),
                впавшие в гордыню от того, что они “не такие, как этот мытарь”. Саддукеи же —
                священники, побежденные злом и цинизмом, изверившиеся в спасении, отвергающие
                воскресение мертвых (а значит, опять-таки, — суд, милость и веру), презирающие
                народ с его преданием и благочестием (т. е. зачастую — собственных прихожан),
                иногда втайне насмехающиеся над его подчас наивной верой, ориентированные на
                другую культуру и готовые пойти на сговор с властью ради “церковного мира”, а
                на самом деле — собственного благополучия. Фарисеи и саддукеи — антагонисты и
                даже враги, но перед лицом Истины они неизбежно становятся союзниками.</p>
            

            
            <p>&nbsp; В этой перспективе фундаментализм и модернизм
                неизбежно сходятся и превращаются друг в друга, поскольку и то и другое есть
                стилизация — в одном случае невольная и подчас искренне не замечающая этого, в
                другом же — намеренная и сознательная.</p>
            

            
            <p>&nbsp; Обе эти тенденции могут быть описаны как
                сектантские. Что это значит? Сектантство — болезнь церкви, имеющая своей
                питательной средой секуляризм, который о. Александр Шмеман назвал настоящей
                ересью. Секуляризация есть расцерковление жизни, приводящее человека к
                внутреннему расколу, поскольку значительная часть его жизни оказывается вне
                Бога и Церкви. Человек же тоскует по целостности, он хочет (и это его право),
                чтобы его вера была кафолична, т. е. “имела отношение до всего” (kaq` ?lou), до
                всех сторон его бытия. Сектантство и рождается из этой жажды обретения
                потерянной кафоличности, но только оно есть искусственный, упрощенный и потому
                ложный ход в этом направлении. Здесь есть разные способы: можно сократить
                размеры того, что считается “всем” — и в этом случае часть нашего бытия
                относится в область несуществующего, объявляясь “бесовщиной”, ересью, и т. п.
                (в этом отношении марксизм — громадная тоталитарная секта, и потому методы
                фундаменталистов и методы большевиков, как было показано на конференции,
                типологически сходны (доклад о. Иоанна Привалова). Модернизм же выходит из
                положения, отвергая саму эту претензию человека на кафоличность, приспосабливая
                свою веру к разделенности мира сего. И в том и в другом случае отвергается то
                единственное, что только и способно соединять разъединенное — крест (а значит и
                свобода). Тут фундаментализм и модернизм снова становятся неотличимы. Например,
                отвержение перевода богослужения на русский язык — это фундаментализм или
                модернизм? С одной стороны, как приверженность букве, — вроде бы фундаментализм,
                но с другой — модернизм, поскольку принципиально настаивает на том, что часть
                человеческой жизни не нуждается в освящении Церковью и в церкви.</p>
            

            
            <p>&nbsp; Действительно, только в Ветхом Завете “эллины
                и иудеи” — противоположны, а с “точки зрения” благодати они две стороны одной
                медали, и возвращение к одному из двух этих типов религиозного сознания
                предполагает возвращение и к другому, часто в одном и том же человеке. </p>
            

            
            <p>&nbsp; Еще один корень и фундаментализма и
                модернизма — разрыв в преемственности и разрыв между духом и смыслом. И тут эти
                противоположности снова сходятся. Например, священник-фундаменталист любит
                православное богослужение и во избежание “человеческого мудрования”,
                “рационализма” и т. п. не пойдет ни на какое изменение “того, что получил”,
                поскольку “ключи” к богослужению потеряны, модернист же, наоборот — может быть
                и не любит традиционного богослужения, но и он тоже не будет ничего менять —
                зачем? Ведь это требует сил, а он в своей реальной пастырской работе “ставит”
                не на это.</p>
            

            
            <p>&nbsp; Поэтому почти шоком (надо надеяться —
                отрезвляющим) для многих неподготовленных людей было выступление английского
                журналиста и историка церкви Майкла Уолша, рассказавшего, как некоторые
                западные религиозные движения, традиционно считающиеся у нас либеральными, в
                определенных условиях превращаются в фундаменталистские и политизированные, что
                является еще одним свидетельством тому, что, консервативные или либеральные,
                искусственные fundamentals приводят к одному и тому же результату. (Впрочем, и
                у нас в России уже можно наблюдать сходные процессы: так, например, случилось
                со многими людьми из круга Свято-Тихоновского института, да и с многими другими
                диссидентами.) В этом отношении был интересен доклад Евг. Кадосова, показавший,
                что выражение о. Георгия Флоровского “Протестантизм восточного обряда”,
                употребляющееся сейчас некоторыми фундаменталистами в отношении так называемых
                “неообновленцев”, может быть с полным основанием применено к ним самим.</p>
            

            
            <p>&nbsp; Можно рассматривать проблему и со стороны
                психологической и философской. Психологи различают основные “типы”
                религиозности, а философы отмечают различное отношение разных типов людей к
                себе и миру,&nbsp; и, поскольку во всех
                обществах всегда найдутся представители этих типов, нежелание считаться с
                естественными факторами человеческого существования, попытка подстричь всех под
                одну гребенку неизбежно приводит к опасным искажениям и даже извращениям
                церковной жизни (доклад игумена Вениамина (Новика)).</p>
            

            
            <p>&nbsp; Все это так. Но вот в чем трудность: все эти
                тенденции и проблемы были в церкви всегда, с самых ранних времен ее
                существования, но не всегда это приводило к такому кризису. В чем же дело?</p>
            

            
            <p>&nbsp; Конечно, церковь состоит из людей, и наследие
                большевистского рабства дает о себе знать, — об этом в 1937 г. предупреждала
                мать Мария (Скобцова). С другой стороны, сказывается семидесятилетний разрыв в
                предании, и потому возрождение церковной жизни не может пройти без проблем:
                церковь отражает процессы, происходящие в обществе. К тому же после падения
                коммунистического режима государство хочет от церкви восполнения недостатка в
                государственной идеологии, и, с другой стороны, того же в церкви ищут люди,
                привыкшие иметь идеологию, и церковь, к сожалению, поддается на это искушение.</p>
            

            
            <p>&nbsp; Есть, как отмечали докладчики, и более
                глубокие пласты социальной жизни, “играющие” в этой ситуации. Большинство наших
                людей, включая интеллигенцию, — горожане в первом или втором поколении, к тому
                же люди, у которых долгое время была отнята повседневность, и в церковной жизни
                они ищут именно этого — дома, тепла, быта (доклад О. И. Генисаретского). Что
                же, было бы абсурдно считать, что человек не имеет на это права, или что
                церковь не должна давать этого человеку. Но почему это должно обязательно
                мешать подлинной духовной жизни и приводить к филетизму? Здесь нельзя не вспомнить
                рассказ иеромонаха Антония (Ламбрехтса) о сибирском миссионере архимандрите
                Спиридоне. Многие читали его воспоминания, недавно переизданные, и помнят, с
                какой теплотой он пишет о духовной жизни и благочестии русского народа — и как
                радостно было услышать о собранной им в Киеве после революции евхаристической
                общине, где литургия служилась с открытыми Царскими вратами, а анафора читалась
                вслух всего народа (при этом арх. Спиридон активно сопротивлялся
                обновленческому расколу).</p>
            

            
            <p>&nbsp; Человек постиндустриального и
                постмодернистского мира ищет оснований, ищет fundamentаls — и, мне кажется,
                невозможно осуждать его за это желание. Можно согласиться с Александром
                Кырлежевым в том, что нам нужна “постоянная реакция на новый опыт, на события
                собственной жизни, на новые встречи и осмысление этой реакции”, иначе мы
                перестанем иметь дело с реальностью, — как уже, собственно, со многими и
                произошло — однако не только это, ведь жизнь церкви все-таки не жизнь науки.
                Можно и нужно говорить о христианстве как свободе, но свобода не должна
                исключать дома. Только вот где найти это основание, этот фундамент — такой,
                чтобы, встав на него, человек не проваливался в фундаментализм?</p>
            

            
            <p>&nbsp; В этом отношении была показательна собственно
                богословская часть конференции. Мы, кажется, слишком легко противопоставляем
                предания с маленькой буквы — Преданию с большой, говоря, что все предания
                относительны, настоящее же Предание есть сам Дух церковной жизни, река,
                оставляющая после себя золотой песок преданий. Так ли это? Да, Церковь живет
                Духом Святым, но утверждена она на основании апостолов и пророков, и неужели
                нельзя выделить это основание, как когда-то Церковь зафиксировала канон
                Священного писания? Выделить так, чтобы это было живое, “бытийное”, по словам
                о. Виктора (Мамонтова), основание? </p>
            

            
            <p>&nbsp; Думается, что главный итог, главный вывод
                конференции именно в этом. За время константиновской эпохи церковь слишком
                “экстериоризировалась”. Церковь создала христианское государство, христианское
                общество, христианский брак, христианскую культуру, христианскую философию,
                христианскую литературу, науку, образование и даже психологию. В конце концов
                церковь не заметила, как потеряла себя самое. Это выражается в частности в том,
                что не слышен голос Церкви, и непонятно, где его услышать. Может быть —
                делаются предположения — дело в том, что нет соборов? — но вот в
                старообрядчестве они есть, но ситуация, кажется, не лучше. Потеряны не соборы,
                потеряно предание с какой-то не маленькой и не большой, но средней буквы,
                предание в собственном смысле слова, как сам процесс передачи Духа Святого,
                передачи живого духовного опыта. Секуляризация не столько в том, что церковь
                потеряла мир, сколько в том, что она предварительно отождествила себя с миром.
                (Проблемы экуменизма, которых тоже касались на конференции, восходят к тому же.
                Отождествив себя с частью империи, церковь забыла о братьях по вере и поэтому
                легко пошла на раскол.) Вещи для Церкви важные, но все-таки внешние (например —
                государство, брак или культура), оказались в роли хранителей предания, и
                поэтому возрождаясь, церковь не может восстановить себя самое, маниакально
                восстанавливая что-то еще. Это очень хорошо всем видно, но, к сожалению, чаще в
                других, а не в себе, поэтому и возникает обстановка практически всеобщего
                взаимного недоверия и подозрительности: “Люди заразились духом сектантства и
                самодостаточности в какой-то необычайной степени”, мы присутствуем при
                “страшном явлении саморазрушения Церкви... Церковь перестает узнавать свое как
                свое, и называет его чужим, или наоборот, чужое называет своим” (доклад о.
                Георгия Кочеткова). В наше горькое время если кто с кем и дружит, то, кажется,
                только “против кого-то”.</p>
            

            
            <p>&nbsp; В этом отношении очень показательна история
                церкви первых послереволюционных лет: борясь с обновленчеством, патриарх Тихон,
                как было показано в докладе В.К.Котта, спасал церковь именно пытаясь возродить
                ее самое.</p>
            

            
            <p>&nbsp; Если из истории Церкви мы знаем, что
                появление ереси есть призыв решить в Церкви какие-то давно назревавшие в ней
                проблемы, если это всегда еще и знак того, что Церковь готова их решить, то не
                является ли разгул фундаментализма, который о. Иоанн Мейендорф назвал “ересью
                ХХ века”, для нас подобным сигналом? Не настало ли время “найти” Церковь в
                церкви, найти собственные основания или, по крайней мере, поставить такой
                вопрос? </p>
            

            
            <p>&nbsp; Для меня было очень символично, что
                конференция заканчивалась выступлением архимандрита Виктора (Мамонтова),
                сделавшего в своем докладе или, как он его назвал, слове, акцент как раз на
                том, что “истинное предание бытийно”: “Самое главное в возрождении Церкви — это
                обретение реальности всех ее таинств, и прежде всего — таинств вступления в
                Церковь (крещения и миропомазания) и таинства таинств — Евхаристии”. Первое
                может быть сделано через катехизацию — не как обучение или сообщение каких-то
                знаний, но как приведение человека к живому Христу через общение и передачу
                живого опыта, а второе — через возрождение серьезной общинной жизни.</p>
            

            
            <p>&nbsp; Естественно, в этой связи на конференции не
                мог не возникнуть вопрос, уже поднимавшийся четыре года назад на “Афанасьевских
                чтениях”, — вопрос о границах Церкви. В этом отношении наиболее интересен был
                доклад Антуана Аржаковского о том богословском контексте, в котором появился
                сборник “Живое предание” (во многом он стал ответом на гонения, которым
                подвергся о. Сергий Булгаков). Содержание доклада достойно того, чтобы
                остановиться на нем чуть подробнее.</p>
            

            
            <p>&nbsp; В период между двумя войнами в истории
                парижской русской богословской мысли, оказавшейся в обстановке свободной
                встречи с инославным миром, можно выделить три периода. Первый может быть назван
                мифологическим — это период мифов и крушения мифов (поскольку сознание,
                неподготовленное к встрече с новой реальностью, пытается загородиться от нее
                мифами) — например, мифа о Западе и Востоке, мифа о мистичности и глубине
                Православия и юридизме и поверхностности католицизма. Второй — период поиска
                новых границ Церкви после крушения мифов, обсуждения проблемы этих границ.
                Наконец третий — период практического синтеза, период реализма или ортопраксии.</p>
            

            
            <p>&nbsp; Вопрос о границах Церкви, который нас сейчас
                особенно интересует, рассматривался, по мнению докладчика, в соответствии с
                тремя пониманиями и путями познания Св. Троицы, которые он охарактеризовал как
                “Бог через Себя” (т.е. Энергия), “Бог через Себя и в Себе” (т.е. Премудрость
                или Сущность) и, наконец, “Бог в Себе” (т.е. Личность).</p>
            

            
            <p>&nbsp; Первый подход был характерен для о. Г.
                Флоровского и В. Н. Лосского, он может быть назван “апофатическим”. Как мы не
                знаем, что есть Бог, так мы не знаем и того, что есть Церковь. Однако Церковь
                существует в объективированном мире, поэтому она необходимо должна как-то
                выявлять себя в этом мире, иметь внешние границы, о них-то и может идти речь.
                Это, прежде всего, догматы, понимаемые как апофатическая ограда Церкви
                (например, знаменитые четыре “не” халкидонского ороса), и каноны, в которых эти
                догматы получают практическое и историческое выражение, и прочие предания и
                практики. Этот подход может быть назван также каноническим, для него проблема
                границ Церкви связана прежде всего с богословской работой.</p>
            

            
            <p>&nbsp; Второй подход — о. Сергия Булгакова. Для него
                границы Церкви связаны не столько с выявлением Церкви, сколько с ее явлением —
                “через себя и в себе”. Он может быть назван “катафатическим, софиологическим и
                эсхатологическим” и соотносится прежде всего с таинствами, с Евхаристией, где
                Церковь реализует то, что ей дано (одно время он даже считал, что богословское
                общение с инославными без Евхаристии — просто опасно), и, соответственно, с
                догматами, понимаемыми уже не как апофатическая ограда, но как таинство веры.
                Это измерение Церкви вслед за о. Александром Шмеманом также может быть названо
                мистериальным.</p>
            

            
            <p>&nbsp; Третий — персоналистический подход, подход
                Бердяева. Может быть, это самый высокий подход. Действительно, православное
                богословие различает в Св. Троице Энергию и Сущность, но учит, что все это
                восходит к единому Богу как Личности. Для Бердяева Церковь и представлена
                Личностью — Личность есть цель Церкви и явление Церкви в себе, она реализует
                себя в общине и общении. Так понимаемые границы Церкви, несмотря на их
                мистичность или благодаря ей, во многом шире и подвижнее остальных. В этом
                отношении нельзя не вспомнить прозвучавшее на конференции сообщение
                архимандрита Августина (Никитина) о святом праведном Иоанне Кронштадтском,
                который молился не только о соединении церквей (что с точки зрения многих наших
                фундаменталистов — уже ересь, церковь-то ведь одна — православная!), но и с
                англиканами на богослужении. Или доклад И. Заикановой о епископе Антонине
                (Грановском), который, как она показала, оставался истинным членом Церкви даже
                тогда, когда ошибался в выборе ее канонических границ.</p>
            

            
            <p>&nbsp; Это обсуждение границ Церкви и привело к
                качественно новому этапу — реализма, или ортопраксии, связанному с именами о.
                Н. Афанасьева, матери Марии (Скобцовой), Льва Зандера и Н. Зернова (последний,
                как отметил А. Аржаковский, даже сделал попытку выработать практические
                критерии, знаки присутствия в общине Св. Духа, помогающие избегать
                проникновения в Церковь внешней власти: 1) продолжительность церковной жизни
                общины, 2) церковное творчество общины, 3) проявление в ней даров Св. Духа).
                Этот подход совмещает в себе все три аспекта, что хорошо видно, например, в
                статье Л. Зандера в сборнике “Живое предание”.</p>
            

            
            <p>&nbsp; Думается, что та часть русской церкви, что
                оказалась в эмиграции, отчасти прошла тот путь, который предстоит пройти и всем
                нам. Действительно, не связан ли кризис церкви, с которым мы сейчас
                столкнулись, с господством и неизбежным кризисом мифологического мышления — тех
                мифов, которыми православная церковь в сегодняшней России, тоже оказавшаяся неготовой
                к встрече с новой реальностью, жила в последние годы: миф о Святой Руси, миф о
                чистоте православия, миф о старцах, миф о соборах, миф о еретиках, миф о
                неообновленцах, миф о симфонии церкви и государства, миф о царе-праведнике, миф
                о тоталитарных сектах, миф о... можно продолжать, кажется, до бесконечности. (В
                Париже подобный кризис привел к гонениям на о. Сергия Булгакова, обвинения в
                ереси с которого были сняты только летом 1936 г. — непосредственно перед
                выходом сборника “Живое предание”. У нас же — свои жертвы.)</p>
            

            
            <p>&nbsp; Если следовать схеме Антуана Аржаковского, то
                следующий этап — серьезное обсуждение границ Церкви, и он, кажется, уже так или
                иначе начинается. И здесь главное, мне кажется, постоянно иметь в виду все эти
                три подхода. Когда-то на заре церковной истории эти границы Церкви совпадали,
                теперь же они разошлись, и было бы непростительной ошибкой игнорировать этот
                факт. Только максимально серьезное и внимательное отношение ко всем трем этим
                границам, трем измерениям Предания, как укорененным в бытии Св. Троицы, может
                действительно сделать наше предание подлинно живым. Только понимание того, что
                недопустимо как забвение и игнорирование какой-либо из этих границ, так и
                произвольная “выдача желаемого за действительное”, то есть поспешное сведение этих
                границ друг к другу в нашем сознании, только постоянные усилия, направленные к
                тому, чтобы эти границы все-таки сближались, может уберечь нас от крайностей
                фундаментализма и модернизма.</p>
            

            
            <p>&nbsp; И еще одно. “Мифологический период” в Париже
                закончился тогда, когда, как пишет в сборнике “Живое предание” Лев Зандер,
                “увидели в инославных братьях христиан”. Не пора ли и нам — и не дает ли нам
                сегодняшняя ситуация такую возможность — увидеть друг в друге православных?</p>
            

            
            <p>&nbsp; Будем надеяться, что переиздание в 1997 г. в России
                парижского сборника 1937 г. “Живое предание” и одноименная конференция
                послужат, как и тогда в Париже, не только прекращению гонений на верных членов
                Церкви, но и переходу церкви к периоду уяснения своих границ и далее — к
                периоду реализма и ортопраксии. </p>
            

            
            <p>НА ЕПАРХИАЛЬНОМ
                СОБРАНИИ МОСКВЫ, </p>
            

            
            <p>ИЛИ «КАРФАГЕН ЛЖИ
                РАЗРУШЕН»</p>
            

            
            <p>“Сретенский
                листок”&nbsp; №16 (70), декабрь 1997 г. </p>
            

            
            <p>&nbsp; Мы&nbsp;
                печатаем&nbsp; некоторые отрывки из
                выступления правящего архиерея Москвы Святейшего Патриарха Алексия II,
                опубликованные в газете «Радонеж». Весь номер газеты, как и само выступление,
                даны под шапкой «КАРФАГЕН ЛЖИ РАЗРУШЕН». Таким образом, по мнению
                «православного обозрения «Радонеж», в подводящем итоги этого года докладе
                Святейшего был дан окончательный ответ на призыв игумена Тихона (Шевкунова)
                уничтожить нашу общину, и это, конечно, для «Радонежа» самое важное в
                выступлении Патриарха.</p>
            

            
            <p>&nbsp;&nbsp; ...В последнее десятилетие совершается
                зримый процесс возвращения значительной части нашего общества, в прошлом
                оторванного от Церкви, в ее спасительное лоно — это плоды подвижнического
                миссионерского труда многих наших пастырей. Но нельзя умолчать и о том, что в
                миссионерской практике некоторых священников и в некоторых приходах наблюдаются
                болезненные явления. Печальным примером ложного подхода к делу христианского
                просвещения явилась ситуация, сложившаяся в приходе Успения Пресвятой
                Богородицы в Печатниках в ту пору, когда его возглавлял ныне запрещенный в
                служении священник Георгий Кочетков. Эта ситуация закономерным образом привела
                к злополучному эксцессу, за которым последовали неизбежные прещения со стороны
                Священноначалия. Все дело в том, что псевдомиссионерская деятельность в этом
                приходе ориентирована на привлечение духовно ищущих людей не ко Христу и в
                Церковь, а непременно в собственную приходскую общину. </p>
            

            
            <p>&nbsp;&nbsp; В этом приходе сложился совершенно
                нетерпимый в Церкви культ настоятеля, авторитет которого ставится выше
                авторитета Священноначалия. Тем самым попирались самые основы канонического
                строя; в общине грубо пренебрегали богослужебными церковными традициями. Эта
                община противопоставляла себя другим приходам Русской Православной Церкви,
                являясь, по сути дела, сектантски ориентированным сообществом. Она пыталась
                оказывать прямое давление на нас как через светские структуры, прессу,
                телевидение, так и извне, через зарубежных религиозных деятелей, которым я
                вынужден был ответить следующее: </p>
            

            
            <p>&nbsp;&nbsp; “Что касается отца Георгия Кочеткова и
                создавшегося вокруг него напряжения, то дело тут не в его желании сделать
                литургическую жизнь более понятной для современных верующих и помочь им в более
                полном участии в ней — этого мы все желаем, а в серьезных экклезиологических,
                дисциплинарных и духовных искажениях церковной жизни, опасность и вредность
                которых для бытия Русской Православной Церкви остается неосознанной многими на
                Западе. Случай откровенного насилия, учиненного отцом Георгием и его
                последователями над вторым священником прихода, был обстоятельно расследован
                авторитетной комиссией, возглавляемой епископом, и вынудил законные и необходимые
                прещения. Думаю, что для единства и мирной жизни Церкви неполезно поощрять
                безумную гордыню, доводящую до безрассудных поступков, тенденциозную критику,
                недоверие и неуважение к Священноначалию. Мне кажется, что правящему епископу
                проблемы, возникающие в его епархии, не менее близки и не менее понятны, чем
                сочувствующим издалека — из другой страны, из другой юрисдикции”.</p>
            

            
            <p>&nbsp;&nbsp; Необходимо сугубое внимание уделить вопросам
                церковной дисциплины. И пастыри, и миряне в своем служении, в своей
                деятельности, во всей своей жизни обязаны руководствоваться святыми канонами,
                авторитетными постановлениями высшей церковной власти, неукоснительно следовать
                указаниям правящего архиерея, ибо епископам, по учению преподобного Иоанна
                Дамаскина, Господь вручил Церковь. Соборное начало в жизни Церкви действует в
                сочетании с началом иерархическим.</p>
            

            
            <p>&nbsp; “Радонеж” №21(65), декабрь 1997 г., текст
                выступления сверен по ЖМП №2, 1998 г.</p>
            

            
            <p>&nbsp;&nbsp; ...Пение хора также должно создавать
                молитвенную обстановку, доносить каждое слово до ума и сердца слушающих. </p>
            

            
            <p>&nbsp;&nbsp; Чрезмерно растянутое церковное пение (будь
                то концертное или унисонное) не способно создать молитвенный настрой и только
                удлиняет службу. Не может создать его и невнятное, торопливое чтение. Этим
                пользуются всякого рода раскольники, призывающие к реформам, к изменению
                богослужебного языка. Они зазывают народ к себе, где все понятно, где ласково и
                приветливо встретят. А между тем любой церковнославянский текст, будь то
                паремии, послание Апостола, Евангелие, прочитанное не спеша, с ясным пониманием
                самим читающим того, что он читает, несомненно, будет понятен без всяких
                переводов каждому слушающему.</p>
            

            
            <p>&nbsp; Текст выступления сверен по ЖМП №2, 1998 г.</p>
            

            
            <p>&nbsp;&nbsp; ...Но все эти общеобразовательные учреждения
                не должны существовать сами по себе — они должны реально ощущать направляющую
                руку Отдела, проводящего продуманную линию развития православного образования.
                Слава Богу, что самостийно возникают на приходах воскресные школы,
                катехизаторские курсы, огласительные училища, но, когда за этими самородками
                нет реального надзора со стороны Отдела, это способно приводить к созданию
                таких школ, как Высшая православная школа при храме Успения Пресвятой
                Богородицы в Печатниках и тому подобных учебных заведений, которые действуют от
                имени РПЦ, но являются не выразителями святоотеческого предания, а проводниками
                надуманных рационалистических конструкций либо полной богословской
                неграмотности.</p>
            

            
            <p>&nbsp; Текст выступления сверен по ЖМП №2, 1998 г.</p>
            

            
            <p>&nbsp; Из ответов на записки: Вопрос о том, что
                поступают во все приходы от группы Кочеткова, священника, такие послания. Ну,
                никаких ответов на эти послания давать не следует, поскольку они имеют целью
                посеять недоверие, неприязнь к священноначалию и вызвать смуту в Церкви.
                Картинка, приложенная к данному посланию, красноречиво свидетельствует о
                настроениях этих людей. На этой картинке изображены святители, святые земли
                Русской, а под ней надпись: «Слепые вожди привели слепых в яму».</p>
            

            
            <p>&nbsp;&nbsp; И я уже сказал, что обращение, такое же
                обращение есть к депутатам Государственной Думы. Я уже сказал, что любые
                обращения в государственные инстанции или попытка оказывать давление на нас ни
                к чему не приведут, приведут только к&nbsp;
                обратным действиям. Мы разбираемся по справедливости.</p>
            

            
            <p>&nbsp;&nbsp; ...В двух записках в отношении школы,
                которую возглавлял Кочетков. Было в моем указе и в моем распоряжении сказано,
                что отец Олег Клемышев должен взять руководство, но пока его туда не впускают.
                Школа эта еще пока вне нашей юрисдикции продолжает осуществлять свою
                деятельность.</p>
            

            
            <p>ДОКУМЕНТЫ И ФАКТЫ</p>
            

            
            <p>“Сретенский
                листок”&nbsp; №16 (70), декабрь 1997 г. </p>
            

            
            <p>&nbsp; 1.  Катехизация,
                которую проводит Огласительное училище при Свято-Филаретовской высшей
                православно-христианской школе, приводит не в “свою приходскую общину”, а в
                Церковь — именно теперешняя ситуация это полностью показала. Сейчас у общины
                нет своего прихода, но оглашение продолжается в полном объеме: оглашаемые,
                воцерковленные ходят во многие храмы, становятся членами других приходов
                Русской православной церкви — как это было и раньше.</p>
            

            
            <p>&nbsp; 2.  Авторитет
                духовного отца у его духовных чад&nbsp; в
                православной традиции духовничества был сравнительно слабо связан с его
                относительным положением в иерархии и отнюдь не может сам по себе разрушать
                иерархические начала Церкви, так как бесспорный для всякого православного авторитет
                архиерея лежит в иной плоскости — в церковном устроении — икономии. К тому же
                члены нашей общины нередко избирают своим духовником не о. Георгия Кочеткова, а
                других священников, хотя часто и воздают ему особую честь, ведь к нему можно
                применить слова апостола Павла: «у вас тысячи наставников во Христе, но не
                много отцов; я родил вас во Христе Иисусе благовествованием».</p>
            

            
            <p>&nbsp; 3.&nbsp; Свято-Филаретовская
                высшая школа постоянно сотрудничала с Отделом религиозного образования и
                катехизации МП, получила представление Отдела на государственное
                лицензирование, где сказано, что “в результате изучения образовательной
                программы Лицензионная комиссия Отдела утверждает соответствие содержания
                образования православному вероучению”.</p>
            

            
            <p>&nbsp; 4.  По
                различным приходам и епархиям рассылалась статья&nbsp; Б.Колымагина «Общину о. Георгия Кочеткова
                изгоняют из храма (в то время как официальное расследование ее полностью
                оправдало)», опубликованная в «Русской мысли», и в которой содержатся материалы
                о несостоятельности всех обвинений в адрес свящ. Георгия Кочеткова и его
                прихожан. Сотни архи-, прото- и просто иереев, получивших эту статью, могут
                подтвердить, что никаких картинок с анекдотическими подписями к этой статье не
                прилагалось.</p>
            

            
            <p>&nbsp; 5.  Как
                видно и из текста указа Святейшего Патриарха, и из опубликованной ниже
                переписки, в указе&nbsp; предписывалось прот.
                Олегу Клемышеву «взять руководство» над приходскими учебными заведениями храма
                Успения в Печатниках, к коим Свято-Филаретовская школа никогда не принадлежала.</p>
            

            
            <p>&nbsp;&nbsp; * * *</p>
            

            
            <p>&nbsp;&nbsp; Ректору МВПХШ</p>
            

            
            <p>&nbsp;&nbsp; Указом Святейшего Патриарха № 4520 от 9
                октября 1997 г. мне, настоятелю храма Успения Пресвятой Богородицы в
                Печатниках, поручено попечение о духовном окормлении приходских учебных
                заведений и братств (вместо свящ. Георгия Кочеткова).</p>
            

            
            <p>&nbsp;&nbsp; Предлагаю Вам в самое ближайшее время
                связаться со мной для согласования даты нашей встречи. </p>
            

            
            <p>&nbsp;&nbsp; Настоятель&nbsp;
                храма Успения Пресвятой Богородицы</p>
            

            
            <p>&nbsp;&nbsp; в Печатниках прот. Олег Клемышев</p>
            

            
            <p>&nbsp;&nbsp; 18.10.97</p>
            

            
            <p>&nbsp; Настоятелю храма Успения Пресвятой Богородицы
            </p>
            

            
            <p>&nbsp; в Печатниках, благочинному Сретенского округа
            </p>
            

            
            <p>&nbsp; г. Москвы прот. Олегу Клемышеву</p>
            

            
            <p>&nbsp; Ваше Высокопреподобие!</p>
            

            
            <p>&nbsp; На Ваши запросы о Свято-Филаретовской Школе и
                действующем при ней Огласительном училище, доставленные нам 29.10.97 г.,
                сообщаем следующее.</p>
            

            
            <p>&nbsp; Свято-Филаретовская московская высшая
                православно-христианская школа учреждена в 1988 г. В 1992 г. Школа получила
                государственную регистрацию (свидетельство № 156 от 17.07.92 г.) и
                благословение на труды от Святейшего Патриарха Московского и всея Руси Алексия
                II (резолюция № 2551 от 27.08.92 г). В Школе и Огласительном училище, в
                соответствии с Уставом, могут обучаться православные христиане, как правило, не
                моложе 21 года, из любого прихода г. Москвы и других городов России и
                зарубежья.</p>
            

            
            <p>&nbsp; Ректор Школы</p>
            

            
            <p>&nbsp; 5.11.97</p>
            

            
            <p>&nbsp;&nbsp; Ректору МВПХШ</p>
            

            
            <p>&nbsp;&nbsp; Заведующему Огласительным училищем</p>
            

            
            <p>&nbsp;&nbsp; Напоминаю, что Указом Святейшего
                Патриарха&nbsp; Московского и всея Руси
                Алексия от 09.10.97 №4520 мне поручено попечение о духовном окормлении
                приходских учебных заведений (вместо&nbsp;
                свящ. Георгия Кочеткова). Приходится с сожалением констатировать, что в
                Вашем ответном письме, доставленном мне 13.11.97, мое настоятельное предложение
                о согласовании даты нашей встречи Вами полностью игнорируется.</p>
            

            
            <p>&nbsp;&nbsp; Хочу предупредить, что дальнейшее Ваше
                уклонение от подобной встречи будет расценено мной как недопустимое
                сопротивление воле епархиального архиерея.</p>
            

            
            <p>&nbsp;&nbsp; Поэтому еще раз предлагаю Вам в самое
                ближайшее время связаться со мной для согласования даты нашей встречи.</p>
            

            
            <p>&nbsp;&nbsp; Настоятель храма Успения Пресвятой
                Богородицы</p>
            

            
            <p>&nbsp;&nbsp; в Печатниках протоиерей Олег Клемышев</p>
            

            
            <p>&nbsp;&nbsp; 21.11.97</p>
            

            
            <p>&nbsp;&nbsp; Его Высокопреподобию,</p>
            

            
            <p>&nbsp;&nbsp; протоиерею Олегу Клемышеву,</p>
            

            
            <p>&nbsp;&nbsp; благочинному церквей Сретенского округа</p>
            

            
            <p>&nbsp;&nbsp; города Москвы</p>
            

            
            <p>&nbsp;&nbsp; Копия: Руководству братства “Сретение”</p>
            

            
            <p>&nbsp;&nbsp; Руководству Московской высшей </p>
            

            
            <p>&nbsp;&nbsp; православно-христианской школы</p>
            

            
            <p>&nbsp;&nbsp; Руководству Огласительного училища. </p>
            

            
            <p>&nbsp;&nbsp; Канцелярия Московской Патриархии сообщает
                Вашему Высокопреподобию резолюцию ЕГО СВЯТЕЙШЕСТВА, положенную на Вашем рапорте
                от 5.12.97 г.:</p>
            

            
            <p>&nbsp;&nbsp; “8.ХII.1997 г. В связи с неподчинением
                Нашему Указу от 9 октября 1997 года за № 4520 о передаче руководства по
                духовному окормлению братства “Сретение” и приходских учебных заведений, как
                Московская высшая христианская православная школа, свт. Филарета, митрополита
                Московского и огласительное училище, от запрещенного в служении свящ. Георгия
                Кочеткова протоиерею Олегу Клемышеву, Наше благословение на их дальнейшую
                деятельность снимается”.</p>
            

            
            <p>&nbsp;&nbsp; Арсений, Архиепископ Истринский,</p>
            

            
            <p>&nbsp;&nbsp; викарий Святейшего Патриарха Московского и
                Всея Руси</p>
            

            
            <p>&nbsp; Его Святейшеству, </p>
            

            
            <p>&nbsp; Святейшему Патриарху Московскому и всея Руси</p>
            

            
            <p>&nbsp; АЛЕКСИЮ II</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыка!</p>
            

            
            <p>&nbsp; 11 декабря с.г. нам сообщили о Вашей
                резолюции на рапорте благочинного церквей Сретенского округа г.Москвы, нового
                настоятеля храма Успения Пресвятой Богородицы в Печатниках прот. Олега
                Клемышева, в которой говорится о снятии Вашего благословения на дальнейшую
                деятельность Свято-Филаретовской московской высшей православно-христианской
                школы и Огласительного училища на основании того, что со стороны этих учебных
                заведений будто бы имеет место неподчинение Вашему Указу от 9.10.97 № 4520.</p>
            

            
            <p>&nbsp; Ранее мы получали письмо от прот. Олега
                Клемышева в адрес руководства этих школ с просьбой о встрече для согласования
                вопросов по вступлению его в духовное окормление Свято-Филаретовской школы и
                Огласительного училища. Однако в тексте вышеупомянутого Вашего Указа
                определенно говорится не об этих церковных учебных заведениях, а о приходских
                школах храма Успения Богородицы в Печатниках. В действительности, Огласительное
                училище было основано в 1979 г., а Свято-Филаретовская московская высшая
                православно-христианская школа — в 1988 г. (т.е. за многие годы до открытия
                братством “Сретение” Успенского храма), они имеют свой Устав, государственную
                регистрацию и лицензию Отдела по катехизации и религиозному образованию и
                никогда не могли быть и не были приходскими школами Успенского храма. Более
                того, они вообще находятся не в Сретенском благочинии. К тому же свящ. Георгий
                Кочетков никогда не был духовным попечителем этих школ, так как подобный статус
                не предусматривается их Уставом. Обо всем этом и был незамедлительно дан ответ
                прот. Олегу Клемышеву.</p>
            

            
            <p>&nbsp; Хотя наши школы никогда не претендовали и не
                претендуют на какое-либо участие в приходских учебных заведениях Успенского
                храма, мы готовы оказывать любую методическую и прочую помощь приходским
                учебным заведениям храма Успения, как и других храмов, по просьбе их
                руководства, как уже и было во многих случаях.</p>
            

            
            <p>&nbsp; В связи с этим мы верим и надеемся, что
                возникшее по внушению врага рода человеческого досадное недоразумение будет
                вскоре исправлено, дабы не вводить в жестокое смущение жаждущий просвещения
                церковный народ, и особенно сотни преподавателей и студентов наших школ (среди
                которых есть представители других стран и поместных церквей), а также тысячи
                оглашающихся и огласившихся в них. Никто да не даст повод врагам Церкви думать,
                что нечестивый дух человеческих противоречий в ней может победить Божий Дух
                Любви, Свободы, Света и Истины.</p>
            

            
            <p>&nbsp; Испрашивая Ваших Первосвятительских молитв,</p>
            

            
            <p>&nbsp; Вашего Святейшества недостойные послушники и
                богомольцы</p>
            

            
            <p>&nbsp; Ректор Свято-Филаретовской московской
                православно-</p>
            

            
            <p>&nbsp; христианской школы М.В.Шилкина, канд. филос.
                наук,</p>
            

            
            <p>&nbsp; Декан МВПХШ&nbsp;&nbsp;
                И.Я.Гриц, канд.эконом. наук,&nbsp;&nbsp;&nbsp; </p>
            

            
            <p>&nbsp; Зав. Огласительным училищем М.И.Зельников, </p>
            

            
            <p>&nbsp; канд.физ.-мат.наук</p>
            

            
            <p>&nbsp; 15&nbsp;
                декабря 1997 г.</p>
            

            
            <p>&nbsp; Его Святейшеству, </p>
            

            
            <p>&nbsp; Святейшему Патриарху Московскому и всея Руси </p>
            

            
            <p>&nbsp; АЛЕКСИЮ II</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыко,
                благословите!</p>
            

            
            <p>&nbsp; К Вам обращаются участники общего собрания
                православного просветительско-благотворительного братства “Сретение”,
                проведенного в Москве 15 ноября с.г.</p>
            

            
            <p>&nbsp; Братство было учреждено в сентябре 1990 г.,
                имеет государственную регистрацию и благословение на деятельность от Вашего Святейшества.
                Братство в 1996 г. подавало свой новый Устав, приведенный в соответствии с
                решениями Архиерейского собора Русской Православной Церкви 1994 г. на
                утверждение Вашего Святейшества, однако ответ нами не был получен.</p>
            

            
            <p>&nbsp; Поэтому почтительнейше просим Вас принять к
                сведению, что общее собрание решило приостановить деятельность братства в связи
                с неопределенностью в настоящий момент его канонического статуса. </p>
            

            
            <p>&nbsp; По поручению общего собрания членов Братства,</p>
            

            
            <p>&nbsp; Вашего Святейшества&nbsp; недостойный послушник и богомолец</p>
            

            
            <p>&nbsp; Председатель Братства</p>
            

            
            <p>&nbsp; С.С.Тюльпин</p>
            

            
            <p>&nbsp; 15.11.97</p>
            

            
            <p>&nbsp;&nbsp; Председателю братства “Сретение” </p>
            

            
            <p>&nbsp;&nbsp; С.С.Тюльпину</p>
            

            
            <p>&nbsp;&nbsp; Канцелярия Московской Патриархии сообщает
                Вам резолюцию ЕГО СВЯТЕЙШЕСТВА, положенную на Вашем письме от 15 ноября 1997
                года:</p>
            

            
            <p>&nbsp;&nbsp; “20.ХI.1997 г. Устав братства “Сретение” в
                представленной в Московскую Патриархию редакции может быть НАМИ утвержден при
                условии выполнения НАШЕГО Указа от 9 октября1997 г. за № 4520.</p>
            

            
            <p>&nbsp;&nbsp; Если собрание братства “Сретение” не считает
                возможным исполнить данный Указ, НАШЕ благословение на его деятельность
                снимается.”</p>
            

            
            <p>&nbsp;&nbsp; Арсений, </p>
            

            
            <p>&nbsp;&nbsp; Архиепископ Истринский,</p>
            

            
            <p>&nbsp;&nbsp; викарий Святейшего Патриарха </p>
            

            
            <p>&nbsp;&nbsp; Московского и Всея Руси</p>
            

            
            <p>&nbsp;&nbsp; 20 ноября 1997 г.</p>
            

            
            <p>&nbsp; Его Святейшеству, </p>
            

            
            <p>&nbsp; Святейшему Патриарху Московскому </p>
            

            
            <p>&nbsp; и всея Руси АЛЕКСИЮ II</p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыка!</p>
            

            
            <p>&nbsp; Получив Ваш ответ на письмо членов общего
                собрания православного просветительско-благотворительного братства “Сретение”
                от 15.11.97, мы были сердечно рады и благодарны Вам, Ваше Святейшество, за
                открывшуюся возможность утверждения Устава нашего братства в представленной в
                Московскую Патриархию редакции.</p>
            

            
            <p>&nbsp; Мы всегда готовы исполнить любое условие,
                указание и благословение Вашего Святейшества и об ином никогда не помышляли,
                тем более в отношении Вашего Указа от 9.10.97. Поэтому мы с недоумением и
                глубоким огорчением узнали, что прот. Олегом Клемышевым нам вменяется
                невыполнение данного Указа, на основании чего снимается Ваше благословение на
                деятельность братства “Сретение”.</p>
            

            
            <p>&nbsp; Мы никогда не давали повода думать о
                неподчинении Вашему Святейшеству и теперь не понимаем, чем вызвана такая
                ситуация. Стоит сказать, что мы неоднократно обращались к прот. Олегу Клемышеву
                с просьбой, во исполнение вышеупомянутого Вашего Указа, собрать Приходское
                собрание храма Успения Пресвятой Богородицы в Печатниках, где естественно было
                бы разрешить все вопросы, в том числе касающиеся и будущего приходского
                братства. Наши просьбы остались не услышанными, в то время как весь
                двухтысячный приход, а значит и большая часть братства “Сретение”, оказались
                буквально изгнанными из своего храма, который открывался и поднимался нашими
                силами и средствами почти что из руин на протяжении целого ряда лет — с 1991
                года.</p>
            

            
            <p>&nbsp; Ваше Святейшество! Находясь в столь нелепых и
                скорбных обстоятельствах, смиренно просим разъяснить нам, грешным, данную
                ситуацию. Также испрашиваем Ваших Первосвятительских молитв о полном
                возвращении приходской общины и братства в свой храм.</p>
            

            
            <p>&nbsp; Да хранит Вас Господь во всяком деле блазе,
                совершаемом для укрепления и прославления свыше святой Русской Православной
                Церкви.</p>
            

            
            <p>&nbsp; Ваши недостойные послушники и богомольцы</p>
            

            
            <p>&nbsp; члены братства “Сретение” приходской общины </p>
            

            
            <p>&nbsp; храма Успения Пресвятой Богородицы в
                Печатниках</p>
            

            
            <p>&nbsp; От имени братства его секретарь</p>
            

            
            <p>&nbsp; С.С.Тюльпин</p>
            

            
            <p>&nbsp; 15&nbsp;
                декабря 1997 г.</p>
            

            
            <p>ПРОШЕНИЕ ЧЛЕНОВ </p>
            

            
            <p>ПОПЕЧИТЕЛЬСКОГО
                СОВЕТА </p>
            

            
            <p>СВЯТО-ФИЛАРЕТОВСКОЙ
                ВЫСШЕЙ </p>
            

            
            <p>ПРАВОСЛАВНО-ХРИСТИАНСКОЙ
                ШКОЛЫ </p>
            

            
            <p>&nbsp; Его Святейшеству, </p>
            

            
            <p>&nbsp; Святейшему Патриарху Московскому и всея Руси </p>
            

            
            <p>&nbsp; АЛЕКСИЮ II&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
            

            
            <p>&nbsp; Ваше Святейшество, Святейший Владыко!</p>
            

            
            <p>&nbsp; Мы обращаемся к Вам как Верховному
                Предстоятелю Русской Православной Церкви, а также как к Правящему Архиерею
                Москвы, с почтительным ходатайством о Свято-Филаретовской высшей школе,
                подвергшейся несправедливым нападкам в печати,&nbsp;
                а теперь, что нам несравнимо горше, еще и лишенной Вашего
                Первосвятительского благословения.</p>
            

            
            <p>&nbsp; Ваше Святейшество! Мы знаем основателя Школы
                и ее деятельность, и для нас невозможно воспринимать случившееся иначе, как
                трагическую ошибку, могущую иметь тяжелые последствия. Основанная в
                достопамятном году 1000-летия Крещения Руси, а фактически начавшая свою
                неофициальную деятельность много раньше, в годы атеистического режима, уже
                оказавшая реальную духовную помощь тысячам людей, которые приходили к Богу и
                становились верными чадами Русской Православной Церкви, хорошо известная многим
                в России и за рубежом,&nbsp; Школа эта не
                может и не должна оказаться вне юрисдикции Московской Патриархии. Для нашего
                искреннего чувства это невыносимо, а причин для этого мы не видим. Всем Вашим
                указам, как и распоряжениям Отдела религиозного образования, Школа всегда
                подчинялась. Что касается притязаний прот. Олега Клемышева на духовное
                руководство Высшей школой и Огласительным училищем, то они необоснованны
                постольку, поскольку учебные заведения, о которых идет речь, не являются и не
                являлись приходскими: ведь они были созданы много раньше открытия прихода
                Успения Пресвятой Богородицы в Печатниках и существовали все эти годы во
                взаимодействии также и с другими приходами нашей Церкви.</p>
            

            
            <p>&nbsp; Ввиду всего сказанного смиренно просим Вас,
                Ваше Святейшество, внять нашему свидетельству об искреннем стремлении
                Свято-Филаретовской школы и Огласительного училища, всех учащих и учащихся в
                них, всегда оставаться под Вашим Первосвященническим омофором, не уклоняясь в
                сторону, продолжая трудиться на благо Святой Православной Церкви и нашего
                Отечества, — и умоляем Вас благословить их труды.</p>
            

            
            <p>&nbsp; С сердечными пожеланиями Вам благодатной
                помощи Божией в Вашем многотрудном служении и просьбой о молитвах</p>
            

            
            <p>&nbsp; недостойные Вашего Святейшества послушники и
                богомольцы,</p>
            

            
            <p>&nbsp; члены Попечительского Совета
                Свято-Филаретовской Школы</p>
            

            
            <p>&nbsp; член-корреспондент Российской Академии Наук
                С.С.Аверинцев</p>
            

            
            <p>&nbsp; декан факультета журналистики МГУ,</p>
            

            
            <p>&nbsp; доктор филологических наук, профессор
                Я.Н.Засурский</p>
            

            
            <p>&nbsp; ведущий научный сотрудник Института Русского
                языка,</p>
            

            
            <p>&nbsp; доктор филологических наук, профессор
                Е.М.Верещагин</p>
            

            
            <p>&nbsp; главный редактор журнала “Континент”,</p>
            

            
            <p>&nbsp; академик Европейской Академии Культуры
                И.И.Виноградов</p>
            

            
            <p>&nbsp; профессор Университета Зап. Онтарио, Канада,</p>
            

            
            <p>&nbsp; доктор церковной истории Д.В.Поспеловский</p>
            

            
            <p>&nbsp; член Епархиального совета Сурожской епархии,</p>
            

            
            <p>&nbsp; редактор радиожурнала “Воскресение” (БиБиСи,
                Лондон), </p>
            

            
            <p>&nbsp; доктор богословия протоиерей Сергий Гаккель</p>
            

            
            <p>&nbsp; доцент Санкт-Петербургской Духовной Академии,</p>
            

            
            <p>&nbsp; кандидат богословия архимандрит Августин
                (Никитин)</p>
            

            
            <p>&nbsp; профессор Свято-Сергиевского богословского
                института</p>
            

            
            <p>&nbsp; в Париже, доктор богословия Оливье Клеман</p>
            

            
            <p>&nbsp; главный редактор журнала “Вестник Русского </p>
            

            
            <p>&nbsp; христианского движения” (Париж) Н.А.Струве</p>
            

            
            <p>&nbsp; член Русского христианского движения,</p>
            

            
            <p>&nbsp; писатель Е.Д.Клепинина-Аржаковская</p>
            

            
            <p>&nbsp; исполнительный директор Ассоциации друзей </p>
            

            
            <p>&nbsp; Свято-Филаретовской школы (Париж) </p>
            

            
            <p>&nbsp; Леонид Корабельников</p>
            

            
            <p>&nbsp; отправлено 11.03.98 г.</p>
            

            
            <p>А.Платонов</p>
            

            
            <p>НАС ПОЧИТАЮТ
                ОБМАНЩИКАМИ, НО МЫ ВЕРНЫ...</p>
            

            
            <p>НАС ПОЧИТАЮТ
                УМЕРШИМИ, НО ВОТ МЫ ЖИВЫ...</p>
            

            
            <p>(2 КОР 6:9)</p>
            

            
            <p>“Независимая
                газета”, 27.02.98 г. Здесь публикуется полный </p>
            

            
            <p>вариант статьи по
                журналу“Православная община”&nbsp; №43. </p>
            

            
            <p>В приложении к
                статье были опубликованы два постановления Мещанской прокуратуры, которые в
                настоящем сборнике </p>
            

            
            <p>помещены в
                разделе “Говорят документы”.</p>
            

            
            <p>&nbsp; 4 октября прошлого года опубликовала свою
                очередную статью (см. “НГ” № 187 (1512)) поэтесса Олеся Николаева, в которой
                она подготавливала общественное мнение к оглашению неправедного приговора
                священнику Георгию Кочеткову и 12 членам его общины. Этот приговор был спешно
                вынесен церковной комиссией по расследованию инцидента, происшедшего 29 июня в
                храме Успения в Печатниках, в полном противоречии с окончательными результатами
                проверки, проведенной прокуратурой, и разбирательством специальной комиссии
                Минздрава.</p>
            

            
            <p>&nbsp; Напомним, что произошло 29 июня. В тот день
                второй священник о. Михаил Дубовицкий устроил во время богослужения скандал, в
                храм были вызваны милиция и бригада психиатрической скорой помощи, принявшая
                ответственное решение о госпитализации о. Михаила с диагнозом “шизофрения,
                острый дебют”. В развитии скандала приняли участие монахи соседнего Сретенского
                монастыря, уже несколько лет гнавшие общину о. Георгия и возмущавшие церковную
                общественность против нее. Не прошло и нескольких часов после происшествия, как
                сводный хор церковно-националистических сил по радио, по телевидению и со
                страниц газет грянул: “физическая расправа..., уголовщина..., террористическая
                акция..., деньги с Запада...”! Бррр... </p>
            

            
            <p>&nbsp; Под такой аккомпанемент&nbsp; уже через день после событий в Успенском
                храме, 1-го июля, выходит Распоряжение Святейшего Патриарха, а 2-го июля его
                Указ (Документы см. в разделе “Из церковной документации” —&nbsp; ред.), как о несомненных фактах говорящие о
                надругательстве и избиении, насильственной госпитализации и физической расправе
                над священником Дубовицким и запрещающие в служении отца Георгия Кочеткова.</p>
            

            
            <p>&nbsp; Спустя некоторое время (26 июля) на страницах
                “НГ” был опубликован документ за подписью начальника 18 о/м г.Москвы Римского
                А.Л., в котором говорилось о драке священнослужителей (!) в алтаре и который,
                очевидно, был использован как основание для наложения прещений на о. Георгия.
                (Документ см. в разделе “Справки и постановления органов милиции и прокуратуры”
                — ред.) Вскоре после событий 29-го Патриарх назначает комиссию для
                расследования инцидента в Успенском храме, которая в результате своей работы
                будто бы установила “факт совершения насилия и глумления над свящ. Михаилом
                Дубовицким”, в связи с чем Предстоятель Русской церкви 9 октября подписывает
                следующий Указ (Документ см. в настоящем разделе&nbsp; (“Что же дальше?”) — ред.), которым оставляет
                о. Георгия Кочеткова под запретом и отлучает от причастия двенадцать членов его
                общины, среди которых почти все алтарники, члены приходского совета&nbsp; и семидесятилетняя старушка — помощница о.
                Георгия. Правда, в последнем Указе уже прямо об избиении и отправлении в
                психиатрическую больницу о. Михаила не говорилось, но еще говорилось о насилии,
                глумлении и оскорбительных действиях против него.</p>
            

            
            <p>&nbsp; Интересно, что параллельно с церковной
                комиссией по заявлению г-жи Дубовицкой, двадцатилетней псаломщицы одного из
                московских храмов, работали и светские компетентные органы: в том же 18 о/м шла
                проверка “фактов” насильственных действий над о. Михаилом и незаконного
                помещения его в психиатрический стационар, над тем же работали прокуратура и
                комиссия Минздрава. Все они свою работу также закончили, что и подтвердили
                заключительными документами, которые полностью опровергают выводы церковной
                комиссии,&nbsp; настолько скоропалительные,
                как будто они были приняты даже не в момент выхода первого Указа Святейшего
                Патриарха 2 июля, а еще раньше.</p>
            

            
            <p>&nbsp; Итак, свящ. Георгия Кочеткова и двенадцать
                его избранных прихожан обвиняют в тяжелом проступке — насилии над собратом и
                отправлении его с глумлением и оскорбительными действиями в психиатрический
                стационар чуть ли не без каких-либо на то оснований. Все это противники общины
                о. Георгия пытаются подтвердить упомянутым выше документом из 18 о/м,
                заключением врачей 14 психиатрической больницы (что поступивший к ним свящ.
                Михаил Дубовицкий на момент его выписки “признаков психического расстройства не
                выявлял”) (Документ см. в разделе “Медицинские документы” — ред.) и еще
                какими-то справками, которые, впрочем, никто не видел.</p>
            

            
            <p>&nbsp; Но вот 8 октября, буквально накануне
                подписания Патриархом Указа о прещениях относительно о. Георгия и его прихожан,
                из стен того же отделения милиции вышло Постановление об отказе в возбуждении
                уголовного дела по факту нарушения общественного порядка в храме Успения в
                Печатниках 29 июня. При этом в документе говорится, что “из объяснений самого
                Дубовицкого усматривается, что он возмущен его насильственным направлением в
                псих. больницу, о том, что было нарушение общественного порядка, он не
                говорит...”.</p>
            

            
            <p>&nbsp; Далее, 14 октября, на следующий день после
                вручения Указа о. Георгию и его прихожанам, было подписано заключение комиссии
                Минздрава РФ о проверке обстоятельств, связанных с направлением о. Михаила
                Дубовицкого в психиатрическую больницу. Эта комиссия досконально исследовала
                все материалы, касающиеся госпитализации о. Михаила 29 июня, выслушала его
                самого и даже его адвоката, помощника депутата Госдумы Грешневикова А.Н., а
                также врача скорой помощи, просмотрела полную видеозапись событий в Успенском
                храме. И вот в ее заключительном документе говорится, что “в момент
                стационирования М.В.Дубовицкий находился в остром реактивном состоянии, которое
                соответствует острой реакции на стресс с преобладанием нарушения поведения по
                Международной классификации болезней 10 пересмотра”. И далее: “В создавшихся
                условиях тактика врача Г.Л.Шафрана — решение вывести М.В.Дубовицкого из не
                поддающейся разрешению тяжелой психотравмирующей ситуации — была оправданной,
                ибо в противном случае действия врача следовало бы расценить как неоказание
                медицинской помощи”. (Документ см. в разделе “Медицинские документы” — ред.)
                Таким образом, принудительная госпитализация о. Михаила из храма 29 июня этим
                документом признана оправданной на самом высоком уровне, а следовательно, и
                причины, по которым она была произведена, в принципе подтверждены (т. е.
                диагноз не снят).</p>
            

            
            <p>&nbsp; Вскоре свое решение вынесла и Мещанская
                прокуратура. 20 октября было подписано Постановление об отказе в возбуждении
                уголовного дела по факту незаконной госпитализации за отсутствием состава
                преступления. Этот документ целиком читатель может прочесть ниже, поэтому в
                данном случае от комментариев можно отказаться.</p>
            

            
            <p>&nbsp; И наконец, последний документ, также
                публикуемый ниже, говорит о том самом злополучном письме начальника 18 о/м
                ст.лейтенанта Римского А.Л. от 1 июля. Как мы видим, документ прокуратуры ясно
                говорит, что информация, содержащаяся в милицейском документе, не соответствует
                действительности. Как же тогда понимать утверждение г-на Римского, будто бы 29
                июня сотрудники милиции «обнаружили происходящую драку в алтарной комнате между
                священниками, причем один из них, как в последствии выяснилось Дубовицкий
                Михаил Владимирович, находился со следами побоев и в разорванной церковной
                одежде», и его же, Римского, утверждение в телепрограмме “Криминал” (НТВ, 24
                июля) об избиении о. Михаила, когда уже было ясно, что никто не только не бил
                священника, но и не замахивался на него? Как вообще может делать подобные
                заявления человек, призванный устанавливать правду и наказывать преступающих
                закон?</p>
            

            
            <p>&nbsp; Кстати, из милицейских источников стал
                известен еще и такой примечательный факт. Когда вышел Указ об оставлении о.
                Георгия Кочеткова под запретом и об отлучении двенадцати его прихожан от
                причастия, жена о. Михаила пожелала забрать свое заявление, ибо, как вскоре
                поспешили объявить некоторые псевдоцерковные газеты, о. Михаил будто бы
                “простил своих обидчиков”. Но она опоздала: уже был готов отказ в возбуждении
                уголовного дела на основании проведенной проверки.</p>
            

            
            <p>&nbsp; К большому сожалению, особо приходится
                говорить о крайней необъективности и той церковной комиссии, которой Святейший
                Патриарх поручил расследовать печальный инцидент. Комиссия открыто не желала
                принимать во внимание ни свидетельские показания, ни результаты светского
                расследования, и поэтому, как только стало ясно, что наши отнюдь не захваленные
                в общественном мнении светские органы к чести их не собираются идти на поводу у
                лжи, сразу вынесла свое заключение. Комиссия так торопилась, что даже формально
                не опросила всех свидетелей происшедшего в Успенском храме, хотя и ее
                председатель — епископ Орехово-Зуевский Алексий, и ее секретарь — свящ.
                Александр Абрамов убеждали всех в обратном. Не опрошенным оказался даже, может
                быть, самый объективный и независимый свидетель этой истории — иеродиакон
                Серафим из Данилова монастыря, который в те часы присутствовал в храме и в
                алтаре (в отличие от всех “свидетелей” из Сретенского монастыря), сам пытался
                говорить с о. Михаилом и увещевать его и имя которого упоминали некоторые
                свидетели, вызывавшиеся на заседания комиссии.</p>
            

            
            <p>&nbsp; Как было сказано в последнем Указе Патриарха,
                комиссия “установила факт совершения насилия и глумления над священником
                Михаилом Дубовицким”. Но в чем, по ее мнению, это насилие и глумление выражалось?!
                Может быть, насилием и глумлением здесь ошибочно названа сама принудительная
                госпитализация? Почему-то до сих пор заключение комиссии остается неизвестным
                православной общественности и, таким образом, получается, что, простите,
                крайним в этой некрасивой истории оказывается сам епископ Москвы Святейший
                Патриарх Алексий II. Хотя все понимают, что человек, обремененный столькими
                заботами, не имеет достаточного времени для детального разбора подобных дел.
                Для этого и существуют викарии, канцелярия, масса помощников и проч. Похоже, и
                на этот раз помощники сильно подвели Патриарха, ведь его мнение по данной
                проблеме, каким бы оно ни было, основано на подготовленной ими информации.</p>
            

            
            <p>&nbsp; Теперь, как кажется, печальная история вокруг
                инцидента в московском храме Успения в Печатниках, разрекламированная
                ультраправыми околоцерковными СМИ как беспрецедентная за всю историю РПЦ,
                подходит к концу. На сегодняшний день не осталось никаких объективных
                документов, подтверждающих «факт» насилия и глумления над свящ.Михаилом
                Дубовицким, и теперь можно однозначно говорить о юридической и этической
                невиновности о. Георгия Кочеткова и его прихожан в том, что произошло в их
                храме в последнее июньское воскресенье. Так не наступило ли время искать и
                находить истинные причины происшедшего и происходящего в нашей церкви?</p>
            

            
            <p>&nbsp; Может быть, весь ужас нынешней российской
                действительности и заключается именно в беззаконии, в беспределе, из-за
                которого жизнь действительно кажется невыносимой. Глядя на печальный итог
                “служения” о. Михаила и его повседневных явных и тайных наставников, на
                практически пустующий ныне, а еще недавно наполнявшийся тысячами людей
                Успенский храм в Печатниках, вдвойне страшно становится из-за того, что именно
                в нашей церкви иногда царит этот беспредел и хаос, и главное, что в ней сейчас
                почти не видно сил, способных эту церковную болезнь уврачевать.</p>
            

            
            <p>&nbsp; Характер последовавших после Указа событий и
                публикаций меняется. С одной стороны, враги общины о. Георгия как будто
                добились своего: столь опасный для них священник и его ближайшие помощники под
                запретом, храм захвачен, община выгнана на улицу. Но с другой стороны, все шире
                становятся известными решения светских органов, оправдывающих общину, ставящие
                все на свои места, и поэтому гонители усиленно распространяют слухи уже о
                неподчинении о. Георгия Указу Патриарха (как это может быть?), о непомерной его
                гордыне и т.п.</p>
            

            
            <p>&nbsp; 3 декабря (почти три месяца назад!) под
                праздник Введения Богородицы во храм закончился срок епитимьи, наложенный на о.
                Георгия и его помощников епархиальным духовником. Все они, согласно его
                указаниям, направили на имя Патриарха соответствующие прошения о снятии
                прещений. Теперь, согласно своему же Указу, Святейший Патриарх должен принять
                решение о дальнейшей судьбе тех, кто по непонятным причинам все еще остается
                под прещениями. </p>
            

            
            <p>&nbsp; 1 марта Русская Православная Церковь отмечает
                Прощеное воскресенье, праздник, в который верующие просят прощения у тех,
                против кого согрешили, и прощают своих обидчиков, чтобы в мире со всеми начать
                Великий пост, время особого покаяния и предстояния в тишине и молитве пред
                Богом. Именно в этот момент есть уникальная возможность полностью восстановить
                справедливость и закончить эту весьма печальную и неприглядную для всех
                верующих историю без нанесения ущерба авторитету Русской Православной Церкви и
                ее иерархии и, тем самым, лучшим образом уврачевать этот конфликт. Надежда
                есть, и так хочется, чтобы Божья правда восторжествовала, чтобы земная церковь
                искала волю Божию и согласно ей следовала по своему пути. Но в такие моменты
                все же необходимо соблюдать трезвенность, ибо слишком велика опасность, что
                даже если епископ Москвы сам лично и захочет принять справедливое решение, ему
                не позволят это сделать окружающие его силы, которые, конечно же, не для таких
                решений готовили и развязывали этот грандиозный конфликт...</p>
            

            
            <p>На момент сдачи
                данного сборника в типографию<a name="r3" href="#3">[3]</a> решение патриархом так и
                не принято. О. Георгий не служит и вместе с двенадцатью своими прихожанами не
                причащается. Община Успенского храма по-прежнему остается на улице.</p>
            

            
            
            

            
            <hr class="wp-block-separator"/>
            

            
            <p><a name="1" href="#r1">[1]</a> Самый, кажется,
                полный ответ на этот вопрос был дан в докладе о. Георгия Кочеткова “Четыре опасные
                тенденции в современной церковной жизни”.</p>
            

            
            <p><a name="2" href="#r2">[2]</a> Определение предания, слышанное нами от духовного чада игумена Тихона (Шевкунова) о. Михаила Дубовицкого, спровоцировавшего конфликт в московском храме Успения в Печатниках.</p>
            

            
            <p> <a name="3" href="#r3">[3]</a> Сборник был издан в 1998 году. </p>
            
        </div>
    );
};

export default Zaos8;
