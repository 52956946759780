import React from "react";
import './text.css';
const Tomsk = () => {
    return (
        <div align={'start'} className="text1" >
            <h2>«Томское дело»</h2>
            
            
            

            
            <p>«Томское дело» </p>
            

            
            <p>ОТКРЫТОЕ ПИСЬМО
                ТОМИЧЕЙ ПАТРИАРХУ МОСКОВСКОМУ И ВСЕЯ РУСИ АЛЕКСИЮ II </p>
            

            
            <p>И ЧЛЕНАМ
                СВЯЩЕННОГО СИНОДА РУССКОЙ ПРАВОСЛАВНОЙ ЦЕРКВИ </p>
            

            
            <p><strong><em>Печатается
                в сокращении по материалу газеты “Томский Вестник”, 9.01.98 г. Предоставлено
                пресс-службой томского Союза студентов-христиан</em></strong></p>
            

            
            <p>&nbsp; Ваше Святейшество, с момента воссоздания в
                1995 г. Томской Епархии томичи ожидали назначения епископа, благочестивого и
                мудрого пастыря всех православных людей города и области. В октябре 1997 г. это
                долгожданное событие наконец произошло. Но уже первые месяцы пребывания на
                томской земле епископа Аркадия показали, что мы оказались обманутыми в своих
                ожиданиях. Мы получили архиерея грубого, похоже, малообразованного и
                испытывающего открытую неприязнь к благочестивым и просвещенным людям, которых
                в нашем городе,&nbsp; к счастью, не мало.</p>
            

            
            <p>&nbsp; Церковь учит, что всякое преодоление
                трудностей благодатно и является испытанием настоящей веры. Но в России уже
                прошли времена гонений на Церковь. Настало время созидания, возрождения духовной
                жизни общества. Поэтому мы не имеем права молчать, когда на наших глазах в
                одночасье разрушается то, что с большими трудами, любовью, сердечным горением
                было создано за последние годы.</p>
            

            
            <p>&nbsp; Архиерей имеет право осуществлять назначение
                вверенных ему священников на приходы, и мы не смеем это право оспаривать. Но
                произведенные всего за два месяца пребывания епископа&nbsp; Аркадия на томской кафедре назначения и
                перемещения священников привели томскую паству в шоковое состояние. За короткое
                время сотни прихожан оказались лишены своих пастырей, духовников. Почему новый
                Томский Епископ не заботится о прихожанах? Не преследует ли он цель держать
                своими действиями томских священников, клир, прихожан в страхе для облегчения
                своего управления? </p>
            

            
            <p>&nbsp; О. Леонид Хараим, Благочинный томских
                церквей, настоятель большого Петропавловского собора, проректор Томской
                Духовной семинарии, оказался лишен всех должностей и назначен настоятелем храма
                Петра и Павла на окраине города.</p>
            

            
            <p>&nbsp; О. Петр Васильев, духовник священства всей
                Томской Епархии, прослуживший 17 лет в Троицком храме Томска, который
                пользовался особой любовью прихожан, действиями нового епископа вынужден был
                уехать с семьей за пределы епархии.</p>
            

            
            <p>&nbsp; Та же участь постигла семью дьякона Иоанна
                Анопу, прослужившего 15 лет в троицком храме. Его супруга Татьяна и сын Дмитрий
                были известными в Томске руководителями церковных хоров.</p>
            

            
            <p>&nbsp; О. Святослав Зулин, назначенный настоятелем в
                одну из самых известных томских церквей — Воскресенскую, долгие годы не
                действовавшую, за короткое время успевший сделать немало для ее ремонта,
                создавший крепкую общину, отправлен указом нового Томского Епископа в поселок
                Моряковка.</p>
            

            
            <p>&nbsp; О. Андрей Гусев, служивший вторым священником
                в храме Петра и Павла на спичфабрике, получил назначение в северный Бакчарский
                район. В этот храм получил назначение епископа настоятель прихода Северска о.
                Тихон Смокотин.</p>
            

            
            <p>&nbsp; Непоследовательными выглядят и действия
                Епископа Аркадия по отношению к священнику Александру Классену, бывшему в
                течение пяти лет настоятелем храма Петра и Павла на спичфабрике и возродившему
                этот храм после разгрома его безбожниками в 20-е годы. О. Александру удалось
                создать большую молодежную общину и единственную в городе православную
                прогимназию.</p>
            

            
            <p>&nbsp; Решением Епископа Аркадия о. Александр
                Классен лишен должностей руководителя миссионерского отдела епархии,
                благочинного, на которые был назначен им самим, смещен с настоятельства храма
                Петра и Павла на должность второго священника. Владыка предпринял попытку
                запретить в служении этого очень популярного среди молодежи и интеллигенции
                священника.</p>
            

            
            <p>&nbsp; Молодой дьякон Роман Штаудингер, служивший в
                Воскресенской церкви и пользовавшийся особой любовью прихожан, безо всяких на
                то причин запрещен в служении и выдворен за пределы епархии.</p>
            

            
            <p>&nbsp; Нам странно слышать, что кроткий и смиренный
                о.Роман обвинен в грязной клевете на епископа.</p>
            

            
            <p>&nbsp; О. Владимир Ламзин, служивший в храме
                областной клинической больницы, переведен в Северск.</p>
            

            
            <p>&nbsp; Священник Воскресенской церкви о. Семен Гайм,
                отказавшийся подписать клеветническое письмо об о. Романе Штаудингере, который
                подвергся недопустимо вольному обращению к себе со стороны епископа, сослан им
                в северное село Александровское. Есть факты противоестественного интереса
                Владыки и к другим молодым священнослужителям и прихожанам.</p>
            

            
            <p>&nbsp; Нас смущает намерение Владыки провести
                повальный постриг в монашествующие томских семинаристов, которое уже начинает
                осуществляться. Ведь известно, что даже в монастырях людей к этому готовят
                годами.</p>
            

            
            <p>&nbsp; Грубому обращению со стороны Владыки
                подвергаются члены&nbsp; семей священнослужителей.
                Многие прихожане, да и обычные томичи, смущены личным поведением Епископа
                Аркадия, который позволяет себе грубую брань, частые пиршества и обильные
                возлияния на территории Троицкой церкви.</p>
            

            
            <p>&nbsp; Странными кажутся заявления Владыки о том,
                что в духовной жизни Томска «запущено все» и что он приехал сюда «наводить
                порядок». Вызывает серьезное беспокойство и та экстремистская настроенность
                Владыки Аркадия, с которой он приехал в наш город. Он много говорит о вреде
                тоталитарных сект и готов активно бороться с ними. Но, к счастью в Томске такие
                секты не определяют духовную жизнь. И нам кажется, ни одна тоталитарная секта
                не принесет столько вреда, сколько личное поведение Епископа. Хорошо известно,
                что проповедь монаха только тогда достигает своей цели, когда его поступки
                согласуются с его словами. Здесь мы видим совсем иное.</p>
            

            
            <p>&nbsp; Нам неприятно, что Владыка постоянно
                будоражит общественность города своими заявлениями о личных связях с сильными
                мира сего — премьером РФ В. Черномырдиным и президентом «Газпрома» Р.Вяхиревым.
                Неужели авторитетом этих уважаемых государственных людей новый томский епископ
                стремится завоевать уважение и любовь к себе томичей?</p>
            

            
            <p>&nbsp; Ваше Святейшество, мы не вправе осуждать
                православного Епископа, но нам кажется, что своими действиями на томской земле
                он наносит непоправимый урон духовной жизни города, общественной нравственности
                и авторитету Русской Православной Церкви. А потому, во имя любви к Истине, мы
                не можем молчать и оставаться безучастными к событиям, происходящим в Томской Епархии.</p>
            

            
            <p>&nbsp; Всего около 200&nbsp; подписей</p>
            

            
            <p>&nbsp; 24 декабря 1997 г.</p>
            

            
            <p>&nbsp;&nbsp; Его
                Преосвященству</p>
            

            
            <p>&nbsp;&nbsp;
                Преосвященнейшему Аркадию</p>
            

            
            <p>&nbsp;&nbsp;
                епископу Томскому и Асиновскому</p>
            

            
            <p>ОБЪЯСНИТЕЛЬНАЯ</p>
            

            
            <p>&nbsp;&nbsp; Почтительно объясняю Вашему Преосвященству,
                что поводом к разговору с Вами со стороны моего тестя, Вильгельма Генриховича
                Фаста, были следующие, смутившие меня обстоятельства.</p>
            

            
            <p>&nbsp;&nbsp; Когда я пришел к Вам, чтобы благословиться
                на поездку в Сергиев Посад для сдачи очередной сессии в МДА, Вы оказали мне
                много внимания и ласки. Сначала поругав, Вы неожиданно стали обнимать меня, и
                потребовали чтобы я поцеловал своего архиерея в уста. Затем крепко схватили за
                нос, и прижимая к себе гладили волосы, разглядывали и целовали меня в лоб. При
                этом присутствовал иеромонах Иннокентий и два семинариста, как я помню.</p>
            

            
            <p>&nbsp;&nbsp; Такой ласки мне не оказывал даже мой родной
                отец и я был несколько смущен. Естественно, рассказал это в семейном кругу.</p>
            

            
            <p>&nbsp;&nbsp; Никаких обвинений по этому поводу я Вам не
                предъявлял и не предъявляю. Мой тесть сам решил поговорить с Вами, а я в это
                время отсутствовал в Томске.</p>
            

            
            <p>&nbsp;&nbsp; Ваш смиренный послушник, </p>
            

            
            <p>&nbsp;&nbsp; диакон Роман Штаудингер</p>
            

            
            <p>&nbsp;&nbsp; 18.12.97</p>
            

            
            <p>&nbsp;&nbsp; [Написано от руки ниже:]</p>
            

            
            <p>&nbsp;&nbsp; Я готов свидетельствовать, что способ
                общения Владыки Аркадия ввел меня в смущение.</p>
            

            
            <p>&nbsp;&nbsp; р. б. Даниил Крапчунов </p>
            

            
            <p>&nbsp;&nbsp; 24.12.97 [Подпись]</p>
            

            
            <p>&nbsp;&nbsp; Его
                Преосвященству</p>
            

            
            <p>&nbsp;&nbsp;
                Преосвященнейшему Аркадию</p>
            

            
            <p>&nbsp;&nbsp;
                епископу Томскому и Асиновскому</p>
            

            
            <p>&nbsp;&nbsp; раба
                Божия Даниила Крапчунова</p>
            

            
            <p>ОБЪЯСНИТЕЛЬНАЯ ЗАПИСКА</p>
            

            
            <p>&nbsp;&nbsp; 24 декабря 1997 г. иерей Александр (Классен)
                просил меня прийти к нему домой. Он показал мне различные письма, копии указов
                и объяснительных записок, связанных с Вашим Преосвященством. После беседы о.
                Александр просил меня подписаться под некоторыми из писем, от чего я мягко
                отказался так как не совсем был согласен в их содержании. Затем о. Александр
                просил написать отдельную записку, от чего я так же отказался. Тогда о.
                Александр попросил сделать приписку под копией объяснительной записки дьякона
                Романа (Штаудингера), так как оригинал ее, по словам отца Александра, был порван,
                и она не имеет силы. Мне было предложено несколько вариантов приписки, но я
                написал следующий текст “Я готов свидетельствовать, что образ общения епископа
                Аркадия ввел меня в смущение”, так как он отражал реальное положение вещей.
                Сейчас я сожалею о том, что сделал эту приписку и, раскаиваясь, прошу у Вас
                прощения.</p>
            

            
            <p>&nbsp;&nbsp; 15.01.1998</p>
            

            
            <p>&nbsp;&nbsp; Многогрешный Даниил [Подпись]</p>
            

            
            <p>&nbsp;&nbsp; Его
                Преосвященству</p>
            

            
            <p>&nbsp;&nbsp;
                Преосвященнейшему Аркадию</p>
            

            
            <p>&nbsp;&nbsp;
                епископу Томскому и Асиновскому</p>
            

            
            <p>&nbsp;&nbsp; Смиренно прошу у Вашего Преосвященства искреннего
                прощения за то, что причинил Вам обиду. Из-за своего малолетства и неопытности
                я, действительно, не знаю как себя правильно вести перед Вами. От слов же
                первой своей объяснительной отречься, к сожалению, не могу, так как описал все
                происшедшее как помню. И если нужно подтвердить, то готов встать пред Святым
                Крестом и Святым Евангелием.</p>
            

            
            <p>&nbsp;&nbsp; Смиренный послушник, </p>
            

            
            <p>&nbsp;&nbsp; диакон Роман</p>
            

            
            <p>&nbsp;&nbsp; 18.12.97&nbsp;
                [Подпись]</p>
            

            
            <p>&nbsp;&nbsp; 18.12.97 &nbsp;&nbsp;&nbsp;&nbsp; г.
                № 200</p>
            

            
            <p>УКАЗ</p>
            

            
            <p>&nbsp;&nbsp; Настоящим, за оскорбление и грязную клевету
                в адрес Правящего Епископа, диакон Роман Штаудингер запрещается в
                священнослужении.</p>
            

            
            <p>&nbsp;&nbsp; Вход в алтарь храма строго запрещен.
                Сладость церковную вкушать как мирянину.</p>
            

            
            <p>&nbsp;&nbsp; Епископ Томский и Асиновский Аркадий</p>
            

            
            <p>&nbsp;&nbsp; 04.01.98 г. № 09</p>
            

            
            <p>УКАЗ</p>
            

            
            <p>&nbsp;&nbsp; Настоящим, за нарушение 31, 39 и 55
                Апостольских Правил иерей Александ<a name="r1" href="#1">[1]</a> Классен запрещается в
                священнослужении до глубокого покаяния.</p>
            

            
            <p>&nbsp;&nbsp; Сладость церковную вкушать как мирянину.</p>
            

            
            <p>&nbsp;&nbsp; Епископ Томский и Асиновский Аркадий</p>
            

            
            <p>&nbsp; Его
                Святейшеству,</p>
            

            
            <p>&nbsp;
                Святейшему Патриарху Московскому </p>
            

            
            <p>&nbsp; и
                всея Руси Алексию II</p>
            

            
            <p>&nbsp;
                копии: членам Священного Синода </p>
            

            
            <p>&nbsp;
                Русской Православной Церкви</p>
            

            
            <p>&nbsp; Ваше Святейшество!</p>
            

            
            <p>&nbsp; Во время Вашего посещения города Томска я
                удостоился благодатной встречи и краткой теплой беседы с Вами, Вашего
                благословения. Чрезвычайные обстоятельства заставляют меня обратиться к Вам.</p>
            

            
            <p>&nbsp; Считаю своим долгом рассказать о непростой
                ситуации, возникшей в городе Томске с приездом Владыки Аркадия. Мой личный опыт
                общения с ним позволяет мне без преувеличения сказать, что православным и
                неправославным людям города Томска, города славного своим интеллектуальным
                потенциалом, приходится иметь дело с весьма грубым епископом, не способным
                нормально общаться с людьми. Я — отец троих детей и все они пошли по пути
                служения Церкви: мой сын — священник и два моих зятя — один священник, другой
                диакон. После того как Преосвященный Аркадий позволил себе неэтичное, на мой
                взгляд, поведение по отношению к моему зятю — молодому диакону, я связался с
                Владыкой по телефону с желанием получить аудиенцию у него, чтобы как-то
                объясниться. Разговора не получилось — в ответ я услышал брань (за жизнь мою
                впервые в мой адрес было сказано: “Я не разговариваю с подонками”). Поведение
                Владыки — вызывающее и это проявилось не только по отношению ко мне. Священники
                же, по видимости, и за людей не считаются — с ними можно поступать как угодно:
                кричать, унижать, переводить с места на место, выдворять из епархии, не
                сообразуясь с обстоятельствами. Для Владыки допустимы выражения: зачуханный,
                вонючий, трухомет, поганый, шизофренические бредни. Отсутствует отеческая,
                пастырская любовь ко вверенным Владыке чадам. Господствует психический террор.</p>
            

            
            <p>&nbsp; Почтительно прошу объяснить мне — существует
                ли управа на такого правящего архиерея в нашей Церкви, или действия его должны
                оставаться совершенно бесконтрольными? Ведь возникает парадоксальная ситуация:
                если епископ кричит — должно считать, что это он просто громко говорит;
                ругается — считай это требовательностью, а любое возражение священника епископу
                рассматривается как дерзость, непочтительность и прочее.</p>
            

            
            <p>&nbsp; Зная, что в Церкви не принято обращаться в
                гражданские суды, я обращаюсь к Вам, Ваше Святейшество. Хоть Владыка Аркадий и
                епископ, полагаю однако, что и епископу не позволено грубить и унижать
                человеческое достоинство. Это не украшает лицо Церкви и для многих является
                соблазном.</p>
            

            
            <p>&nbsp; Ваш смиренный богомолец</p>
            

            
            <p>&nbsp; В. Фаст<a name="r2" href="#2">[2]</a></p>
            

            
            <p>&nbsp; 19.12.1997 </p>
            

            
            <p>&nbsp;&nbsp; Его
                Святейшеству,</p>
            

            
            <p>&nbsp;&nbsp;
                Святейшему Патриарху</p>
            

            
            <p>&nbsp;&nbsp;
                Московскому и всея Руси Алексию II</p>
            

            
            <p>&nbsp;&nbsp;
                Управляющему делами Московской Патриархии,</p>
            

            
            <p>&nbsp;&nbsp;
                архиепископу Солнечногорскому Сергию</p>
            

            
            <p>ЗАЯВЛЕНИЕ ДУХОВЕНСТВА ТОМСКОЙ ЕПАРХИИ</p>
            

            
            <p>&nbsp;&nbsp; Принято на общем собрании</p>
            

            
            <p>&nbsp;&nbsp; 11 марта 1998 года</p>
            

            
            <p>&nbsp;&nbsp; Мы, священнослужители Томской епархии,
                глубоко возмущены антицерковными действиями и поступками запрещенных клириков
                священника Александра Классена и диакона Романа Штаудингера, которые
                целенаправленно подрывают ложью и клеветой авторитет возрождающегося на Томской
                земле Православия, позорят честь и достоинство священного сана. </p>
            

            
            <p>&nbsp;&nbsp; Серьезное беспокойство вызывает и их
                нежелание осознать губительность своего пути, пренебрежение многократными
                увещеваниями и Епископа, и собратьев, что свидетельствует о глубоком духовном
                кризисе.</p>
            

            
            <p>&nbsp;&nbsp; Вся деятельность Правящего Епископа, целью
                которой является оздоровление и улучшение епархиальной жизни, вызывает у нас
                полную поддержку и понимание, о чем мы письменно свидетельствуем.
                Беспрецедентные действия о. Александра Классена и диакона Романа Штаудингера
                направлены на смену Епископа, основываются на лжи, личностных амбициях и заслуживают
                более строгих мер прещения.</p>
            

            
            <p>&nbsp;&nbsp; [Около сорока подписей]</p>
            

            
            <p>&nbsp;&nbsp; Его
                Преосвященству,</p>
            

            
            <p>&nbsp;&nbsp;
                Преосвященнейшему Аркадию,</p>
            

            
            <p>&nbsp;&nbsp;
                епископу Томскому и Асиновскому</p>
            

            
            <p>&nbsp;&nbsp;
                настоятеля церкви преподобного</p>
            

            
            <p>&nbsp;&nbsp;
                Сергия Радонежского г. Томска</p>
            

            
            <p>&nbsp;&nbsp;
                иерея Евгения Воронкова</p>
            

            
            <p>РАПОРТ</p>
            

            
            <p>&nbsp;&nbsp; Ваше Преосвященство, после подписания
                решения епархиального Совета, членом которого я являюсь, и призыва иерею
                Александру Классену и диакону Роману Штаудингеру остановиться, покаяться в
                своих неправдах, ошибках и недопустимом для клириков поведении, ко мне домой
                действительно приходили диакон Роман и его родственник иерей Михаил Фаст с
                письмом-опровержением.</p>
            

            
            <p>&nbsp;&nbsp; В беседе со мной они оспаривали
                справедливость предъявляемых к ним обвинений; требовали отчета за мое участие в
                деле решения сложившейся конфликтной ситуации; говорили что все, кто участвует
                своими подписями в деле обличения о. Александра и д. Романа — являются
                лжесвидетелями, распинателями Христа и правды; что мы теперь не можем достойно
                служить Литургию; на основании святых Отцев Церкви (преп. Симеона, Нового
                богослова), учили поступать вопреки рекомендациям личного, епархиального
                духовников и мнения духовенства Епархии по вопросу о. Александра и о. Романа.
                На мой вопрос: “какое отношение к данному вопросу имеет о. Михаил?”, последовал
                ответ: “мы все (видимо кто с ними солидарен, родственники) за одно дело”. Как я
                понял — за обличение и устранение непонравившегося епископа.</p>
            

            
            <p>&nbsp;&nbsp; Склонность вмешиваться в чисто епископские
                дела, обнаруживать недостатки в других, развивать их своим воображением до
                неузнаваемости, с выносом всего этого в средства массовой информации, нежелание
                своевременно прислушаться к советам и мнениям авторитетных священников томской
                Церкви — все это наносит раны пастырям и пастве, дает пищу хулителям Церкви,
                производит недоумения и удручающее впечатление на людей сочувствующих
                Православию.</p>
            

            
            <p>&nbsp;&nbsp; 24.03.98 г. </p>
            

            
            <p>&nbsp;&nbsp; иерей Е. Воронков</p>
            

            
            <p>&nbsp;&nbsp; 22.04.98 г. № 179-у</p>
            

            
            <p>УКАЗ</p>
            

            
            <p>&nbsp;&nbsp; Определением Нашим, по ходатайству
                Высокопреосвященнейшего Сергия, архиепископа Солнечногорского, Управляющего
                делами Московской Патриархии, запрещение в священнослужении с иерея Александра
                Классена снимается. </p>
            

            
            <p>&nbsp;&nbsp; Настоящим иерей Александр Классен
                отчисляется из клира Томской епархии (без права служения в дальнейшем на ее
                канонической территории) для продолжения пастырского служения в любой другой
                епархии Русской Православной Церкви согласно поданного прошения.</p>
            

            
            <p>&nbsp;&nbsp; О чем и выдан настоящий Указ.</p>
            

            
            <p>&nbsp;&nbsp; Епископ Томский и Асиновский Аркадий</p>
            

            
            <p>&nbsp;&nbsp; 22.04.98 г. № 180-у</p>
            

            
            <p>УКАЗ</p>
            

            
            <p>&nbsp;&nbsp; Определением Нашим, по ходатайству
                Высокопреосвященного Сергия, Архиепископа Солнечногорского, Управляющего делами
                Московской Патриархии, запрещение в священнослужении с диакона Романа
                Штаудингера снимается. </p>
            

            
            <p>&nbsp;&nbsp; Настоящим диакон Роман Штаудингер
                отчисляется из клира Томской епархии (без права служения в дальнейшем на ее
                канонической территории) для продолжения священнослужения в любой другой
                епархии Русской Православной Церкви согласно поданного прошения.</p>
            

            
            <p>&nbsp;&nbsp; О чем и выдан настоящий Указ.</p>
            

            
            <p>&nbsp;&nbsp; Епископ Томский и Асиновский Аркадий</p>
            

            
            <p>&nbsp;ИЗВЕСТИЯ ИЗ ТОМСКОЙ ЕПАРХИИ</p>
            

            
            <p>“Православная
                община” № 46</p>
            

            
            <p>&nbsp; Как уже известно, по ходатайству Управляющего
                делами Московской Патриархии архиепископа Солнечногорского Сергия епископом
                Томским и Асиновским Аркадием со священника Александра Классена и диакона
                Романа Штаудингера были сняты наложенные им запрещения в священнослужении (при
                этом с запретом продолжать служение на территории Томской епархии)<a name="r3" href="#3">[3]</a>. Теперь руководство епархии настаивает, под угрозой новых прещений, на срочном выезде этих клириков
                из Томской области, а также на прекращении освещения происходящего в СМИ.
                Клирики подчинились этому: о. Александр перешел в Новосибирскую епархию, а
                диакон Роман — в Красноярскую. Публикуем соответствующие документы.</p>
            

            
            <p>&nbsp;&nbsp; от 03.07.98</p>
            

            
            <p>&nbsp;&nbsp; Диакону Роману Штаудингеру</p>
            

            
            <p>&nbsp;&nbsp; Епархиальный совет считает необходимым
                предупредить Вас о необходимости скорейшего поступления в клир выбранной Вами
                епархии для продолжения церковной жизни и литургического общения.</p>
            

            
            <p>&nbsp;&nbsp; Ваше дальнейшее пребывание на канонической
                территории Томской епархии, посещение храмов областного центра вносит смущение
                в среду верующих и провоцирует всевозможные кривотолки. Потому ради мира
                церковного и спокойствия рекомендуем Вам ускорить решение вопроса о месте
                Вашего дальнейшего служения.</p>
            

            
            <p>&nbsp; &nbsp;Московская Патриархия пребывает в недоумении
                по поводу Вашего столь продолжительного неустройства.</p>
            

            
            <p>&nbsp;&nbsp; В противном случае к Вам будут предприняты
                меры церковного прещения.</p>
            

            
            <p>&nbsp;&nbsp; Протоиерей Николай Яськов, </p>
            

            
            <p>&nbsp;&nbsp; протоиерей Богдан Бида, </p>
            

            
            <p>&nbsp;&nbsp; протоиерей Олег Безруких, </p>
            

            
            <p>&nbsp;&nbsp; иерей Евгений Воронков, </p>
            

            
            <p>&nbsp;&nbsp; иерей Александр Атаманов, </p>
            

            
            <p>&nbsp;&nbsp; священноинок иерей Сергий Гимбатов,</p>
            

            
            <p>&nbsp;&nbsp; священноинок иерей Иоанн Свистелин, </p>
            

            
            <p>&nbsp;&nbsp; протодиакон Владимир Марков, </p>
            

            
            <p>&nbsp;&nbsp; священноинок иерей Варфоломей Егоров, </p>
            

            
            <p>&nbsp;&nbsp; иерей Святослав Зулин.</p>
            

            
            <p>&nbsp;&nbsp; 03.07.98</p>
            

            
            <p>&nbsp;&nbsp; Фасту Вильгельму Генриховичу</p>
            

            
            <p>&nbsp;&nbsp; Сегодня, 3 июля 1998 года, Епархиальный
                совет имел суждение о Вашей статье в газете “Томский вестник” от 30 июня 1998
                года “На епископа можно жаловаться”. Изложенное Вами видение внутрицерковной жизни
                Томской епархии и ее архиерея, методично навязываемое верующим и томичам через
                средства массовой информации, вызывает не только возмущение, но и вынуждает нас
                обратиться непосредственно к Вам со словами увещевания и предупреждения.</p>
            

            
            <p>&nbsp;&nbsp; Учитывая неоднократные удары с Вашей стороны
                по авторитету Православия на Томской земле, умышленную дестабилизацию и
                возмущение церковного мира, распространение клеветы на правящего архиерея и
                ложной информации о епархиальной жизни, напоминаем Вам об ответственности перед
                Богом и личной совестью. Призываем Вас, без лишней амбициозности, внимательно
                пересмотреть свою позицию, осознать ее греховность и пагубность, и принести
                покаяние перед епископом и смущенной Вами томской паствой.</p>
            

            
            <p>&nbsp;&nbsp; В противном случае, при повторении подобных
                фальсифицированных публикаций, Епархиальный совет обратится к Правящему
                епископу с настоятельной просьбой отлучить Вас от церковного общения.</p>
            

            
            <p>&nbsp;&nbsp; (Те же подписи)<br/></p>
            

            
            <hr class="wp-block-separator"/>
            

            
            <p><a name="1" href="#r1">[1]</a> Так в тексте</p>
            

            
            <p><a name="2" href="#r2">[2]</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; В. Фаст — известный правозащитник, председатель
                Томской областной комиссии по реабилитации жертв политических репрессий, тесть этих
                священнослужителей.</p>
            

            
            <p><a name="3" href="#r3">[3]</a> См. Никита
                Струве.<em> О “самопреследовании” в Русской церкви</em>, “Вестник РСХД” № 177 (I-II
                — 1998), с. 257, а также “Православная община” № 43, (№1 за 1998 г.), с. 93-96,
                и № 45 (№3 за 1998 г.), с. 115-123.</p>
            
        </div>
    );
};

export default Tomsk;
