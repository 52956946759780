import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {Link} from "react-router-dom";
import './GroupOrientation.css';
import {
    createTheme,
    ThemeProvider,
    alpha,
    getContrastRatio,
} from '@mui/material/styles';

// Augment the palette to include a violet color

// Update the Button's color options to include a violet option

const violetBase = '#1976d2';
const violetMain = alpha(violetBase, 0.7);

const theme = createTheme({
    palette: {
        violet: {
            main: violetMain,
            light: '#42a5f5',
            dark: '#1565c0',
            contrastText: getContrastRatio(violetMain, '#74d266') > 4.5 ? '#2555bb' : '#111',
        },
    },
});
// const theme = createTheme({
//     palette: {
//         violet: {
//             main: 'rgba(24,52,52,0.2)',
//             light: alpha(violetBase, 0.5),
//             dark: alpha(violetBase, 0.9),
//             contrastText: getContrastRatio(violetMain, '#74d266') > 4.5 ? '#2555bb' : '#111',
//         },
//     },
// });
const themeSalmon = createTheme(theme, {
    // Custom colors created with augmentColor go here
    palette: {
        salmon: theme.palette.augmentColor({
            color: {
                main: 'rgba(255,51,82,0.71)',
            },
            name: 'salmon',
        }),
    },
});
const showDetails = () => {
    return [
        <Link to="/nachalo/"><Button color="violet" key="key1" className="Menu-button" variant="contained" onClick={()=>{window.location="/nachalo/"}}>Введение</Button></Link>,
        <Link to="/zaostrov/"><Button color="violet" key="key2" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/zaostrov/"}}>Дело священника Георгия Кочеткова и общины храма Успения Пресвятой Богородицы в Печатниках</Button></Link>,
        // <Link to="/zaos1/"><Button color="violet" key="key3" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/zaos1/"}}>Часть 1. «Дай вам Бог крепнуть, углубляться, расти и светить всем вокруг вас»</Button></Link>,
        // <Link to="/zaos2/"><Button color="violet" key="key4" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/zaos2/"}}>Часть 2. «Вы — жертва, может быть, и невинная…»</Button></Link>,
        // <Link to="/zaos3/"><Button color="violet" key="key5" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos3/"}}>Верните нашего брата – о. Павла!</Button></Link>,
        // <Link to="/zaos4/"><Button color="violet" key="key6" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos4/"}}>Часть 4. 29 июня: говорят документы</Button></Link>,
        // <Link to="/zaos5/"><Button color="violet" key="key7" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos5/"}}>Часть 5. «Радонеж» начинает и выигрывает?</Button></Link>,
        // <Link to="/zaos6/"><Button color="violet" key="key8" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos6/"}}>Часть 6: Нет ничего тайного, что не было бы узнано</Button></Link>,
        // <Link to="/zaos7/"><Button color="violet" key="key9" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos7/"}}>Часть 7. Комиссия. Июль— октябрь 1997 года</Button></Link>,
        // <Link to="/zaos8/"><Button color="violet" key="key10" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos8/"}}>Глава 8. Что же дальше?</Button></Link>,
        <Link to="/non-licet/"><Button color="violet" key="key11" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/non-licet/"}}>«Дело» архимандрита Зинона</Button></Link>,
        <Link to="/fatheradelgeim/"><Button color="violet" key="key12" className="Menu-button" variant="contained" onClick={()=>{window.location="/fatheradelgeim/"}}>«Херсонское дело»</Button></Link>,
        <Link to="/chronologyadelgeim/"><Button color="violet" key="key13" className="Menu-button" variant="contained" onClick={()=>{window.location="/chronologyadelgeim/"}}>«Томское дело»</Button></Link>,
        <Link to="/strugglechurch/"><Button color="violet" key="key14" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/strugglechurch/"}}>«Екатеринбургское дело»</Button></Link>,
        <Link to="/tver/"><Button color="violet" key="key15" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/tver/"}}>«Дело» игумена Мартирия (Багина) и храма Всех святых на Кулишках</Button></Link>,
        <Link to="/krasnousolsk/"><Button color="violet" key="key16" className="Menu-button" variant="contained" onClick={()=>{window.location="/krasnousolsk/"}}>Кто следующий?</Button></Link>,
        <Link to="/nizhnevartovsk/"><Button color="violet" key="key17" className="Menu-button" variant="contained" onClick={()=>{window.location="/nizhnevartovsk/"}}>Заключение</Button></Link>,
        <Link to="/pril/"><Button color="violet" key="key18" className="Menu-button" variant="contained" onClick={()=>{window.location="/pril/"}}>Приложение</Button></Link>
    ];
}
export default function GroupOrientation() {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column',justifyContent: 'space-around',alignItems: 'stretch' }}>
            <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                <ThemeProvider theme={themeSalmon} >
                {showDetails()}
                </ThemeProvider>
            </ButtonGroup>
        </Box>
    );
}
