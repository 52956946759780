import React from "react";
import './text.css';
const Zaos3 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Часть 3. 29 июня: говорят очевидцы</h2>
            

            <p className="has-text-align-right"><em>&nbsp; “Я глубоко убежден в том, что имеют право говорить только
                очевидцы. Притом, чем менее рассказы их были бы похожи на связный рассказ и чем больше они строились бы
                так: “я стоял вот в этом углу и видел своими глазами то-то и то-то и слышал своими ушами то-то и то-то”,
                — тем эти рассказы для меня лично были бы достовернее и убедительнее. Все остальные лица могут сверять
                рассказы очевидцев, подвергать их критическому анализу, приходить к каким-то выводам, но это уже совсем
                другое дело.”</em></p>
            

            <p className="has-text-align-right">&nbsp;<em> (Отрывок из выступления акад. С.С. Аверинцева&nbsp; на
                радиостанции “София” 13.09.97 г.)</em></p>
            

            
            <p>&nbsp;<strong> СВИДЕТЕЛЬСТВА ПРИХОЖАН</strong></p>
            

            
            <p>&nbsp;<strong><em> Отрывки взяты из свидетельств, посланных прихожанами храма Успения в Печатниках
                патриарху и в комиссию по расследованию инцидента 29 июня. Здесь приводятся лишь небольшие отрывки
                небольшой части свидетельств. </em></strong></p>
            

            
            <p> Cвидетельствуем о том, чему мы — регент и певчие правого хора — в известных событиях, происшедших
                в нашем храме 29 июня с.г., были очевидцами. Около 7 часов утра, по расписанию, служащий иерей
                    Михаил начал утреню. Течение ее было обыкновенным до тех пор, пока назначенный помогать служащему
                    иерею настоятель не подошел к елеопомазанию (во время чтения седальнов по 3-й песни канона и
                    ектеньи) и, как принято среди священства, принял елеопомазание не рукою служащего иерея, а своею
                    собственной. После этого и начались странности. По катавасии 6-й песни канона, когда давно
                    закончивший елеопомазание иерей Михаил, находившийся в это время между центром храма и выходом в
                    притвор (на месте, где он прежде читал Евангелие), не произнес положенного “Паки и паки...”, хотя
                    ему и подсказали несколько раз (чтец, алтарник, пом.старосты, регент), предположив причиной его
                    молчания рассеянность. Прождав некоторое время напрасно, мы решили продолжать и спели кондак, после
                    которого чтец сказал икос, — и дальше утреня пошла своим чередом. Пока, наконец, не достигла
                    катавасии по 8-й песни. На этом месте Богослужения священник, по чину, выходит на солею и кадит
                    иконе Богородицы, по окончании же катавасии возглашает “Богородицу и Матерь Света в песнех
                    возвеличим!”, после чего хор поет Песнь Богородицы, положенную этому дню. Таково уставное
                    последование.&nbsp; В нашем же случае никакого священнического появления на солее и никакого
                    священнического возгласа, предваряющего “Честнейшую”, не было. После некоторого замешательства, мы
                    обратили взоры от пустой солеи к настоятелю, принимавшему в это время исповедь в Никольском приделе,
                    и настоятель благословил&nbsp; петь “Честнейшую”. (Это представляется нормальным, так как правило
                    безостановочности Богослужения стремятся соблюдать все священники. От чего бы ни произошла заминка,
                    ее необходимо покрыть, а разбирательство устраивается обычно по окончании службы.) Примерно на 3-м
                    стихе “Честнейшей” с солеи раздалось чтение: о. Михаил, лицом к алтарю, читал каноны. Мы же, допев
                    “Честнейшую”, остановились, недоумевая, каким чином служить дальше. О. Георгий обратился к иерею
                    Михаилу с просьбою продолжать нормальное чинопоследование, на что ему был ответ, что так читать
                    каноны (как?) — кощунство. Что иереем Михаилом имелось в виду, — неясно. Манера ли чтения, или то,
                    что чтецы вычитывали по одному тропарю из каждого канона? — он не объяснил. Но так или иначе,
                    начинать заново читать каноны не только после пения “Честнейшую”, но и, вообще говоря, после самим
                    же произнесенной ектеньи по 3-й песни, — непоследовательность изумительная и действительное
                    разрушение Богослужения. Т.е. то, что читалость и пелось и о чем молились до сих пор, был черновик,
                    “а теперь еще раз с той же цифры”. Богослужение вместе со встроенной репетицией Богослужения?
                    Естественно, что в ответ на отказ вернуться к&nbsp; нормальному чинопоследованию о. Георгий был
                    вынужден потребовать у иерея Михаила отдать алтарникам богослужебные книги, и после отказа какие-то
                    книги были отняты. (“Отняты” — это надо понимать буквально; ничего кроме изъятия — из рук в руки —
                    не было). За сим иерей Михаил в полном облачении пошел к выходу из храма, староста же и старший
                    алтарник побежали за ним с просьбой не ходить в облачении на улицу, а если ему хочется на улицу, то
                    вернуть облачение. Оба призыва остались без ответа; однако помощник старосты успел запереть наружную
                    дверь. Иерей Михаил вынужден был оставаться внутри храма — и продолжил свое удивительное чтение в
                    притворе. Мы с клироса не могли разобрать читаемого и петого им, так как отец настоятель, оставив
                    исповедующихся, был вынужден сам заканчивать утреню, — но можем засвидетельствовать, что пение и
                    чтение из притвора были слышны довольно долго: оставшийся чин&nbsp; утрени мы дослуживали на их
                    фоне. Иерей Михаил перемещался из притвора в храм, ходил в южном приделе, выходил снова в притвор,
                    пытался читать по Минее, лежавшей на аналое для пользования чтецов (и ее тоже были вынуждены
                    отнять); повсюду его сопровождала небольшая свита из братьев и сестер, пытавшихся его словесно
                    успокоить — именно что только “сопровождала” и только “словесно”.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Виноградова,
                З.Дашевская</p>
            

            
            
            

            
            <p> Одна сестра плакала, прося и говоря, что ему будет потом стыдно за себя и перед своими детьми за все,
                что он делает, но он отодвигал шторку на двери (имеется в виду занавеска на стекле наружной двери
                Успенского храма — ред.) (ожидая подмоги какой-то, я тогда не понимала, чего он ждал и кого?) и
                улыбался.</p>
            

           
            <p className="has-text-align-right">&nbsp; Л.Хвастницкая</p>
            

            
            
            

            
            <p> Несколько сестер окружило о. Михаила, прося его помолиться вместе с нами, он же бросился в притвор.
                Алтарник Виктор Котт говорил ему: “Снимите облачение, батюшка, и можете идти ... на улицу в облачении
                нельзя”. Говорил просительно, тихо, больше уговаривал, чем говорил. Когда кто-то из присутствующих
                присоединился к этой просьбе, дотронувшись пальцем до фелони, священник Михаил Дубовицкий резко,
                надрывно произнес: “Уберите руки! не насилуйте!” — и после, когда эта ситуация повторилась: “Руки!
                Прочь! Не насилуйте!” Мои слова были таковы: “Батюшка! Вы сейчас пасете самого себя, как у пророка
                Иеремии говорится”, “Батюшка! помолимся вместе!” — “Батюшка! За что Вы нас так не любите?” — потому что
                он произносил страшные вещи: “Вы не христиане. Гореть вам в геенне огненной”. Запомнились слова Виктора
                Котта: “Батюшка! Что это Вы говорите? Грех Вам соблазнять малых сих. Зачем Вы предвосхищаете Суд
                Небесный?”<br/> В это время вошедшая с улицы прихожанка о. Михаила спросила в тревоге: “Батюшка, чем Вам
                    помочь?” — и получила в ответ: “Вызовите милицию”.</p>
            

           
            <p className="has-text-align-right">&nbsp; Т.Тябут</p>
            

            
            
            

            
            <p>Перед тем, как удалиться в алтарь, о. Михаил послал приходящих именно к нему на службу прихожанок в
                Сретенский монастырь за подмогой и попросил вызвать милицию. Подмога и милиция не заставили себя ждать.
                О. Михаил, находясь в алтаре, время от времени издавал возгласы примерно такие: “Есть ли православные в
                храме?” “Православные! Надо мной совершают насилие!”</p>
            

           
            <p className="has-text-align-right">&nbsp; В.Кулыгин</p>
            

            
            
            

            
            <p>В праздничный день 29 июня я с 9 часов 50 минут находился в храме Успения Пресвятой Богородицы в
                Печатниках. Готовясь к Божественной литургии, я молился вблизи солеи перед иконой Господа Вседержителя.
                Когда из алтаря вышел о. Михаил Дубовицкий, его поведение мне показалось странным. Он был возбужден:
                красные воспаленные глаза, резкие движения при ходьбе чередовались с растерянной неподвижностью. О.
                Михаил прошел в правый придел, открыл книгу и начал читать, что именно он читал, я разобрать не смог.
                Голос его возбужденно дрожал. Это продолжалось не более полминуты, после чего о. Михаил вернулся в
                алтарь. К народу он больше не выходил.<br/> Я стоял вблизи Царских Врат и хорошо слышал, как в алтаре о.
                    Михаил сбивчиво читал какой-то богослужебный текст. Служба начаться не могла. Было ясно, что с о.
                    Михаилом что-то не в порядке.<br/> Я молился за о. Михаила, просил Господа милости к молодому
                        священнику. Неожиданно из алтаря прозвучал голос о. Михаила: “Православные,
                        помогите!”<br/> Никаких звуков, которые говорили бы о возможном насилии, я не слышал. Интонация
                            возгласа была такой же, какой он только что произносил текст. У меня было чувство, что
                            никакая реальная опасность ему не угрожает.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Белоус</p>
            

            
            
            

            
            <p>Из алтаря слышались выкрики о. Михаила. Вскоре появились милиционеры, и богомольцы, которые часто
                приходили в храм во время служения о. Михаила, стали требовать от настоятеля о. Георгия допустить
                милиционера в алтарь.<br/> Я стояла и молилась у самой солеи, справа от меня стояли две женщины, которые
                    стали говорить милиционеру, чтобы о. Михаил вышел к ним, так как они хотели отвести его к иг.
                    Тихону. Милиционер повторил, что о. Михаила никто не держит. Тогда они стали говорить, что хотят
                    исповедоваться о. Михаилу. Милиционер ответил: “Вы верующие? Как он может исповедовать в таком
                    состоянии?”<br/> Я была между милиционером и этими женщинами и стала свидетелем этого разговора.
                        Крики о. Михаила усилились, и весь храм коленопреклоненно стал молиться.</p>
            

           
            <p className="has-text-align-right">&nbsp; Р.Куркова</p>
            

            
            
            

            
            <p> Я видела, как из алтаря вышел милиционер и обратился к находившимся в храме людям, говоря, что насилия в
                алтаре по отношению к этому человеку (о. Михаилу) не совершается, что ему (о. Михаилу) сказали снять
                церковное облачение и идти куда он хочет, но ваш священник (о. Михаил) лежит на полу, кричит и никого не
                слушает.<br/> Для поддержания порядка и относительного спокойствия в храме (т. к. все это время, 30-40
                    минут, о. Михаил продолжал кричать), милиционера попросили остаться возле входа в алтарь.</p>
            

           
            <p className="has-text-align-right">&nbsp; Ю.С.Степанова</p>
            

            
            
            

            
            <p> Подошел к солее высокий худощавый человек в рясе с настойчивым намерением пройти в алтарь, куда его не
                пускали алтарники&nbsp; без благословения настоятеля.<br/> Вышедший из алтаря дьякон на вопрос гостя, что
                    там происходит, ответил спокойно: “Ничего. Он (о. Михаил) лежит на полу и кричит, — не подходите ко
                    мне, не трогайте меня,— а около него никого нет.”<br/> Подошел о. Георгий, прервав исповедь, и стал
                        объяснять пришедшему: “в алтарь входить я Вас не благословляю, так как Вы человек не
                        бесстрастный и Ваше присутствие может вновь возбудить о. Михаила.” Гость сказал: “Я хочу видеть,
                        что происходит с о. Михаилом”. О. Георгий объяснил, что в алтаре находится милиционер, что был
                        врач, а какой он поставил диагноз, Вы знаете, но мы не можем допустить, чтобы священника
                        госпитализировали прямо из алтаря. Подождем еще. О. Георгий вернулся в Никольский придел. Из
                        алтаря вышел милиционер. Гость переспросил и его, что там происходит. Милиционер бесстрастно
                        ответил: “Ничего. Его никто не трогает. Похоже, что он не в себе.”<br/> Гость спросил
                            милиционера: “Отвечаете ли Вы за свои слова?” Милиционер ответил: “Я на службе и отвечаю за
                            свои слова. Ничего противозаконного там не происходит.” Я не помню знаков воинского отличия
                            милиционера, но я очень хорошо запомнила его лицо, так как вплотную стояла с гостем перед
                            милиционером.<br/> Женщины из группы поддержки о. Михаила закричали: “отпустите же его,
                                покажите его.” Милиционер ответил: “Его никто не держит. Его просят снять богослужебные
                                одежды. Ведь он не может выйти на улицу в них.”</p>
            

           
            <p className="has-text-align-right">&nbsp;&nbsp; И.Упит</p>
            

            
            
            

            
            <p>Войдя в храм в 9.30 утра, я сразу заметил милиционера, стоящего у солеи. Позже я узнал, что незадолго до
                моего прихода он сообщил прихожанам храма, что, по его мнению, священник Михаил Дубовицкий не в себе.
                Тем временем я услышал, как из алтаря раздаются возгласы о. Михаила о том, что его бьют. Я вошел в
                алтарь и увидел, что о. Михаил не подвергается никакому насилию, но, тем не менее, время от времени
                громко выкрикивает, что его бьют. Настоятель храма о. Георгий Кочетков между тем заканчивал принимать
                исповедь. Я вышел из алтаря, чтобы принести видеопленку, и когда вернулся вновь, в алтаре уже был врач.
                При этом о. Михаил лежал на полу лицом вниз и на все уговоры врача встать никак не реагировал. Через
                некоторое время я опять вышел из алтаря, а когда вернулся, о. Михаил стоял, положив руки на стол и
                уткнувшись в них лицом. Рядом с ним находились фельдшер и милиционер. Подошедший о. Георгий, обращаясь к
                ним, сказал, что он встречается с такой ситуацией впервые, и спросил у них, как у людей более опытных,
                что в таких случаях делают. Они молчали. Вдруг о. Михаил захотел помянуть родных и двинулся к
                жертвеннику, а когда ему преградили путь, закричал: “уберите от меня руки, помогите, бьют!”, — на что
                стоящий рядом милиционер заметил: “Батюшка, Вас здесь никто не трогает, не устраивайте
                демонстрацию”.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Чукавин</p>
            

            
            
            

            
            <p>Выйдя из алтаря на солею, ст. лейтенант объявил собравшимся в храме людям (а их уже было человек 500),
                что батюшка немного не в себе, что сейчас, как только снимет облачение, сразу сможет идти куда захочет.
                При этом о. Михаил мог спокойно выйти из алтаря, ему никто в этом не препятствовал, но он этого не
                сделал, а наоборот, ушел в дальний угол заалтарной комнаты и там остался. Оставшись там, он периодически
                продолжал кричать, тем самым провоцируя людей, находящихся в храме, на волнение, потом лег на пол,
                продолжая кричать. В храме при каждом крике кто-то из людей, пришедших из Сретенского монастыря, пытался
                ворваться в алтарь. </p>
            

           
            <p className="has-text-align-right">&nbsp; Р.Соколинский</p>
            

            
            
            

            
            <p>Все прихожане собрались около солеи, было несколько сот человек. Я увидела двух молодых мужчин, которые
                вели себя, как спортсмены перед началом тренировки, как будто они разминаются, и нервно
                переговаривались, что им делать: “Наших-то — мало!” Я отошла к свечному ящику в южном приделе храма. О.
                Михаил закричал очень громко и ему ответили женские голоса: “Мы здесь, о. Михаил, мы с Вами!” </p>
            

           
            <p className="has-text-align-right">&nbsp; М.Патрушева</p>
            

            
            
            

            
            <p>В алтаре было темно и очень тихо, создавалось впечатление, что о. Михаил там один, но раза 2-3 из алтаря
                выходили алтарники, мирные и очень собранные. В непосредственной близости от солеи бушевала “группа
                поддержки“ о. Михаила, призванная радиостанцией “Радонеж”. Они кричали: “О. Михаил, мы с Вами!” Когда
                крики о. Михаила затихали, то казалось, что в храме ничего не происходит, если бы не напряжение,
                повисшее в воздухе. О. Георгий исповедовал, чтецы читали часы, народ молился. Лишь в левой
                    стороне у дьяконских врат все время что-то происходило. Приходили представители из Сретенского
                    монастыря, просясь в алтарь, женщины из группы поддержки о. Михаила громко кричали, что их
                    священника бьют, а может, и убивают, но из чего были сделаны такие выводы, было неясно. Но если
                    вначале я слышала из алтаря лишь неоформленные вопли, после криков женщин, соратниц о. Михаила,
                    крики оформились в фразы: “Православные, убивают!” Эти завывающие вопли произносились с некоторой
                    периодичностью. Могу засвидетельствовать мое впечатление, что ни на секунду не было ощущения
                    истинности этих слов, лишь жалость и боль к больному человеку, выполняющему чей-то страшный замысел.
                    Еще 15—20 минут я находилась в правой части у солеи, а потом поднялась на место служения, на хоры. С
                    хоров было видно, что какой-то человек, окруженный женщинами, кричал: “Изверги, они его убивают!”, и
                    на очередной вопль о. Михаила бросился к дьяконским вратам алтаря, но был оттеснен прихожанами храма
                    Успения. Из алтаря вышел милиционер и вывел из храма нарушителя порядка и его поддерживающих,
                    которые назвали себя духовными чадами о. Михаила.</p>
            

           
            <p className="has-text-align-right">&nbsp; М.Голдина</p>
            

            
            
            

            
            <p>О том, что эти люди были приглашены на воскресенье священником Михаилом, я узнала несколько позже, когда
                одну из его прихожанок вывели из храма за недостойное поведение в храме. Выйдя на минуту из храма, (мне
                стало нехорошо), я увидела возбужденно кричавшую эту прихожанку (по-моему, ее звали Вита) в синем
                джинсовом платье. Она кричала: “Еретики, звери !”, — явно привлекая внимание прохожих. Я пыталась
                успокоить ее и спросила, зачем же она пришла сегодня на службу, зная, что по расписанию сегодня служит
                о. Георгий. Она на это мне ответила, что им велел прийти о. Михаил.</p>
            

           
            <p className="has-text-align-right">&nbsp; Т.Н.Юргенева</p>
            

            
            
            

            
            <p>Около 10 часов, перед началом литургии, когда храм был заполнен прихожанами (порядка 500 человек), некий
                брат в бордовой рубашке на очередной крик отца Михаила, расталкивая всех локтями, активно ринулся в
                алтарь, а когда ему преградили дорогу, также активно пустил в ход кулаки. На помощь пришел лейтенант
                милиции, он вывел из храма по-хулигански ведшего себя брата. Хотя вскоре он опять появился в храме, а
                когда прихожане чистосердечно призвали его молиться, что мы все и делали, то этот брат вдруг неистово
                возопил: “Не приближайтесь ко мне, нечистые!” — вложив в свой крик столько ненависти, что выражение его
                лица перестало быть человеческим. </p>
            

           
            <p className="has-text-align-right">&nbsp; М.Е.Викулина</p>
            

            
            
            

            
            <p>В это время наш хор запел “Царице моя Преблагая...” и весь храм, поддержав нас, стал молиться. Через
                окна, с балкона, было видно, что подъехала машина скорой помощи.</p>
            

           
            <p className="has-text-align-right">&nbsp; М.Голдина</p>
            

            
            
            

            
            <p>Приехали врач и фельдшер “Скорой помощи”. Врач несколько раз предлагал о. Михаилу поговорить с ним одному
                (в ризнице), тот резко ответил: “Нет”. Затем о. Михаил вдруг резко повернулся и хотел выйти в храм.
                Алтарники (в том числе и я) остановили его, прося выполнить указание настоятеля — в таком состоянии в
                облачении (он был в поручах) в храм не выходить. Тогда он начал кричать очень странно, почти нараспев:
                “Православные, помогите! Меня бьют! Не дают подойти к престолу! И к жертвеннику!” и т.п. Потом он вдруг
                сказал, чтобы его пропустили исповедовать. Подошедший о. Георгий вновь сказал ему, что в таком состоянии
                это невозможно, и что он (о. Михаил) предварительно должен успокоиться и передохнуть хотя бы 10 минут.
                Тот, не слушая и не отвечая никому ( его просили не нагнетать обстановку также врач и милиционер), стал
                с криком буквально рваться в храм. Затем, вынув из кармана подрясника маленький Новый Завет и громко дав
                предварительный богослужебный возглас о чтении Евангелия, стал читать нараспев текст о страстях
                Господних. Потом он попытался снять икону с аналоя и поставить перед собой (опять с криком: “Мне не дают
                икону!”), вновь пройти в храм и т.п. Все это продолжалось довольно долго, не меньше 10—15 минут, и
                производило впечатление крайне тяжелое и странное, так как он при этом никого не слушал и не вступал в
                разговор. Все были в растерянности. Никаких побоев ни один человек о. Михаилу не наносил. В
                    храме начался шум и крик. Понимая, что если в такой ситуации пропустить о. Михаила в храм, начнется
                    (как мы предполагали по горькому двухмесячному опыту) бесконтрольное развитие конфликта, мы
                    обратились за консультацией к врачу и работникам милиции. Врач сказал, что о. Михаил болен, ему
                    нужна срочная госпитализация, но добровольно он в больницу не поедет.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.М.Копировский</p>
            

            
            <p>&nbsp; &nbsp;</p>
            

            
            <p>Появились иеромонахи из Сретенского монастыря: о. Никандр и о. Анастасий. Нельзя сказать, чтобы они
                пришли с молитвой. О. Георгий выходил увещевать их. В алтарь их не пустили,&nbsp; так как люди из
                Сретенского монастыря, к сожалению, ведут против нас давнюю войну и вряд ли их мнение может быть
                беспристрастным, а их свидетельство — истинным. Кажется, еще до появления монахов была вызвана
                    карета “Скорой помощи”. Врачи находились вблизи событий довольно долго, не предпринимая, кажется,
                    никаких специальных действий, кроме визуального наблюдения за о. Михаилом и постановки
                    диагноза. Врачи находились в храме не менее получаса. Так как отец настоятель не
                        благословил пускать в алтарь клириков Сретенского монастыря, а последние непременно хотели в
                        алтарь проникнуть, то дьяконские врата тщательно охранялись братьями. Царские были закрыты,
                        заперты на задвижку, и завеса была задернута. Этого казалось достаточным, и за Царскими вратами
                        никто не наблюдал. Никто не ожидал, что о. Никандр вдруг стремглав ринется в алтарь именно через
                        Царские врата. Шум, замешательство, и когда мы с клироса туда взглянули, то увидели лишь
                        распахнутые настежь врата и содранную завесу. Кажется, он в ней запутался. Если иеромонах в
                        мантии и епитрахили имеет право входить в Царские врата, то вряд ли он имеет право входить в них
                        таким скоропостижным и разрушительным образом.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Виноградова,
                З.Дашевская</p>
            

            
            
            

            
            <p>Я вошел в алтарь храма около десяти часов утра, увидел печальные, взволнованные лица о. Георгия Кочеткова
                и наших алтарников, находившихся в юго-восточной части алтаря рядом с о. Михаилом Дубовицким, который
                стоял облокотившись на стол для облачений. Здесь же присутствовал старший лейтенант милиции. Я прошел в
                ризницу, где за столом сидел пожилой врач скорой помощи и что-то писал. Внезапно о. Михаил стал
                чрезвычайно громко и нервно читать Евангелие в русском переводе, встав напротив образа Спаса
                Нерукотворного. Затем обернулся и прокричал: “Премудрость прости! Услышим Святаго Евангелия ...” и
                прочее, положенное перед чтением этого текста за богослужением, и вновь, обернувшись к иконе, стал
                читать евангельский текст. Было слышно, что в храме народ очень волнуется. На вопросы “Что же делать?”
                доктор скорой помощи сказал, что о. Михаила нужно срочно госпитализировать, но здесь же отметил: “Но вы
                же понимаете, добровольно он не пойдет”, и просил решить, согласны ли мы на принудительную
                госпитализацию. Последовала пауза, во время которой находившиеся в алтаре совещались о возможности
                принудительной госпитализации, а о. Михаил продолжал все более истерично читать Евангелие. Обстановка в
                храме накалялась и грозила выйти из-под контроля. Тогда было позволено врачам действовать так, как
                полезнее для здоровья о. Михаила. Мы взяли его под руки и вывели его во двор храма, где и посадили в
                машину скорой помощи. </p>
            

           
            <p className="has-text-align-right">&nbsp; Д.С.Гасак</p>
            

            
            
            

            
            <p>Я не точно помню в какой последовательности все происходило. Кажется тогда, через закрытые Царские Врата
                ворвался в алтарь какой-то монах. Как я потом узнала, иеромонах Никандр. Все содрогнулись, но продолжали
                молиться. Через несколько минут он выбежал, вытянув два пальца вверх и кричал что-то вроде: “Вот теперь
                вам конец!”</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Орлова</p>
            

            
            
            

            
            <p>Он (иеромонах Никандр) пошел к выходу, ведя за собой прихожан монастыря и подняв грозно руку с угрозами:
                “Вот теперь вам конец!”&nbsp;В храме стояла полная тишина, к этому времени (было уже около 11
                    часов) храм был полон. Все люди встали на колени и молились со слезами об о. Михаиле, о мире и
                    спокойствии.</p>
            

           
            <p className="has-text-align-right">&nbsp; О.Лаврентьева</p>
            

            
            
            

            
            <p>О. Никандр выбежал из алтаря уже через северные врата с возгласом: “Православные, там избивают о.
                Михаила” и вместе с несколькими прихожанами Владимирского собора Сретенского монастыря быстро направился
                к воротам, ведущим во двор храма. Судя по тому, что о. Никандр так недолго был в алтаре, о. Михаила там
                уже не было, и слова об избиении не соответствовали действительности. Вслед за ними сюда же собралось
                несколько десятков прихожан. Возгласов и криков о помощи во дворе я не слышал. Утверждения об избиении
                о. Михаила продолжались, хотя никаких свидетельств этому не было. В это время из ворот вышел милиционер
                (лейтенант) и попросил не лгать, поскольку никакого избиения нет.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Л.Еремеев</p>
            

            
            
            

            
            <p>Когда о. Михаила вывели из алтаря во двор храма, иеромонах вышел на амвон и объявил: “Православные, там
                о. Михаила бьют”. Это было возмутительной ложью, так как все это видело человек десять и среди них два
                милиционера. Через некоторое время машина скорой помощи уехала. Все происходившее снималось
                видеокамерой.</p>
            

           
            <p className="has-text-align-right">&nbsp; А.Чукавин</p>
            

            
            
            

            
            <p>Я подошла к храму Успения Пресвятой Богородицы в Печатниках в то время, когда из церковного двора
                пыталась выехать машина “скорой помощи”. Из машины до меня донеслись леденящие душу вопли, что-то
                похожее на рев. Один из прихожан, стоявший рядом, объяснил мне, что в машине находится священник Михаил
                Дубовицкий, с которым в алтаре случился приступ душевной болезни, из-за чего он не мог служить сам и
                делал невозможным совершение богослужения в храме, т. к. производил в алтаре действия, которые можно
                было бы назвать кощунственными, если бы они не являлись следствием его болезни. Мне объяснили, что в
                данной ситуации невозможно было обойтись без медицинской помощи, что это была крайняя, вынужденная мера,
                на которую не хотели идти, но к которой вынуждены были прибегнуть. В это время группа
                    агрессивно настроенных людей (оказавшихся прихожанами Сретенского монастыря), представители которой
                    объясняли милиции, бывшей тут же, что они “истинно православные” а мы “еретики”, не давала проехать
                    машине. Люди чуть ли не кидались под колеса, так что милиционерам приходилось сдерживать их натиск,
                    стоя как бы в оцеплении, чтобы машина хоть как-то могла проехать, никого не задавив. Эти люди
                    говорили про нас чудовищные вещи, что мы “сектанты”, что мы якобы творим страшное злодеяние,
                    отправляя о. Михаила в больницу, что его нельзя туда отправлять, потому что он священник, на что
                    врач, сидевший в машине рядом с шофером и с ужасом смотревший на них, отвечал, что священник болен и
                    его надо лечить. Это говорили также и прихожане храма, явившиеся свидетелями того, что священник
                    Михаил Дубовицкий совершал в храме. С большим трудом машина продвигалась к проезжей части. Группа
                    людей, пытавшихся помешать отправке о. Михаила в больницу, вела себя при этом недостойным образом:
                    постоянно слышались оскорбления в наш адрес, одна из бывших там женщин сильно ударила по лицу одного
                    из наших братьев. Члены нашей общины ни на оскорбления, ни на рукоприкладство не отвечали. Перед
                    тем, как ударить его, эта женщина спрашивала, сможет ли она беспрепятственно войти в храм и стоять
                    на службе, не причинят ли ей там вреда, не будут ли толкать, чем очень меня удивила. Ей,
                    естественно, сказали, что никто ей никакого вреда не причинит, только бы она сама никого не толкала.
                    Несколько минут спустя она залепила пощечину мирно стоявшему брату. Еще один человек из этой же
                    группы, уже мужчина, вел себя крайне агрессивно, бросался на машину, когда она уже выезжала на
                    проезжую часть, на людей, кричал, что мы распинаем о. Михаила. После того, как машина отъехала,
                    образовалась потасовка между этим человеком и несколькими милиционерами — кажется, он кого-то из них
                    ударил, хотя точно не знаю, не видела, — но тут же подошел священник и несколько человек из тех, что
                    нас хулили, и быстро увели его в Сретенский монастырь.<br/> Все эти грубые выкрики, рукоприкладство,
                        чудовищные клеветнические обвинения в наш адрес представляли собой крайне безобразное зрелище и
                        являлись соблазном для проходивших&nbsp; мимо людей, милиции, врача и шофера “скорой помощи”.
            </p>
            

           
            <p className="has-text-align-right">&nbsp; Е.Ю.Чуйкова</p>
            

            
            
            

            
            <p>В алтаре все стихло. Затем вышел о. Георгий и сказал: “Я долго не решался и с тяжелым сердцем дал,
                наконец, из-за угрозы здоровью о. Михаила, разрешение на его госпитализацию”.<br/> Состояние здоровья
                    отца Георгия из-за этих событий ухудшилось, и попросив у нас разрешения на служение литургии без
                    проповедей, он начал Божественную литургию, за которой мы опять горячо молились о мире свыше, о
                    благостоянии церквей Божиих, о ненавидящих и обидящих нас, о здоровье о. Михаила.</p>
            

           
            <p className="has-text-align-right">&nbsp; О.Лаврентьева</p>
            

            
            
            

            
            <p>Начиная с этого дня общество “Радонеж”, пользуясь средствами массовой информации, извергает на нашу
                общину, особенно на нашего духовного отца, священника Георгия Кочеткова, страшную, кощунственную
                клевету. Или эти люди не боятся Бога? Так лгать, так поносить честного человека, всю жизнь свою
                отдавшего на служение Христу и людям!</p>
            

           
            <p className="has-text-align-right">&nbsp; Е.Ю.Чуйкова</p>
            

            
            
            

            
            <p>В той истерике, которая началась на радиостанции “Радонеж” в тот же день, невозможно было узнать ни один
                эпизод из тех, свидетелем которых я был, это была сознательная и злостная клевета.</p>
            

           
            <p className="has-text-align-right">&nbsp;&nbsp;В.Мезенцев</p>
            

            
            
            

            
            <p>Я, человек, который недавно, после больших и мучительных исканий встретил Бога, встретил и ощутил Его
                всей душой именно в Русской Православной Церкви в том храме, прихожанином которого я имею честь себя
                считать. То, что происходит сейчас в нашем храме и вокруг него, после прихода второго священника отца
                Михаила Дубовицкого, является для меня каким-то страшным кошмаром, состоящим изо лжи, провокаций,
                патологической злобы, ненависти и вопиющей несправедливости по отношению к прихожанам данного храма. Я
                взрослый человек и могу отличить правду от лжи, а для того, чтобы разобраться в том, что вытворяют с
                нами, не требуется даже сколько-нибудь житейского, не говоря уже духовного, опыта. Лжесвидетельство,
                попрание правды, разжигание противостояния среди священнослужителей и народа Божия, провоцирование
                насилия и все то, что позволяют себе радиостанция “Радонеж“, телепередача “Русский дом“ и те, кто за
                всем этим стоит, в миру было бы невозможно, так как за все это пришлось бы нести ответственность. Но,
                например я, не могу себе позволить предпринять никаких реальных шагов по пресечению этого безобразия,
                так как это может бросить тень на всю нашу Церковь. Вы, Святейший Владыко, единственный, кто
                    может в этом разобраться, встретившись и заслушав представителей нашей общины, дать объективную
                    оценку тем действиям, с которыми сейчас столкнулся наш приход и, видимо, скоро столкнется вся наша
                    Церковь. Призывы к борьбе&nbsp; и насилию, которые постоянно звучат в средствах массовой информации,
                    называющих себя “православными“, внушают ужас людям, только-только обратившимся к Богу. С
                        призывом о помощи, прошением о возвращении нам нашего настоятеля и духовного отца — Георгия
                        Кочеткова, я обращаюсь к Вам, Ваше Святейшество, к нашему первоиерарху и отцу.</p>
            

           
            <p className="has-text-align-right">&nbsp; Ваш недостойный молитвенник<br/> А.Шарин (Из письма патриарху)</p>
            

            
            
            

            
            <p>Святейшему Патриарху Московскому и Всея Руси Алексию II</p>
            

            
            <p>&nbsp; от прихожанки
                Скоробогатовой М.Ф.</p>
            

            
            <p>&nbsp; Заявление</p>
            

            
            <p>&nbsp; Я хочу сообщить о том, что я была в храме 29.06 , т. е. когда это все произошло. То, что обвиняют
                отца Георгия, — несправедливо, что показывают по телевидению, — все вранье, священникам не к лицу
                заниматься ложью. Никто отца Михаила не бил, у него был нервный срыв. Я прошу, пожалуйста,
                    разобраться по-божески, о. Георгий ни в чем не виновен, он очень хороший священник, восстановите
                    его.</p>
            

            
            
            

            
            <p>“ГРУППА ПОДДЕРЖКИ” О. МИХАИЛА</p>
            

            
            <p>&nbsp;<strong><em> Все опубликованные свидетельства о. Михаила и присутствовавших в храме членов “группы
                поддержки” приводятся в сборнике полностью. </em></strong></p>
            

            
            <p>&nbsp; СВИДЕТЕЛЬСТВО
                М.НАМАКОНОВОЙ</p>
            

            
            <p>&nbsp; <strong><em>“Православная Москва” №19—21,&nbsp; июль 1997 г., </em></strong></p>
            

            
            <p>&nbsp;<strong><em> а также ”Черная сотня” №7—8 (49—50) </em></strong></p>
            

            
            <p>&nbsp; 29 июня 1997 года я со своей мамой пошла на исповедь в храм Успения Богородицы в Печатниках
                (Сретенка, д. 3) к отцу Михаилу Дубовицкому к 9 часам утра. Без пятнадцати девять мы были у храма,
                который был закрыт. Из храма вышли двое прихожан и сказали: “Подождите часок.” Мы ответили, что нам было
                назначено на девять. “ Идите через часовню.” Пришли в часовню, служащие ответили, что не могут нас
                пропустить. В это время в храме шла проповедь Кочеткова (так везде в тексте — ред.), почему дверь храма
                была закрыта — нам неведомо. Нам открыли дверь те прихожане, которые говорили: “Подождите часок”.
                Видимо, пропустили потому, что подошла еще одна девушка из кочетковских прихожан: друг друга они знают в
                лицо.&nbsp; Отца Михаила мы не увидели. Шла проповедь Кочеткова, который говорил, что “надо бояться зла
                во всех его проявлениях”. Осмотревшись по сторонам в поисках отца Михаила, мы заметили, что он окружен
                алтарником, прихожанкой и молодым человеком с кинокамерой. Они теснили отца Михаила, не давая ему читать
                требник. Они его теснили и все его возражения записывали на камеру. Сначала мы думали, что отец Михаил
                готовится к службе, но потом поняли, что&nbsp; они не давали ему читать, перебивали его, закрывали
                требник и оттесняли к стене, не давая ему двинуться. О. Михаил говорил: “Я буду исполнять свой
                пастырский долг, вы мне мешаете.” Попытка отца Михаила&nbsp; продолжать чтение
                требника&nbsp; пресекалась в грубой форме алтарником и прихожанкой. Я&nbsp; возмутилась такому
                поведению, сказав, что они не имеют&nbsp; права так обращаться со священником. На мое
                возмущение&nbsp; они спросили: “Во сколько вы сюда пришли? Он мешает нам вести&nbsp; службу. Он
                неправильно ведет&nbsp; службу, он не причащает людей по воскресеньям, вы не&nbsp; знаете, как он себя
                ведет, а мы&nbsp; знаем.”&nbsp; Несмотря на их нападки, отец Михаил что-то читал по-церковнославянски,
                они продолжали его перебивать. Мы с&nbsp; матерью хотели помочь отцу&nbsp; Михаилу как-то, но прихожанка
                грубо меня оттолкнула. Подоспел “вышибала”, сказал, что&nbsp; вышибет меня отсюда. Тут отец&nbsp; Михаил
                попросил, чтобы мы&nbsp; пошли&nbsp; в Сретенский монастырь, сообщили о происходящем отцу Тихону и
                позвонили&nbsp; в милицию, так как против&nbsp; него применяют физическую&nbsp; силу. Судя по дальнейшим
                событиям, кочетковцы решили опередить нас и сами вызвали милицию, представив при этом&nbsp; себя
                “пострадавшей” стороной. Мы пошли в Сретенский&nbsp; монастырь, попросили дежурного из
                административного&nbsp; корпуса, чтобы он позвонил в&nbsp; милицию и связался с отцом Тихоном. Это было
                приблизительно в 9.20. О. Тихон спустился к нам, мы ему рассказали о том, что происходит, он пригласил
                милиционера, бывшего на территории монастыря, мы вместе пошли в Успенский храм, но отца Михаила мы не
                увидели, а проповедь шла своим чередом. Через некоторое время мы услышали крики отца Михаила,
                доносившиеся из алтаря: “Православные! Помогите!” Моя мама закричала: “О. Михаил, мы здесь!” Мы хотели
                пройти к алтарю, но присутствующие в храме нас обступили, приказывали замолчать и не мешать проповеди.
                На все наши вопросы “Что там происходит?” так называемые прихожане кричали: “Молитесь, он человек
                больной, одержимый, бесноватый, он всегда так кричит, на всех службах!”&nbsp; Нам говорили, что женщинам
                в алтарь входить запрещено, но в это время в алтаре находилась женщина — то ли прихожанка, то ли
                “алтарница”.&nbsp; Окружающие нас женщины тихими голосами стали нас успокаивать: “Ну вот, сейчас
                укольчик успокоительный сделали, помощь оказали, и все нормально.” (Внимательный читатель может
                сопоставить это со свидетельством о. Михаила о сделанном в больнице “каком-то” уколе, а также с
                признанием иером. Анатолия Берестова: доказать существование в крови о. Михаила “зловредных” веществ не
                удалось — ред.) Подошел Кочетков, сказал, что сейчас придет милиция, разберемся. Через некоторое время
                подошел алтарник соседнего храма Владимир, он тоже стал возмущаться, сказал, что он пришел к отцу
                Михаилу и хочет его видеть и знать, что там происходит. Нас обступили и стали кричать со всех сторон
                оскорбления, клеветать на отца Михаила и задавать вопросы: “Откуда вы? Зачем пришли?” В это время в
                алтарь входили и выходили люди в гражданской одежде, крики “Помогите!” продолжались, а прихожане
                говорили нам, чтобы мы молились за “одержимого Михаила” и говорили, что “он не хочет выходить из
                алтаря”. На наш вопрос “Что там происходит?” прихожанки ответили, что не позволят отцу&nbsp; Михаилу
                выйти из храма в облачении. Один прихожанин сказал:&nbsp; “Мы его не держим, пусть уходит из храма, но
                без облачения.” О. Михаил продолжал кричать: “Православные, помогите!” Через некоторое время вышедший из
                алтаря прихожанин на наш вопрос “Почему вы его не выпускаете?” ответил, что отец Михаил сам
                отказывается&nbsp; выходить. Пришел в облачении отец&nbsp; Иона (это был иерод. Иоанн (Сирота) — ред.)
                из Сретенского монастыря, которого пригласил тот милиционер, с которым мы пришли. На его попытку пройти
                в&nbsp; алтарь Кочетков ответил отказом, мотивируя тем, что отец&nbsp; Михаил сам не хочет выходить.
                Милиционер, вызванный&nbsp; Кочетковым, предлагал всем&nbsp; успокоиться. Отец Иона хотел поговорить с
                отцом Михаилом, но его&nbsp; категорически не пускали,&nbsp; между тем прихожане продолжали входить и
                выходить из&nbsp; алтаря. Алтарник находился&nbsp; там все время. Нам сказали, что отец Михаил валяется
                на полу и кричит, так как он одержимый.&nbsp; Кочетков благословил милиционера зайти в алтарь. О. Иона
                ушел, через 15—20 минут пришли два священнослужителя и прихожанка из Сретенского монастыря, стали
                просить пройти в алтарь, откуда доносились крики отца Михаила. О. Никандр прошел в алтарь и увидел там
                отца Михаила, связанного, на полу. О. Никандр вышел, алтарь опять закрыли, отец Михаил опять стал звать
                на помощь. Какая-то прихожанка кричала, что отец Михаил больной человек, что он постоянно во время
                службы кричит, не давая Кочеткову вести службу.&nbsp; Алтарник Владимир ринулся на этот крик в алтарь со
                словами: “Мы здесь!”. Со всех сторон его схватили за руки, заломили, потащили к выходу. Я стала за него
                заступаться, меня выволокли из храма, его тоже. Мама осталась в храме. Обратно в храм нас не пустили.
                Священники вышли из храма и остановились у входа в храм. Через некоторое время со двора подъехала скорая
                помощь, отца Михаила выволокли и втолкнули в машину. Санитары обращались с ним жестоко. Когда машина
                поехала, я на ходу открыла дверь и увидела, как двое прихожан держали отца Михаила за руки и за волосы,
                мы пытались вытащить отца Михаила. Подоспел отряд милиции с автоматами, и один милиционер, которого
                Кочетков благословил пройти в алтарь, нецензурно выругавшись, грубо оттолкнул мою мать. В это время
                машина тронулась. но остановилась, так как&nbsp; О. Никандр бросился под колеса, не давая скорой
                помощи&nbsp; ехать. Его оттащили, и машина уехала. Владимир, алтарник&nbsp; соседнего храма, запомнил
                номер скорой помощи. Я запомнила только буквы — РКК. В машине о. Михаил молился: “Прости им, Господи,
                не&nbsp; ведают, что творят! Прости им,&nbsp; Господи, не ведают, что творят.“ Он тихим, совершенно
                изможденным голосом просил&nbsp; православных:&nbsp; “Помогите, помогите.”&nbsp; О. Михаил находился
                в&nbsp; белом подряснике, видимо, облачение с него сорвали. Руки у&nbsp; него были связаны за спиной.
                Как выяснилось позже, отца&nbsp; Михаила увезли в 14-ю городскую психиатрическую больницу, откуда в этот
                же день выпустили.&nbsp; Сотрудники милиции вместо того, чтобы пресечь насилие, чинимое Кочетковым
                и&nbsp; его прихожанами отцу Михаилу, вместо того, чтобы встать&nbsp; на сторону закона, фактически
                стали соучастниками преступления, санкционировав&nbsp; провокацию против православного священника. Мы,
                православные, являемся законопослушными гражданами и привыкли относиться к&nbsp; сотрудникам милиции с
                уважением. Однако своими действиями в данном конкретном случае они не только нарушили закон, но
                дискредитировали&nbsp; МВД в глазах православных&nbsp; людей. <strong><em>(Вспомните это обвинение,
                    читатели, когда встретите в следующем разделе справку из милиции — ред.</em></strong>)</p>
            

            
            <p>СВИДЕТЕЛЬСТВО В.СЕРГЕЕВА</p>
            

            
            <p><strong><em>Приведенное интервью с небольшими вариациями было озвучено три раза, 29 июня, 1 и 2 июля. На
                прямой вопрос ведущего при одном из этих выступлений: “Скажите, Владимир, о. Михаила в алтаре били?”
                последовал ответ: “Видеть я этого не видел”. Здесь приводится по тексту, опубликованному в газете
                “Радонеж”&nbsp; № 11(55), июль 1997 г. </em></strong></p>
            

            
            <p>&nbsp; Утром двадцать девятого июля, (<strong><em>так в тексте - ред.</em></strong>) в девять часов
                тридцать минут, я пришел для того, чтобы исповедываться у о. Михаила. Около храма уже стояла милицейская
                машина. Меня это очень насторожило. Когда я зашел в храм, я увидел, как некий старший лейтенант и
                окружившие его прихожане что-то очень бурно обсуждали. Как я понял с их слов, о. Михаил стал, как это
                положено, по требнику читать молитвы перед исповедью. Тут к нему подошли, как я их называю, «полицаи»
                (они очень похожи на них и внешне, и по своему поведению, и даже по повязкам, которые они носят на своих
                рукавах) и попросили, чтобы он перестал это делать. А он читал на славянском языке. Когда он сказал: «Я
                буду исполнять свой долг», они молча захлопнули требник, после чего он был если не насильственно, то
                очень властно препровожден в алтарь, где содержался «под стражей». Также я видел следующее. Из
                Сретенского монастыря пришел диакон о. Иоанн и просил, чтобы его допустили к о. Михаилу. Он просил этого
                у Кочеткова, но тот отказал ему. Старший же лейтенант, тоже просившийся к о. Михаилу, был Кочетковым
                допущен. Тот зашел (я думаю, с оружием) в алтарь. Через пять минут оттуда послышались крики о. Михаила,
                который взывал о помощи: «Православные, помогите!” Он крикнул несколько раз. Какое сердце православного
                человека&nbsp; может выдержать подобное?! Я стоял недалеко от северных дверей и, естественно, рванулся
                на помощь о. Михаилу. Но через мгновение меня скрутили так называемые прихожане — «полицаи» этого храма
                и выбежавший из алтаря милиционер. Меня вывели на улицу и только чудом не увезли в милицию.&nbsp; Из
                Сретенского монастыря пришли еще два священника — два иеромонаха. Среди них был о. Никандр. Он, улучив
                момент, вошел в алтарь и увидел, как о. Михаила выводили через задние двери, связанного... (<strong><em>На
                    видеопленке отчетливо видно, что о. Михаил не связан, идет своими ногами, его ведут под руки —
                    ред.</em></strong>) Я не знаю, как назвать тех людней (<strong><em>так в тексте - ред.</em></strong>),
                кто его выводил. Это были, наверное, санитары, но слово это к ним не подходит. Они прекрасно понимали,
                кого они выводили. О. Михаил читал Иисусову молитву: «Господи, не вмени им сего греха, ибо не ведают,
                что творят...»&nbsp; (<strong><em>На этом месте видеопленка фиксирует страшные крики о. Михаила:
                    “Убива-а-ают” — ред.</em></strong>) Его насильно ввели в машину «скорой помощи», вызванную перед
                этим кочетковцами. Когда я попытался рвануться к машине, чтобы остановить ее, то через окно увидел
                связанного о. Михаила, двое санитаров держали его за руки и за волосы. О. Михаил читал ту же
                молитву.&nbsp; О. Никандр для того, чтобы остановить машину, лег под колеса. К нам была применена
                физическая сила, машину мы не смогли задержать, и она уехала. Тут же нас обступили автоматчики, чтобы не
                пустить по следам уехавшей «скорой помощи». </p>
            

            
            <p>&nbsp; 1 июля 1997 г.</p>
            

            
            <p>СВЯЩЕННИК МИХАИЛ ДУБОВИЦКИЙ</p>
            

            
            <p><strong><em>Прямой эфир радиостанции “Радонеж”, интервью о. Михаила </em></strong></p>
            

            
            <p><strong><em>1 июля 1997 г. из 7-й городской больницы. Приводится по тексту, опубликованному в газете
                “Радонеж”&nbsp;&nbsp; № 11(55), июль 1997 г.</em></strong></p>
            

            
            <p>&nbsp; Конфликт произошел в
                День Всех Русских Святых. Когда я давал чтецам указание, как необходимо вести
                службу, они категорически отказались это делать в грубой форме. О. Георгий сам
                вырвал у меня из рук аналой и книги, и мне не давали книг, чтобы совершать
                службу. Когда я хотел выйти из храма, чтобы принять какие-то меры по
                прекращению этих бесчинств, то меня не выпускали под предлогом того, что я в
                облачении. Вход пикетировали, дверь закрыли ключом. Я вернулся и стал читать
                первый час по «Часослову» — первой книге, которая мне попалась. Этого мне
                делать тоже не дали. У меня вырвали из рук и эту книгу. Так проходила эта
                служба.&nbsp; Потом я попытался выйти из
                боковой двери алтаря, но и этого мне не дали сделать. Я пытался посмотреть, где
                мне выйти из алтаря, чтобы просить о помощи. Но меня уже окружили, тянули со
                всех сторон, срывали облачение. Сорвали крест, фелонь, епитрахиль, и я остался
                в одних только поручах. Когда я стал громко взывать о помощи, чтобы меня
                отпустили, чтобы мне дали исполнить священнические обязанности, мне выкручивали
                руки, свалили на пол, (?! - ред.) и в таком положении я остался лежать до
                прихода врача-психиатра. Когда он начал со мной говорить, я ответил, что я
                психически здоров и не нуждаюсь ни в какой помощи. Тогда он стал говорить с
                теми, кто его вызвал. После этого я начал читать Страстное Евангелие от Матфея.
                Затем я спросил позволения выйти из алтаря. Но мне насильно скрутили руки и
                вывели из алтаря. Я кричал, просил о помощи, но был с помощью ОМОНа затолкнут в
                машину и увезен сначала в Перовскую психбольницу № 13, а затем — в 14-ю
                больницу на ул. Бехтерева. Там и был сделан укол, после него я очень долго
                спал, было очень тяжелое состояние. Потом был собран консилиум врачей, и меня
                отпустили из больницы, признав психически здоровым.</p>
            

            
            <p><strong><em>Прямой эфир радиостанции “Радонеж”&nbsp; 30 сентября 1997 г.:</em></strong></p>
            

            
            <p>&nbsp; То, что происходило в алтаре Успенского храма на 29 июня, можно квалифицировать как физическое
                насилие, которое было применено ко мне как к священнику и как к человеку. Это выражалось в пиханиях, в
                толканиях, в такой физически насильственной блокировке, значит, в один момент, значит, получилось так,
                что я упал на пол, пытаясь прорваться сквозь ряды кочетковцев, чтобы исполнить свои священнические
                обязанности, я просил о том,&nbsp; чтобы мне дали возможность исповедовать, чтобы мне дали возможность
                подойти к престолу, к жертвеннику, мне этого сделать не дали, потом меня оттаскивали за ноги, когда я
                пытался прорваться через частокол ног, потом очень больно один из алтарников мне наступил на руку, я
                закричал от боли, по-моему, это был...(<strong><em>в этом месте Е.Никифоров перебивает, поясняя, что
                    тихий голос о. Михаила (на пленке) криком назвать невозможно</em></strong>) Но в конце концов это
                закончилось и прямым уже насильствием. Меня схватили, совершенно жестоко, грубо схватили и выпихивая
                выволокли из алтаря.</p>
            
        </div>
    );
};

export default Zaos3;
