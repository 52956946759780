import React from "react";
import './text.css';

const Nachalo = () => {
    return (
        <div align={'start'} className="text1">
           <h2>Введение</h2>
           <p>Сборник посвящен ряду событий в современной церковной жизни,  в первую очередь — запрещению в священнослужении свящ. Георгия Кочеткова и изгнанию возглавляемой им общины из восстановленного ею московского храма Успения в Печатниках (1997 г.). Отдельный раздел посвящен запрещению в священнослужении всемирно известного иконописца архим. Зинона (Теодора) и изгнанию из Мирожского монастыря его общины. Далее приводятся документы, свидетельствующие о преследовании других священнослужителей и высших церковных иерархов. 
              Это явления, с одной стороны, глубоко прискорбные, потому что об искренних, живых и плодотворных усилиях по возрождению общинной жизни, православной миссии и проповеди в храме Успения в Печатниках или в Мирожском монастыре приходится говорить уже в прошедшем времени. С другой стороны, они говорят о надежде, ибо стремление к глубине и подлинности христианской жизни в церкви было, есть, и уничтожить его нельзя: одним из подтверждений этого является судьба общины свящ. Георгия Кочеткова.
              Сборник включает в себя  статьи, опубликованные в течение 1996-1998 г. в печати, расшифровки теле- и радиопередач, выдержки из писем, заявлений, обращений и т.п. 
              Составители намеренно ушли от предпринятой в предыдущем номере “Вестника” попытки создать своеобразный диалог между различными позициями. После событий, произошедших в 1997 г. в храме Успения в Печатниках, стало очевидно, что одну из “сторон” в противостоянии (ту, которую теперь уже можно окончательно охарактеризовать как “фундаменталистскую”) начали представлять  в основном сектантски настроенные околоцерковные и нецерковные силы. Это делает невозможным помещение материалов той и другой стороны в равной пропорции без какого-либо комментария. Нам представляется искусственным уравнивать в правах правду с ложью. В этом одна из причин того, что комментарий составителей занимает в сборнике значительно большее место, чем в предыдущих номерах. 
              Это не означает, однако, что все неоткомментированные утверждения соответствуют действительности. Некоторые заведомо ложные тексты настолько, на наш взгляд, “говорят сами за себя”,  что в комментариях не нуждаются. 
              Комментарий составителей и в виде сплошных текстов,  и в виде примечаний в скобках везде набран курсивом. Жирным шрифтом выделены те места, которые заслуживают, по мнению составителей, особенного внимания. 
              Сборник составлен таким образом, чтобы при чтении можно было при желании пропускать отдельные фрагменты или целые разделы, возвращаясь к ним по мере необходимости.
              Во всех документах, в том числе в опубликованных свидетельствах,  сохранены особенности орфографии и пунктуации подлинников (включая опечатки). В газетных и журнальных статьях  исправлены лишь очевидные опечатки и сохранены особенности стилистики авторов.</p>
        </div>
    );
};

export default Nachalo;
