import React from "react";
import './text.css';
const Zaos4 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Часть 4. 29 июня: говорят документы</h2>

            
            <p><strong>СПРАВКИ И ПОСТАНОВЛЕНИЯ ОРГАНОВ МИЛИЦИИИ ПРОКУРАТУРЫ</strong></p>
            

            
            <p><strong><em>&nbsp;</em></strong></p>
            

            
            <p><strong><em>Ниже приводится никогда ранее не публиковавшееся интервью журналиста информационного
                агентства “Интерфакс“ с сотрудником 18-го отделения милиции, присутствовавшим при инциденте 29 июня.
                Ссылки на этот документ можно найти в статье Д.Горина “Кто заказывает “избиения” священников?”
                (“Независимая газета”, 8.08.97 г.)</em></strong></p>
            

            
            <p><strong><em>Вопрос:</em></strong> Главное — понять, как развивались события.
                Откуда вы узнали, что там что-то происходит?</p>
            

            
            <p><strong><em>Ст.
                лейтенант М.Ю.Шунаев, сотрудник 18-го о/м:</em></strong> Да я узнал... дежурный сообщил, потому что
                позвонили из церкви, сказали, что там что-то такое с батюшкой происходит, в
                общем, непонятное. То ли он не в себе, то ли что-то такое, и якобы он там в
                церкви буянить начал.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> Это было примерно во сколько времени?</p>
            

            
            <p><strong><em>Ст.
                лейтенант:</em></strong> Это было
                утром, где-то часов в десять, в полодиннадцатого.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> И вы сразу направились туда?</p>
            

            
            <p><strong><em>Ст.
                лейтенант:</em></strong> Ну да, мы
                подъехали, ну, зашел я в помещение туда.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> То есть сначала в храм вы вошли?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Да, сначала в храм мы вошли, а потом батюшка разрешил пройти в
                алтарь.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>И что вы увидели?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Ну, что я увидел? Увидел, что стоит священник Михаил и он рвался
                выйти к прихожанам, а его как бы не то что держали, просто не пускали, потому что как настоятель сказал,
                у него в это время должна идти исповедь, а в таком состоянии он в принципе не может вести исповедь,
                потому что здесь, как он объяснил, надо отрешиться от всего, а когда он в возбужденном состоянии... Ну
                вот. Ему, значит, и настоятель, и те, кто его окружали, говорили, что раз не можете вести службу —
                снимите облачения и идите домой с Богом. Так, в принципе, насилия не было никакого.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>В чем это заключалось? Потому что главное обвинение в том, что чуть не
                драка случилась.</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Драка случилась — это пришли со Сретенского монастыря, это уже
                позже, я не знаю, прихожане они Сретенского монастыря или... в общем, там что-то такое внутрицерковное.
            </p>
            

            
            <p><strong><em>Вопрос:</em></strong> То есть когда вы в первый раз вошли в алтарь, вы увидели, что о. Михаил
                хочет выйти, а ребята алтарники как-то загораживают...</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Ну да, загораживают проход.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> И как это развивалось дальше?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>А настоятель...</p>
            

            
            <p><strong><em>Вопрос: </em></strong>Настоятель убеждает его в том, что не нужно так поступать?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Да, что не нужно так поступать, раз в таком состоянии, то лучше
                пойти домой.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>И что дальше произошло там?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Ну, что дальше? Дальше, как говорится, больше. Я вышел к
                прихожанам, потому что возмущения пошли — что там делается и все такое — ну, пошел, успокоил прихожан,
                говорю, ничего особенного там нет, никто его в общем-то не задерживает там — ну, это все на камеру
                снималось, в принципе у них есть, документально все оформлено. Так, не документально, но можно у них
                посмотреть. Потом, значит, я постоял перед алтарем, чтобы там ничего не произошло, и опять вошел в
                алтарь. Была такая картина: о. Михаил лег на пол сам, никто его — там, в принципе, рукоприкладства-то не
                было — лег на пол, ну и здесь уже кто-то из прихожан вызвал медиков.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> Он что, молча лежал на полу?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Ну да, молча лежал на полу.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> По собственной воле?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> По собственной воле.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>А крики? Говорили, что были крики какие-то?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Крики — это позже, когда уж медики приехали, уже здесь он начал,
                видя, что что-то не выходит, бучи, митинга такого не получается, потому что пришли люди поддерживать это
                и начали — эти прихожане на этих... там было всего таких человека два. </p>
            

            
            <p><strong><em>Вопрос: </em></strong>В поддержку Михаила?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Да, остальные пассивно так, но оказывали поддержку. <strong><em>(То
                есть старались не нагнетать и по возможности разрядить обстановку — ред.)</em></strong></p>
            

            
            <p><strong><em>Вопрос:</em></strong> Что касается того, как о. Михаила сажали в скорую помощь: с
                рукоприкладством, насильно все происходило?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Пришел врач и так побеседовал с отцом Михаилом немного и
                говорит: человек немножко не в себе, острое психическое расстройство, типа этого, диагноз такой поставил
                и, говорит, его нужно везти в больницу. Тут пришли со Сретенки два служителя церкви, ну, нас попросили
                подойти в алтарь опять и эти двое, которых тоже не пустили в алтарь, два священника, они сказали нам:
                батюшку трогать нельзя, ну, мы говорим, мы и не собираемся. Ну его и вывели эти, алтарники, вывели его
                под руки из алтаря во дворик, посадили в машину, машина тронулась, но, главное, перед воротами скопление
                было. Ну здесь мы попросили помощи, приехала еще пара нарядов. </p>
            

            
            <p><strong><em>Вопрос:</em></strong> Из того же отделения, из 18-го?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Нет, из 68-го, с Сухаревки. И, в принципе, потому что прихожане
                эти с Сухаревки,<strong><em> (прихожане Сретенского монастыря — ред.) </em></strong>стали бросаться под
                колеса, ну, машина уехала и на этом, в принципе, все сразу закончилось.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> Вопрос в том, что не был оформлен протокол этого события, или его не
                нужно было оформлять?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>А что здесь оформлять? Здесь в принципе ничего ярко выраженного
                противоправного, на мой взгляд, не было, просто ничего — в церкви там не били, не колотили, ни
                хулиганства — какое хулиганство...</p>
            

            
            <p><strong><em>Вопрос:</em></strong> То есть вы убеждены, что рукоприкладства в алтаре не было, никакого
                серьезного насилия — ну, от которого синяки остаются, драки священников, — не было?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Не было.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>То есть насилия не было?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Не было.</p>
            

            
            <p><strong><em>Вопрос: </em></strong> Ну а как развивались события внутри вашего отделения милиции? Потому
                что с одной стороны, как мы знаем, вышел документ 18-го отделения милиции с определенными формулировками<a
                    href="http://hv-3.ru/wp-admin/post.php?post=179&amp;action=edit#1">[1]</a>. — кто его писал? </p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Я не знаю, кто писал. Я, во всяком случае, ничего не писал.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>Вы ничего не писали, не подписывали, вас ни о чем не спрашивали?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Нет. Может быть, потом участковый приходил — я не знаю. Мое дело
                выехать по вызову.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> То есть оформление документов внутри 18 отделения без вашего участия
                проходило?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Да.</p>
            

            
            <p><strong><em>Вопрос:</em></strong> Там еще сержант какой-то говорят крутился — вы видели его, или вы были
                один? Он подтвердит ваши слова?</p>
            

            
            <p><strong><em>Ст. лейтенант:</em></strong> Знаете, я был с самого начала, а он подошел, когда отца Михаила
                непосредственно сажали в машину, и мы вдвоем зашли в алтарь, чтобы люди не рвались туда. Больше того,
                что я сказал, он не скажет. Они в машине на выходе сидели все время.</p>
            

            
            <p><strong><em>Вопрос: </em></strong>Я еще раз хочу повторить, что из 18-го отделения милиции, может быть,
                на более высоком уровне, вышла бумага, с формулировкой, что была в алтаре драка, рукоприкладство, и на
                основании этого инцидента священника сняли с настоятельства, фактически уволили — отца Георгия Кочеткова
                — как будто бы он главный зачинщик и главный виновник, как будто священника Михаила избивали, он
                пострадал страшно, и вот за это его фактически уволили с работы на основании документа, вышедшего из
                18-го отделения милиции, вот в чем проблема. Если вы единственный свидетель, вас двое, и вас никто не
                опрашивал — что произошло, как произошло — откуда появилась эта бумага, на ваш взгляд?</p>
            

            
            <p><strong><em>Ст. лейтенант: </em></strong>Вот этого я не знаю. </p>
            

            
            <p>&nbsp;* * *</p>
            

            
            <p>8 октября 18
                отделение милиции г.Москвы приняло постановление об ОТКАЗЕ в возбуждении
                уголовного дела по факту нарушения общественного порядка в Успенском храме 29
                июня с.г. То есть, наконец-то официальнои окончательно было установлено, что в
                храме в тот день никто о. Михаила Дубовицкого не бил и насилия к нему не
                применял. Вынужденная принудительная госпитализация, решение о которой принял
                выполнявший свой долг врач скорой помощи, не может быть квалифицирована как
                насилие ни юридически, ни нравственно. Расследование в данном отделении милиции
                велось по заявлению жены о. Михаила, утверждавшей, что будто бы “29 июня он был
                подвергнут насильственным действиям и направлен в психиатрическую больницу ...
                безо всяких оснований”. Т.о., 18 отделение милиции дезавуировало письмо своего
                же начальника ст. лейтенанта А.Л.Римского от 1-го июля, написанное по запросу
                архиепископа Арсения, патриаршего викария, в котором говорилось, что якобы
                сотрудниками милиции была обнаружена “драка священников” в алтаре храма, и что
                подобное происходило не в первый раз.</p>
            

            
            <p><strong><em>Из
                статьи Б.Колымагина “Община о. Георгия Кочеткова изгоняется из храма, в то
                время как официальное расследование ее полностью оправдало”, “Русская мысль” №
                4196, 6 — 12 ноября 1997 г.</em></strong></p>
            

            
            <p>&nbsp;***</p>
            

            
            <p className="has-text-align-center">Постановление</p>
            

            
            <p>об отказе в возбуждении уголовного дела<br/>20 октября 1997 года&nbsp;<br/>г.&nbsp;Москва</p>
            

            
            <p>&nbsp;Помощник Мещанского межрайонного прокурора
                г.&nbsp;Москвы Станишевская А.А., рассмотрев материал по факту недобровольной
                госпитализации в психиатрическую больницу г-на Дубовицкого М.В.,</p>


            
            <p>&nbsp;УСТАНОВИЛ:</p>
            

            
            <p>&nbsp;02.10.97 года в Мещанскую межрайонную прокуратуру г.&nbsp;Москвы поступил из 18 отделения милиции
                7 РУВД ЦАО г.&nbsp;Москвы материал по заявлению г-ки Дубовицкой Т.В. о незаконном помещении ее мужа
                Дубовицкого М.В. в психиатрический стационар 29.06.97 года.<br/>В ходе проверки, проведенной Мещанской
                    межрайонной прокуратурой г. Москвы было установлено, что 29.06.97 года в храме Успения Пресвятой
                    Богородицы в Печатниках, расположенном по адресу: ул.&nbsp;Сретенка, д.&nbsp;З во время богослужения
                    в адрес скорой психиатрической помощи поступил вызов от г-на Родионова, назвавшегося прихожанином
                    вышеуказанного храма. Около 12:00 29.06.97 года бригада скорой психиатрической помощи в составе
                    врача Шафрана Г.Л. и фельдшера Савушкина Б.Б. прибыла по указанному адресу, откуда данной бригадой
                    г-н Дубовицкий был доставлен в городскую психиатрическую больницу №&nbsp;14 с диагнозом —
                    “шизофрения”.В своих объяснениях врач Шафран показал, что когда прибыла бригада скорой
                        помощи в храм, их провели в помещение ризницы, в корридор, где врач увидел мужчину, лежащего на
                        полу, зовущего на помощь и читающего псалмы. Все это происходило при том, что этого мужчину
                        никто не трогал, никто не применял к нему силу. Врач Шафран установил признаки острого
                        психического расстройства и принял решение, согласно закону РФ “О психиатрической помощи и
                        гарантиях прав граждан при ее оказании”, о недобровольной госпитализации Дубовицкого, поскольку
                        он был в данный момент социально опасен, его поведение могло привести к конфликту между
                        прихожанами церкви. Состояние Шафран пояснил <a name="r1" href="#1">[2]</a>, что настоятель
                        храма Кочетков был против насильственной госпитализации, но врач настоял на недобровольной
                        госпитализации Дубовицкого. Аналогичные объяснения дал фельдшер Савушкин Б.Б.По факту
                            недобровольного доставления Дубовицкого М.В. в психиатрическую больницу с 04.10.97 года по
                            14.10.97 года при Министерстве здравоохранения РФ заседала комиссия под руководством
                            директора НЦПЗ РАМН Тиганова А.С., которая дала заключение по данному вопросу. Комиссия
                            пришла к заключению, что в момент стационирования М.В.Дубовицкий находился в остром
                            реактивном состоянии в создавшихся условиях тактика врача Шафрана Г.Л. была оправданной,
                            ибо в противном случае действия врача следовало бы расценить как неоказание медицинской
                            помощи.Таким образом, в действиях врача Шафрана Г.Л. не усматривается состава
                                преступления, предусмотренного ст.&nbsp;128 ч.&nbsp;1 УК РФ.</p>
            

            
            <p>&nbsp;Учитывая вышеизложенное и руководствуясь
                ст.&nbsp;5 п.&nbsp;2, 109, 113 УПК РСФСР,</p>
            

            
            <p>&nbsp;ПОСТАНОВИЛ:</p>
            

            
            <p>&nbsp;1. В возбуждении уголовного дела по факту
                недобровольной госпитализации г-на Дубовицкого в психиатрический стационар —
                ОТКАЗАТЬ.</p>
            

            
            <p>&nbsp;2. О принятом решении уведомить
                заинтересованных лиц.</p>
            

            
            <p>&nbsp;Помощник Мещанского межрайонного прокурора г.&nbsp;МосквыА.А.Станишевская</p>
            

            
            <p>&nbsp;“СОГЛАСЕН”</p>
            

            
            <p>&nbsp;Мещанский межрайонный прокурор г.&nbsp;Москвыстарший советник
                юстицииО.И.Степанов</p>
            

            
            <p><strong><em>Опубликовано
                в статье А.Платонова “Нас почитают обманщиками, но мы верны... Нас почитают
                умершими, но вот мы живы... “,“Независимая газета”, 27.02.98 г., а также
                “Православная община” №43</em></strong></p>
            

            
            <p><strong><em>&nbsp;* * *</em></strong></p>
            

            
            <p>&nbsp;Прокуратура Российской федерации</p>
            

            
            <p>&nbsp;Прокуратура города Москвы</p>
            

            
            <p>&nbsp;Прокуратура Центрального административного
                округа</p>
            

            
            <p>&nbsp;Мещанская межрайонная прокуратура</p>
            

            
            <p>&nbsp;107014, г.&nbsp;Москва, 1-я Боевская ул., 2,
                с. 1 тел.&nbsp;268-55-41</p>
            

            
            <p>&nbsp;17.11.97 г. № 320кс 97</p>
            

            
            <p>&nbsp;107061, г. Москва, ул. Б. Черкизовская, </p>
            

            
            <p>&nbsp;д.1/1 ЮК № 18 МГКА Мартвель Н.&nbsp;Л.</p>
            

            
            <p>&nbsp;В ответ на Ваше заявление от 3 ноября 1997
                года сообщаю Вам, что 18 отделение милиции г. Москвы провело проверку в порядке
                ст. 109 УПК РСФСР по факту нарушения общественного порядка в храме Успения
                Пресвятой Богородицы в Печатниках 29.06.97 года. Информация от 01.07.97 года о
                нарушении общественного порядка в ходе проверки не подтвердилась и 9 октября
                1997 года было вынесено постановление об отказе в возбуждении уголовного дела
                за отсутствием состава преступления.</p>
            

            
            <p>&nbsp;Мещанский межрайонный прокурор г. Москвы</p>
            

            
            <p>&nbsp;старший советник юстиции</p>
            

            
            <p>&nbsp;О.И.Степанов</p>
            

            
            <p>&nbsp;исп. Станишевская А.А.</p>
            

            
            <p>&nbsp;тел. 268-55-50</p>
            

            
            <p><strong><em>Опубликовано в статье А.Платонова “Нас почитают обманщиками, но мы верны... Нас почитают
                умершими, но вот мы живы... “, “Независимая газета”, 27.02.98 г., а также “Православная община”
                №43.</em></strong></p>
            

            
            
            

            
            
            

            
            <p><strong>МЕДИЦИНСКИЕ ДОКУМЕНТЫ</strong></p>
            

            
            <p>&nbsp;Начальнику </p>
            

            
            <p>&nbsp;управления организации </p>
            

            
            <p>&nbsp;медицинской помощи населению</p>
            

            
            <p>&nbsp;ВЯЛКОВУ Л. И.</p>
            

            
            <p>&nbsp;В отношении госпитализации </p>
            

            
            <p>&nbsp;Дубовицкого М.В. </p>
            

            
            <p>Отдел
                психоневрологической помощи сообщает, что ДУБОВИЦКИЙ МИХАИЛ ВИКТОРОВИЧ, 1968
                г.р., священник, был осмотрен в храме по вызову милиции 29.06.97 дежурным
                психиатром (врач Шафран), был установлен предварительный диагноз “Шизофрения,
                острый дебют”, констатирована необходимость стационирования в психиатрическую
                больницу. </p>
            

            
            <p>&nbsp;В тот же день доставлен в Московскую
                психиатрическую больницу № 14. В течении нескольких часов осматривался врачами-психиатрами
                стационара, в том числе комиссионно. Комиссия пришла к заключению о том, что
                ДУБОВИЦКИЙ М.В. признаков психического заболевания не выявляет, после чего, в
                день поступления, он был выписан из стационара. </p>
            

            
            <p>&nbsp;По имеющимся сведениям вызов психиатра возник
                в результате нарастающего конфликта между священнослужителями храма на
                Сретенке. В МПБ № 14 поступил со свежими ссадинами и кровоподтеками на голенях,
                спине и правой кисти.</p>
            

            
            <p>&nbsp;08.07.97</p>
            

            
            <p>&nbsp;Начальник отдела </p>
            

            
            <p>&nbsp;А.С.Карпов</p>
            

            
            <p><strong><em>Опубликовано
                О.Николаевой в статье “Правоверие как инакомыслие”, “Независимая газета”,
                26.07.97 г.</em></strong></p>
            

            
            <p><strong><em>&nbsp;* * *</em></strong></p>
            

            
            <p><strong><em>Одним
                из основных обвинений против свящ. Георгия Кочеткова и его прихожан было
                обвинение в драке, избиении и т.п. Приведенная ниже справка, выданная как бы в
                подтверждение версии о драке имеет любопытную деталь: со слов о. Михаила, он
                был избит “неизвестными”. Иначе говоря, или некто действительно избил о.
                Михаила после выхода из больницы, или же, как справедливо заметил журналист
                Д.Горин, справка — “липовая”.</em></strong></p>
            

            
            <p>&nbsp;Травматологическое отделение поликлиники № 192</p>
            

            
            <p>&nbsp;Красногвардейского района</p>
            

            
            <p>&nbsp;Адрес: Москва, 115372, ул. Лебедянская, 10</p>
            

            
            <p>&nbsp;Тел. 329-73-66</p>
            

            
            <p>&nbsp;С П Р А В КА</p>
            

            
            <p>&nbsp;Дана гр. Дубовицкий М.В. 1968 в том, что ему
                30 июня 1997 г. в 14 час. 45 мин. была оказана медицинская помощь по поводу
                множественные ушибы верхних ин/конечностей. Ушиб правой половины грудной
                клетки.</p>
            

            
            <p>&nbsp;Рекомендовано хлорэтил давящая повязка</p>
            

            
            <p>&nbsp;Справка не является документом, освобождающим
                от работы.</p>
            

            
            <p>&nbsp;25 июля 1997 г.</p>
            

            
            <p>со слов б-ного
                избит неизвестными 29/VI 97.</p>
            

            
            <p>&nbsp;Врач травматологического пункта (Подпись)</p>
            

            
            <p><strong><em>Документ
                упоминается в статье Д.Горина “Кто заказывает “избиения” священников?”,
                “Независимая газета”, 8.08.97 г. </em></strong></p>
            

            
            <p>&nbsp;<strong><em>* * *</em></strong></p>
            

            
            <p><strong><em>Первоначально
                в намерения составителей не входила публикация заключения Минздрава Российской
                Федерации об обоснованности госпитализации священника Михаила Дубовицкого. К
                сожалению, отдельные части этого документа были обнародованы, более того, в
                некоторых публикациях (в частности, в вышедшем тиражом 15 тысяч экземпляров
                сборнике “Школа церковной смуты”) выдержки были составлены таким образом, чтобы
                в корне исказить основной смысл документа. Поэтому мы приводим его полностью. </em></strong></p>
            

            
            <p>&nbsp;С П Р А В К А</p>
            

            
            <p>&nbsp;по проверке обстоятельств, </p>
            

            
            <p>&nbsp;связанных с направлением Дубовицкого М.В. </p>
            

            
            <p>&nbsp;в психиатрическую больницу</p>
            

            
            <p>&nbsp;Комиссия в составе Директора НЦПЗ РАМН, заведующего кафедрой психиатрии Российской академии
                последипломного образования, член-корреспондента РАМН, профессора А.С.Тиганова (председатель), Ведущего
                научного сотрудника НЦПЗ РАМН кандидата медицинских наук О.А.Борисовой, Руководителя отдела Московского
                НИИ психиатрии Минздрава России, профессора О.П.Вертоградовой, Зам. директора ГНЦ социальной и судебной
                психиатрии им. В.П.Сербского, профессора З.И.Кекелидзе и Ведущего научного сотрудника НЦПЗ РАМН,
                кандидата медицинских наук Г.И.Копейко, созданная по указанию (№ 545-У) Зам. министра здравоохранения
                Российской федерации В.Д.Володина рассмотрела следующие материалы, связанные с обоснованностью
                госпитализации Дубовицкого Н.В.:</p>
            

            <br/>
            <ol>
                <li>Карту регистрации вызова № 912356,</li>
            </ol>
            

            
            <p>&nbsp;2. Карту вызова№ 912356,</p>
            

            
            <p>&nbsp;3. Карту вызова№ 656270,</p>
            

            
            <p>&nbsp;4. Карту вызова№ 656824,</p>
            

            
            <p>&nbsp;5. Объяснительную ст. врача-психиатра Пучкиной Т.А,</p>
            

            
            <p>&nbsp;6. Объяснительную записку врача-психиатра Шафрана Г.Л.,</p>
            

            
            <p>&nbsp;7. Медицинскую карту стационарного больного№ 3258,</p>
            

            
            <p>&nbsp;8. Медицинскую карту стационарного больного№ 16442,</p>
            

            
            <p>&nbsp;9. Заявление священника М.В.Дубовицкого от 14.08.97,</p>
            

            
            <p>&nbsp;10. Заявление священника М.В.Дубовицкого от
                05.09.97,</p>
            

            
            <p>&nbsp;11. Заявление юрисконсульта Союза Православных
                братств, Представителя и помощника депутатов Государственной Думы Федерального
                Собрания Российской Федерации Грешневикова А.Н. и Московской областной Думы
                Воронина А.С., В.И.Соломонова.</p>
            

            
            <p>&nbsp;12. Письмо Архиепископа Истринского, Викария
                Святейшего Патриарха Московского и Всея Руси Арсения от 11.07.97.</p>
            

            
            <p>&nbsp;13. Члены комиссии ознакомились и приняли к
                сведению фрагменты видеозаписей, запечатлевших поведение в храме и направление
                в больницу священника М.В.Дубовицкого (копия, предоставленная старшим
                врачом-психиатром ССиНМП М.М.Андрочниковым и копия, предоставленная священником
                М.В.Дубовицким).</p>
            

            
            <p>&nbsp;Комиссия также ознакомилась с:</p>
            

            
            <p>&nbsp;14. Актом служебного расследования факта
                госпитализации в психиатрическую больницу№14 священнослужителя Дубовицкого
                Михаила Владимировича от 21.07.97.</p>
            

            
            <p>&nbsp;15. Материалами служебного расследования
                Станции скорой и неотложной медицинской помощи от 22.07.97.</p>
            

            
            <p>&nbsp;16. Протоколом заседания ЛКК психиатрической
                службы ССиНМП г. Москвы от 17.06.97.</p>
            

            
            <p>&nbsp;17. Пояснительной запиской старшего
                врача-психиатра ССиНМП М.М.Андрочникова.</p>
            

            
            <p>&nbsp;Во время работы комиссия беседовала с:</p>
            

            
            <p>&nbsp;1. Выездным врачом-психиатром 45-ой подстанции
                СиНМП Г.Л.Шафраном,</p>
            

            
            <p>&nbsp;2. Врачами городской психиатрической больницы№14
                М.В.Ахапкиной, Н.А. Яшиным, Л.Н.Евсеевой,</p>
            

            
            <p>&nbsp;3. Священником М.В.Дубовицким в присутствии
                юрисконсульта Союза Православных братств, Представителя и помощника депутатов
                Государственной Думы Федерального Собрания Российской Федерации Грешневикова
                А.Н. и Московской областной Думы Воронина А.С. В.И.Соломонова.</p>
            

            
            <p>&nbsp;Согласно полученным сведениям, психическое
                состояние М.В.Дубовицкого характеризовалось нарушением поведения с элементами
                неадекватности реагирования, включающими невозможность установления речевого
                контакта, сочетавшимися с повторяющимися выкриками о помощи в связи с якобы
                существовавшей угрозой для его жизни. Подобное состояние возникло в условиях
                психогенной травмирующей ситуации. Дальнейшее пребывание М.В.Дубовицкого в
                условиях стрессовой ситуации, которая по тяжести была близка к экстремальной,
                могло привести к углублению имеющихся расстройств и нанести существенный вред
                его здоровью.</p>
            

            
            <p>&nbsp;Таким образом, комиссия пришла к заключению, что в момент стационирования М.В.Дубовицкий находился
                в остром реактивном состоянии, которое соответствует острой реакции на стресс с преобладанием нарушения
                поведения по Международной классификации болезней 10 пересмотра. В создавшихся условиях тактика врача
                Г.Л.Шафрана — решение вывести М.В.Дубовицкого из не поддающейся разрешению тяжелой психотравмирующей
                ситуации — была оправданной, ибо в противном случае действия врача следовало бы расценить как неоказание
                медицинской помощи. На наш взгляд, врач Г.Л.Шафран действовал в соответствии с пунктом “в” статьи 29
                Закона РФ “о психиатрической помощи и гарантиях прав граждан при ее оказании”.</p>
            

            
            <p>&nbsp;В то же время, при осуществлении
                госпитализации врачом Г.Л.Шафраном были допущен ряд ошибок:</p>
            

            
            <p>&nbsp;1.1. В карте вызова вместо описания дается
                квалификационная оценка психического состояния;</p>
            

            
            <p>&nbsp;1.2. Предварительное заключение, выставленное в карте вызова, следовало бы сформулировать
                синдромально, а не нозологически.</p>
            

            
            <p>&nbsp;Следует также учесть, что врач-психиатр Г.Л.Шафран при сборе анамнестических сведений, очевидно,
                был введен в заблуждение прихожанами храма Успения Пресвятой Богородицы в Печатниках.</p>
            

            
            <p>&nbsp;1.3. Врач Г.Л.Шафран не обеспечил помещение
                М.В.Дубовицкого в машину скорой помощи силами бригады скорой помощи (или
                милиции), как это предусмотрено Законом. Поддавшись уговорам, он разрешил
                поместить М.В.Дубовицкого в машину скорой помощи работникам храма и
                сопровождать его до психиатрической больницы лицам, не имеющим право принимать участие в такого рода
                действиях <a name="r3" href="#3">[3]</a>. </p>
            

            
            <p>&nbsp;1.4. Врач Г.Л.Шафран, доверившись сведениям,
                полученным в храме, ошибочно доставил М.В.Дубовицкого в психиатрическую
                больницу № 13, где он не был принят, в связи с чем М.В.Дубовицкий был доставлен
                в психиатрическую больницу № 14.</p>
            

            
            <p>&nbsp;Действия врачей психиатрической больницы № 14
                в отношении М.В.Дубовицкого — динамическое наблюдение, комиссионный осмотр и
                последующая выписка в сопровождении супруги через 9 часов после стационирования
                — представляются оправданными.</p>
            

            
            <p>&nbsp;Председатель комиссии:</p>
            

            
            <p>&nbsp;Директор НЦПЗ РАМН, зав. кафедрой психиатрии</p>
            

            
            <p>&nbsp;Российской Академии последипломного
                образования,</p>
            

            
            <p>&nbsp;член-корреспондент РАМН, профессор А.С.Тиганов</p>
            

            
            <p>&nbsp;Члены комиссии:</p>
            

            
            <p>&nbsp;Руководитель отдела Московского НИИ психиатрии</p>
            

            
            <p>&nbsp;Минздрава России, профессор О.П.Вертоградова</p>
            

            
            <p>&nbsp;Заместитель директора ГНЦ социальной и
                судебной</p>
            

            
            <p>&nbsp;психиатрии им. В.П.Сербского, профессор З.И.Кекелидзе</p>
            

            
            <p>&nbsp;Ведущий научн. сотрудник НЦПЗ РАМН,</p>
            

            
            <p>&nbsp;канд. мед. наук О.А.Борисова</p>
            

            
            <p>&nbsp;Ведущий науч. сотрудник НЦПЗ РАМН, </p>
            

            
            <p>&nbsp;канд. мед. наук Г.И.Копейко</p>
            

            
            <p>&nbsp;14 октября 1997 года</p>
            

            
            <p><strong>ИЗ ЦЕРКОВНОЙ ДОКУМЕНТАЦИИ</strong></p>
            

            
            <p>&nbsp;Распоряжение </p>
            

            
            <p>&nbsp;Протоиерею Олегу Клемышеву,</p>
            

            
            <p>&nbsp;и.о. благочинного храмов Сретенского округа г.
                Москвы </p>
            

            
            <p>&nbsp;Приходскому совету и Приходскому собранию
                храма </p>
            

            
            <p>&nbsp;Успения Пресвятой Богородицы в Печатниках г.
                Москвы</p>
            

            
            <p>&nbsp;В связи с трагическими событиями, происшедшими
                29 июня сего года при совершении воскресного богослужения в храме Успения
                Пресвятой Богородицы в Печатниках города Москвы, в результате которых допущено
                надругательство над клириком данного храма священником Михаилом Дубовицким, его
                избиение и насильственное отправление в психиатрическую больницу, считаем
                справедливым:</p>
            

            
            <p>&nbsp;1. И.о. настоятеля священника Георгия Кочеткова, как не справившегося со своими обязанностями
                настоятеля и допустившего надругательство над священно-служителем храма, а также до сего времени не
                известившего о происшедшем правящего Епископа г.Москвы — Патриарха, отстранить от должности и.о.
                настоятеля данного храма.</p>
            

            
            <p>&nbsp;2. Приходскому совету храма, как не
                справившемуся со своими уставными обязанностями и не воспрепятствовавшему
                надругательству над священнослужителем храма, предложить сложить свои
                полномочия.</p>
            

            
            <p>&nbsp;3. Лицам, принимавшим участие в надругательстве
                над священнослужителем и его избиении, до принесения искреннего покаяния в
                содеянном духовнику г.Москвы, запрещается принятие Святых Христовых Таин и
                участие в совершении богослужений (чтении, пении, прислуживании в алтаре и
                пр.).</p>
            

            
            <p>&nbsp;4. До завершения расследования данного
                инцидента временно исполняющим обязанности настоятеля означенного храма Нами
                назначается и.о.благочинного храмов Сретенского округа г. Москвы протоиерей
                Олег Клемышев, которому надлежит озаботиться о нормализации внутриприходской обстановки
                в храме, уставном совершении богослужений, а также о скорейшем возобновлении их
                в главном алтаре храма, используемом в настоящее время не по назначению.
                Помещение временного алтаря храма, ставшее местом избиения священнослужителя,
                освятить. Членов Приходского собрания и прихожан храма ознакомить с
                каноническими правилами (27-Апост., 9-Двукратн. собора, 18-Халкидонского,
                34-Шестого Вселенского собора, 3 Правилом собора, бывшего в храме Премудрости
                Слова Божия, и121 правилом Номоканона — в большом требнике) с разъяснением
                ответственностиза их нарушение.</p>
            

            
            <p>&nbsp;Патриарх Московский и всея Руси Алексий II </p>
            

            
            <p>&nbsp;1 июля 1997 года</p>
            

            
            <p>&nbsp;* * *</p>
            

            
            <p>&nbsp;Указ</p>
            

            
            <p>&nbsp;Священнику Георгию Серафимовичу Кочеткову,</p>
            

            
            <p>&nbsp;и.о. настоятеля храма Успения Пресвятой </p>
            

            
            <p>&nbsp;Богородицы в Печатниках гор. Москвы. </p>
            

            
            <p>&nbsp;Копия:</p>
            

            
            <p>&nbsp;и.о. благочинного храмов Сретенского округа г.
                Москвы, </p>
            

            
            <p>&nbsp;Протоиерею Олегу Клемышеву</p>
            

            
            <p>&nbsp;Приходскому совету, Приходскому собранию и
                прихожанам </p>
            

            
            <p>&nbsp;храма Успения Пресвятой Богородицы в
                Печатниках г. Москвы</p>
            

            
            <p>&nbsp;Священник Георгий КОЧЕТКОВ, и.о. настоятеля
                храма Успения Пресвятой Богородицы в Печатниках г.Москвы, за неспособность
                стабилизировать внутриприходскую жизнь (хотя и обещавшего Управляющему Делами
                Московской Патриархии Архиепископу Солнечногорскому Сергию 27 июня с.г.
                нормализовать ее), а также за оскорбительные действия, унижавшие честь и
                достоинство клирика данного храма священника Михаила Дубовицкого, и приведшие к
                физической расправе над ним в алтаре означенного храма, отстраняется НАМИ от
                должности и.о.настоятеля данного храма и до завершения расследования
                происшедшего инцидента запрещается в священнослужении.</p>
            

            
            <p>&nbsp;Алексий II </p>
            

            
            <p>&nbsp;Патриарх Московский и всея Руси </p>
            

            
            <p>&nbsp;2 июля 1997 года</p>
            

            
            <p>&nbsp;* * *</p>
            

            
            <p>Его Святейшеству,
            </p>
            

            
            <p>Святейшему Патриарху
                Московскому</p>
            

            
            <p>и всея Руси
                Алексию II</p>
            

            
            <p>и.о. настоятеля
                храма Успения </p>
            

            
            <p>Пресвятой
                Богородицы в Печатниках </p>
            

            
            <p>свящ. Георгия
                Кочеткова</p>
            

            
            <p>Рапорт</p>
            

            
            <p>Ваше
                Святейшество! Святейший Владыко!</p>
            

            
            <p>Считаю своим
                долгом сообщить Вам о случившемся скорбном факте нарушения душевного здоровья
                свящ. Михаила Дубовицкого, клирика нашего храма. Не желая обременять Ваше
                Святейшество своими впечатлениями от наблюдений за ним в последнее время,
                особенно в последние перед этим дни, скажу только о событии, происшедшем в воскресенье
                29 июня с.г. В этот день о. Михаил врачами скорой помощи был доставлен в
                психиатрическую больницу прямо из храма.</p>
            

            
            <p>Странное
                поведение его можно было наблюдать уже тогда, когда он служил утреню, во время
                чтения канона. О. Михаил внезапно потребовал от алтарников увеличения числа
                тропарей на каждой песне. Алтарники ему объяснили, что не могут нарушить
                благословения настоятеля. Вместо естественного мирного разрешения вопроса о.
                Михаил сначала попытался остановить службу, потом вышел с богослужебными
                книгами на солею и стал читать канон параллельно с чтецами. Когда я, после
                просьбы не срывать службу, получил отказ, отобрал у о. Михаила книги и
                предложил продолжать утреню, он, ответив, что читать канон сокращенно —
                кощунственно, бросил мне: “Продолжайте сами”,— и отправился в богослужебном
                облачении к выходу из храма. Дежурные и члены Церковного совета приостановили
                его и предложили перед выходом из храма разоблачиться. Тогда он стал шуметь,
                ходить по храму в то время, как я, остановив исповедь, продолжил утреню.
                Несмотря на уговоры со стороны алтарников не бесчинствовать, он то на солее, то
                в храме что-то громко читал. Потом пошел в алтарь, требуя выпустить его из
                храма. Ему вновь было предложено разоблачиться и выходить. Он поднял крик в
                алтаре, зовя на помощь, инсценируя своими криками избиение. Когда я вошел в
                алтарь, он потребовал допустить его исповедовать. Я ответил, что в таком
                состоянии проводить исповедь нельзя, что ему нужно успокоиться, посидеть,
                отдохнуть 10 минут.</p>
            

            
            <p>Между тем в храм
                пришли два милиционера, один из соседнего Сретенского монастыря, другой —
                участковый 18-го о/м. Первого я, естественно, не допустил в алтарь. Участковому
                же я разрешил войти после его просьбы, т.к. по крикам о. Михаила из алтаря
                можно было подумать, что над ним совершается насилие.</p>
            

            
            <p>Милиционер
                убедился в том, что никакого насилия по отношению к о. Михаилу не применяется,
                и выйдя из алтаря, объяснил прихожанам происходящее, сказав, что батюшка не в
                себе. После этого кто-то из прихожан вызвал скорую психиатрическую помощь. Мы
                надеялись, что кризис может быть преодолен на месте, но после осмотра и попытки
                разговора с о. Михаилом врач охарактеризовал его состояние как “острый психоз”
                и сказал, что нужна госпитализация, впрочем, тут же заметил, что добровольно о.
                Михаил на нее не согласится. Понимая, что принудительная госпитализация — это
                крайняя мера, могущая вызвать негативные последствия, я долгое время не давал
                на нее своего разрешения. Но видя, что ситуация крайне обостряется и выходит
                из-под контроля, в частности, в связи с тем, что “на помощь” к о. Михаилу
                пришли агрессивно настроенные люди из Сретенского монастыря во главе с иером.
                Никандром, который ворвался в алтарь через закрытые завесой Царские врата
                (одного из пришедших пришлось выводить из храма милиционеру), я дал свое
                согласие на госпитализацию. О. Михаил был выведен под руки и посажен в машину
                скорой помощи фельдшером и алтарниками.</p>
            

            
            <p>Во дворе храма
                выезду машины скорой помощи препятствовали некоторые люди, возглавляемые тем же
                иером. Никандром, который находился еще и в нетрезвом состоянии. Это повлекло
                за собой вмешательство вызванного участковым наряда муниципальной милиции. О.
                Михаил был доставлен в 14-ю психиатрическую больницу.</p>
            

            
            <p>Ваше
                Святейшество! Во время моего отсутствия в Москве по причине паломничества, в
                котором я находился по Вашему благословению с 29 июня по 4 июля с частью
                общины, и по некоторым другим не зависящим от меня причинам, я не имел
                возможности представить Вам рапорт о случившемся. Между тем, всевозможные слухи
                об этом стали очень быстро распространяться средствами массовой информации и,
                более всего, радиостанцией “Радонеж”, которая передает заведомо ложную
                информацию и всячески нагнетает истерию с целью нарушить церковный мир. Наш
                приход обвиняют даже в избиении в алтаре и насильственной госпитализации
                совершенно здорового о. Михаила (хотя никто из наших прихожан его ни разу нигде
                не ударил), призывают православную общественность выражать протест в адрес
                нашей общины, писать письма в Патриархию с требованиями “принять меры” по
                отношению ко мне и всему приходу.</p>
            

            
            <p>Учитывая всю
                сложность сложившейся ситуации, усугубленную явно антицерковными действиями
                газеты и радиостанции общества “Радонеж” и Сретенского монастыря, пытающихся на
                почве несчастья, произошедшего с болезненным священником в нашем храме,
                настроить друг против друга членов одной церкви и нарушить церковный мир,
                почтительнейше прошу Ваше Святейшество, с присущим Вам вниманием и
                объективностью, ознакомиться с происшедшим, а также с его предпосылками.
                Выражаю полную и искреннюю надежду на то, что если это рассмотрение повлечет за
                собой какое-либо решение, выражающее волю Божию, то оно целиком будет служить
                интересам мира и укреплению братской любви в Русской Православной Церкви.</p>
            

            
            <p>Вашего
                Святейшества недостойный послушник и богомолец</p>
            

            
            <p>свящ. Георгий
                Кочетков</p>
            

            
            <p>4 июля 1997 г.</p>
            

            
            <p>&nbsp;* * *</p>
            

            
            <p>&nbsp;Распоряжение</p>
            

            
            <p>&nbsp;Для расследования инцидента, происшедшего 29
                июня 1997 года при совершении воскресного богослужения в храме Успения
                Пресвятой Богородицы в Печатниках города Москвы, в результате которого было
                допущено надругательство, избиение и насильственное отправление в
                психиатрическую больницу клирика данного храма, священника Михаила Дубовицкого,
                Нами образуется комиссия в следующем составе:</p>
            

            
            <p>&nbsp;1. Епископ Орехово-Зуевский Алексий, викарий
                Московской епархии, — председатель комиссии.</p>
            

            
            <p>&nbsp;2. Архимандрит Дионисий (Шишигин), благочинный
                храмов Богоявленского округа.</p>
            

            
            <p>&nbsp;3. Протоиерей Леонид Ролдугин, благочинный
                храмов Преображенского округа, член Епархиального совета г. Москвы.</p>
            

            
            <p>&nbsp;4. Протоиерей Владислав Цыпин, доцент
                Московской Духовной Академии.</p>
            

            
            <p>&nbsp;5. Священник Александр Абрамов, настоятель храма свт. Мартина Исповедника, —секретарь комиссии.</p>
            

            
            <p>&nbsp;Членам комиссии надлежит заслушать участников
                данного инцидента и свидетелей, присутствовавших при этом, разобраться в
                причинах возникшего конфликта и проанализировать поступившую в Московскую
                Патриархию документацию по данному вопросу. О результатах расследования
                доложить рапортом на Наше имя.</p>
            

            
            <p>&nbsp;Алексий II</p>
            

            
            <p>&nbsp;Патриарх Московский и всея Руси</p>
            

            
            <p>&nbsp;11 июля 1997 года<br/></p>
            

            
            <hr className="wp-block-separator"/>
            

            
            <p><a name="1" href="#r1">[1]</a> <strong><em>Имеется в виду следующий "документ", предъявленный патриарху
                Алексию II в первый же день после его возвращения из длительной зарубежной поездки, а позже
                опубликованный О.Николаевой (супругой свящ. Владимира Вигилянского) в статье “Правоверие как
                инакомыслие“ (“Независимая газета”, 26.07.97 г.), в газете “Радонеж”№12(56), июль 1997 г. под рубрикой
                “документ”, а также в вышедшем не ранее ноября 1997 г. сборнике подобных же статей “Школа церковной
                смуты”</em></strong>. <strong><em>Стоит отметить, что несмотря на то, что все дальнейшие расследования,
                так же как и интервью сотрудника милиции, и видеозапись происходившего в храме, обличают этот документ
                как несомненную фальшивку, никто из распространявших его не только не преследовался, но даже не принес
                извинений: </em></strong><br/>"Главное управление внутренних дел г. Москвы.&nbsp;Центральный
                административный округ.&nbsp;7 региональное управление внутренних дел.&nbsp;18 отделение
                милиции.&nbsp;103051, Москва, М. Сухаревский пер., д. 4, стр. 2.&nbsp;Тел. 208-59-39, 208-81-26, факс
                207-20-52.&nbsp;1.07.97 г. № б/н.&nbsp;на №3052 от 1.07.97<br/>Архиепископу Истринскому
                    Арсению&nbsp;Викарию Святейшего Патриарха&nbsp;Московского и всея РусиВаше
                        Высокопреосвященство!&nbsp;На Ваш запрос № 3052 от 1 июля 1997 года сообщаю следующее.&nbsp;По
                        факту конфликта, произошедшего 29 июня 1997 года вцеркви Успения Пресвятой Богородицы в
                        Печатниках, расположенной по адресу: г. Москва, ул. Сретенка, дом 3 руководством 18 отделения
                        милиции 7 РУВД ЦАО г. Москвы было проведено служебное расследование. В результате было
                        установлено, что 29.06.97 г., примерно в 9 часов в дежурную часть 18 отделения милиции по
                        телефону поступил звонок от неизвестного мужчины, который сообщил о происходящей драке в храме
                        Успения Пресвятой Богородицы в Печатниках. Прибыв на место, наряд группы немедленного
                        реагирования обнаружил происходящую драку в алтарной комнате между священниками, причем один из
                        них, как в последствии выяснилось Дубовицкий Михаил Владимирович, находился со следами побоев и
                        в разорванной церковной одежде. Разняв драку сотрудники милиции попросили объяснений у
                        присутствовавших по поводу происходящего. На что один из участвующих в драке священников по
                        фамилии Кочетковобъяснил, что Дубовицкий М.В. психически ненормальный человек, во время
                        богослужения у него началось буйство и присутствовавшие при этом священники принимают меры к его
                        усмирению. Не удовлетворившись данными объяснениями, сотрудники отделения пояснили, что без
                        надлежащего медицинского осмотра священники не вправе утверждать о психической неполноценности
                        того или иного лица, тем более избивать его при этом. После этого служителями церкви был вызван
                        наряд скорой помощи, которая и доставила Дубовицкого М.В. для обследования в 14 психиатрическую
                        больницу. Примерно через два часа дежурный по отделению, майор милиции Кузнецов А. А. созвонился
                        с дежурным врачом больницы, который объяснил, что гр-н Дубовицкий М. В. признан абсолютно
                        здоровым и после обследования отпущен домой.Таким образом, служебная проверка
                            показала, что священник Дубовицкий М.В. сотрудниками милиции не задерживался и в 18
                            отделение милиции не доставлялся, тем более во время совершения им богослужения, т.е. при
                            исполнении своих прямых обязанностей. Действия сотрудников отделения признаны законными,
                            правомерными и обоснованными, что подтверждается свидетельством очевидцев — служителей
                            Московского Сретенского мужского монастыря, расположенного в соседнем храме по адресу: г.
                            Москва, ул. Б. Лубянка, дом 19, которые также присутствовали при разрешении данного
                            конфликта.&nbsp;<br/>Ранее аналогичные факты, связанные с прихожанами и служителями храма
                                Успения Пресвятой Богородицы в Печатниках также имели место, в связи с чем руководство
                                отделения убедительно просит Вас принять все меры к скорейшему наведению порядка в
                                данном храме, принадлежащем Московской Патриархии.&nbsp;С уважением, Начальник&nbsp;ст.
                                лейтенант милиции А.Л. Римский</p>
            

            
            <p><a name="2" href="#r2">[2]</a><strong><em>Так в тексте. – Ред.</em></strong></p>
            

            
            <p><a name="3" href="#r3">[3]</a> <strong><em>Если бы врач и алтарники действовали строго по закону, о.
                Михаила пришлось бы силами милиции связать в алтаре храма и вынести связанного в санитарную машину. —
                Ред.</em></strong></p>
            
        </div>
    );
};

export default Zaos4;
