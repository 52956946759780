import React from "react";
import './text.css';
const Endshpil = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Заключение</h2>
            
            <p>&nbsp; Итак, мы видим печальные результаты
                деятельности считающих себя “ревнителями православия” фундаменталистов: выгнана
                из храма крупнейшая в Москве, а может быть, и в стране, евхаристическая община,
                прекратила свою деятельность всемирно известная Псковская иконописная школа,
                ушел в не признанную ни одной православной церковью юрисдикцию молодой и
                растущий приход в Херсоне. Для наложения прещений на подвергающихся
                фундаменталистской травле клириков используются или сомнительные и неоднозначные
                с канонической точки зрения или прямо ложные и клеветнические (как это было в
                случае со свящ. Георгием Кочетковым) обвинения. </p>
            

            
            <p>&nbsp; Под прицелом “ревнителей” — чуть ли не все
                более ли менее заметные структуры и деятели Русской православной церкви. Страшный
                образ православия, создаваемый фундаменталистами, отталкивает от него и тех,
                кто им не верит, и тех, кто вслед за ними начинает видеть вокруг себя
                бесчисленных “врагов православного народа”. Отношение общества к церкви
                становится все более настороженным. Так стараниями “ревнителей” “имя Божие
                хулится между язычниками”.</p>
            

            
            <p>&nbsp; Сбываются слова печального пророчества
                праведницы XX века, матери Марии (Скобцовой): те, кто в области тягучего и
                неопределенного марксистского миропонимания пылал страстью ересемании и
                уничтожения противников, в области православного вероучения становятся еще
                большими истребителями ересей и охранителями ортодоксии. Пророчество это
                заканчивается знаменательными словами: “Шаржируя, можно сказать, что за
                неправильно положенное крестное знамение они будут штрафовать, а за отказ от
                исповеди ссылать в Соловки. Свободная же мысль будет караться смертной казнью.”</p>
            

            
            <p>&nbsp; На вопрос, как жить в церкви, когда на тебя
                клевещут, когда тебя гонят в раскол как будто бы твои же братья по вере, разные
                люди отвечают по-разному. Пока живет в фактическом затворе, уйдя от активной
                церковной жизни, архимандрит Зинон с братией. О выборе херсонцев мы уже
                говорили. </p>
            

            
            <p>&nbsp; Есть ли иной путь? Возможно ли выносить
                невыносимое, не уйти, не оторваться и все претерпеть — ради того, чтобы наша
                церковь действительно возродилась “не в бревнах, а в ребрах”? Этого нельзя
                знать заранее, потому что это зависит не только от воли Божией, но и от каждого
                из нас, от ежедневного, иногда, казалось бы, мелкого делания. </p>
            

            
            <p>&nbsp; Дай Бог всем,&nbsp;
                кто ищет этого пути, сил и терпения. </p>
            

            
            <p>&nbsp; Дай Бог всем нам помнить, что алчущие и
                жаждущие правды — блаженны. Ибо они насытятся.</p>
            
        </div>
    );
};

export default Endshpil;
