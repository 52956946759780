import React from 'react';
import './text.css';
const Zaostrov = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Дело священника Георгия Кочеткова и общины храма Успения Пресвятой Богородицы в Печатниках</h2>
            <p><a href="/zaos1/" className="alink">Часть 1. Дай вам Бог крепнуть, углубляться, расти и светить всем вокруг вас</a></p>


            <p><a href="/zaos2/" className="alink">Часть 2. «Вы — жертва, может быть, и невинная...»</a></p>


            <p><a href="/zaos3/" className="alink">Часть 3. 29 июня: говорят очевидцы</a></p>


            <p><a href="/zaos4/" className="alink">Часть 4. 29 июня: говорят документы</a></p>


            <p><a href="/zaos5/" className="alink">Часть 5. «Радонеж» начинает и выигрывает?</a></p>


            <p><a href="/zaos6/" className="alink">Часть 6: Нет ничего тайного, что не было бы узнано</a></p>


            <p><a href="/zaos7/" className="alink">Часть 7. Комиссия. Июль — октябрь 1997 года</a></p>


            <p><a href="/zaos8/" className="alink">Глава 8. Что же дальше? </a></p>


        </div>
    );
};

export default Zaostrov;
